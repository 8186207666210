<form [formGroup]="form" (keydown.shift.enter)="onControlEnter($event)" (keydown.enter)="onControlEnter($event)" class="dialog-wrap popup">
    <div formArrayName="array">
        <div *ngFor="let formItem of array.controls; let i = index">
            <nb-card class="small-header popup">
                <nb-card-header>
                    <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Sales.MktMemberCard.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
                </nb-card-header>
                <nb-card-body>
                    <div [formGroup]="formItem">
                        <label class="label label-with-border-below">{{'Thẻ thành viên' | translate | headtitlecase}}</label>
                        <div class="row">
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="Contact" label="Common.contact" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [customIcons]="contactControlIcons">
                                    <ngx-select2 formControlName="Contact" [select2Option]="select2OptionForContact" (selectChange)="onContactChange(formItem, $event, i)"></ngx-select2>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-6">
                                <ngx-form-group [formGroup]="formItem" name="ContactName" label="Contact.name" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="ContactName" placeholder="{{'Contact.name' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="ContactPhone" label="Contact.phone" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="ContactPhone" placeholder="{{formItem.get('ContactPhone').placeholder || ('Contact.phone' | translate | headtitlecase)}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-md-3">
                                <ngx-form-group [formGroup]="formItem" name="DistributedDate" label="{{validateVoucherDate(formItem.get('DistributedDate'), 'Ngày phát hành')}}" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [touchedValidate]="false">
                                    <input type="text" [owlDateTime]="DateOfSale" [owlDateTimeTrigger]="DateOfSale" nbInput fullWidth formControlName="DistributedDate" placeholder="{{'Ngày phát hành' | translate | headtitlecase}}">
                                    <owl-date-time #DateOfSale [startAt]="formItem.get('DistributedDate')?.value || this.cms.lastVoucherDate"></owl-date-time>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-6">
                                <ngx-form-group [formGroup]="formItem" name="ContactAddress" label="Contact.address" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="ContactAddress" placeholder="{{formItem.get('ContactAddress').placeholder || ('Contact.address' | translate | headtitlecase)}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="ContactEmail" label="Contact.email" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="ContactEmail" placeholder="{{formItem.get('ContactEmail').placeholder || ('Contact.email' | translate | headtitlecase)}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="DefaultThread" label="Luồng (công trình/dự án) mặc định" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="DefaultThread" placeholder="Luồng (công trình/dự án) mặc định">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="DefaultPaymentMethod" label="Phương thức thanh toán mặc định" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
\                                    <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="DefaultPaymentMethod" label="Phương thức thanh toán mặc định" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="true" [required]="true" [touchedValidate]="false">
                                        <ngx-select2 formControlName="DefaultPaymentMethod" [select2Option]="select2OptionForDefaultPaymentMethod"></ngx-select2>
                                    </ngx-form-group>
                                </ngx-form-group>
                            </div>
                        </div>
                    </div>
                </nb-card-body>
                <nb-card-footer>
                    <div class="buttons-row" style="text-align: right;">
                        <button nbButton hero status="primary" (click)="preview([formItem.value])" [disabled]="!form.valid || isProcessing">
                            <nb-icon on b-icon pack="eva" icon="printer"></nb-icon>{{'Common.preview' | translate | headtitlecase}}
                        </button>
                        <button nbButton status="danger" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
                        <button nbButton status="success" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="save()">{{'Common.save' | translate | headtitlecase}}</button>
                    </div>
                </nb-card-footer>
            </nb-card>
        </div>
    </div>
</form>