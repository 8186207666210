import { ColDef, ColumnApi, GridApi, IRowNode, RowNode } from "@ag-grid-community/core";
import { CurrencyPipe } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NbToastrService, NbDialogService, NbDialogRef, NbThemeService } from "@nebular/theme";
import { AgNumberCellInput } from "../../../../../../lib/custom-element/ag-list/cell/input/number.component";
import { AgTextCellRenderer } from "../../../../../../lib/custom-element/ag-list/cell/text.component";
import { agMakeCommandColDef } from "../../../../../../lib/custom-element/ag-list/column-define/command.define";
import { agMakeImageColDef } from "../../../../../../lib/custom-element/ag-list/column-define/image.define";
import { agMakeSelectionColDef } from "../../../../../../lib/custom-element/ag-list/column-define/selection.define";
import { DataManagerFormComponent } from "../../../../../../lib/data-manager/data-manager-form.component";
import { CollaboratorBasicStrategyProductModel } from "../../../../../../models/collaborator.model";
import { ProductUnitModel, ProductModel } from "../../../../../../models/product.model";
import { ApiService } from "../../../../../../services/api.service";
import { CommonService } from "../../../../../../services/common.service";
import { RootServices } from "../../../../../../services/root.services";
import { AgDynamicListComponent } from "../../../../../general/ag-dymanic-list/ag-dymanic-list.component";
import { Model } from "../../../../../../models/model";
import { AgDateCellInput } from "../../../../../../lib/custom-element/ag-list/cell/input/date.component";
import { agMakeTextColDef } from "../../../../../../lib/custom-element/ag-list/column-define/text.define";
import { B2bCenterService } from "../../../../b2b-center.service";
import { CollaboratorProductListComponent } from "../../../../../collaborator/product/collaborator-product-list/collaborator-product-list.component";
import { CollaboratorBasicStrategyProductFormComponent } from "../../../../../collaborator/strategies/basic-strategy/product-form/collaborator-basic-strategy-product-form.component";
import { B2bAdvanceSalesCommissionConfigModel, B2bAdvanceSalesCommissionConfigPartnerModel, B2bAdvanceSalesCommissionConfigProductModel } from "../../../../b2b-center.model";
import { B2bSalesCustomerListComponent } from "../../../partner/list/sales-partner-list.component";
import { B2bPublicProductListComponent } from "../../../../product/public/public-product-list/public-product-list.component";
import { agMakeCurrencyColDef } from "../../../../../../lib/custom-element/ag-list/column-define/currency.define";
import { AgSelect2Filter } from "../../../../../../lib/custom-element/ag-list/filter/select2.component.filter";
import { DialogFormComponent } from "../../../../../dialog/dialog-form/dialog-form.component";

@Component({
  selector: 'ngx-b2b-advance-commission-config-form',
  templateUrl: './advance-commission-config-form.component.html',
  styleUrls: ['./advance-commission-config-form.component.scss'],
  providers: [
    CurrencyPipe
  ]
})
export class B2bAdvanceSalesCommissionConfigFormComponent extends DataManagerFormComponent<B2bAdvanceSalesCommissionConfigModel> implements OnInit {


  componentName: string = 'B2bAdvanceSalesCommissionConfigFormComponent';
  idKey = ['Code'];
  // baseFormUrl = '/collaborator/advance-strategy/form';
  apiPath = '/b2b-center/sales/commission/config/advances';
  themeName = this.themeService.currentTheme == 'default' ? '' : this.themeService.currentTheme;
  unitList: ProductUnitModel[] = [];

  constructor(
    public rsv: RootServices,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public toastrService: NbToastrService,
    public dialogService: NbDialogService,
    public cms: CommonService,
    public ref?: NbDialogRef<B2bAdvanceSalesCommissionConfigFormComponent>,
    public b2bCenterService?: B2bCenterService,
    public themeService?: NbThemeService,
    public onDetectChangeRef?: ChangeDetectorRef
  ) {
    super(rsv, activeRoute, router, formBuilder, apiService, toastrService, dialogService, cms);


    const $this = this;
    /** AG-Grid */
    this.columnDefs = [
      {
        ...agMakeSelectionColDef(this.cms),
        headerName: 'STT',
        field: 'Id',
        valueGetter: 'node.data.Partner',
      },
      {
        ...agMakeImageColDef(this.cms),
        headerName: 'Avatar',
        field: 'Avatar',
        width: 100,
      },
      {
        headerName: 'Tên CTV',
        field: 'PartnerName',
        width: 200,
        filter: 'agTextColumnFilter',
        cellRenderer: AgTextCellRenderer,
        // pinned: 'left',
      },
      {
        headerName: 'Bắt đầu',
        field: 'DateOfStart',
        width: 180,
        filter: 'agTextColumnFilter',
        cellRenderer: AgDateCellInput,
        cellRendererParams: {
          takeUntilDelay: 0,
        },
        // pinned: 'right',
      },
      {
        headerName: 'Kết thúc ',
        field: 'DateOfEnd',
        width: 180,
        filter: 'agTextColumnFilter',
        cellRenderer: AgDateCellInput,
        cellRendererParams: {
          takeUntilDelay: 0,
        },
        // pinned: 'right',
      },
      {
        headerName: 'Sản phẩm',
        field: 'Products',
        width: 150,
        filter: 'agTextColumnFilter',
        cellRenderer: AgTextCellRenderer,
        // pinned: 'right',
      },
      {
        ...agMakeCommandColDef(null, this.cms, false, (params) => {
          this.gridApi.applyTransaction({ remove: [params] });
        }, false, [
          // {
          //   name: 'setting',
          //   title: 'Cài đặt',
          //   icon: 'settings-2-outline',
          //   status: 'primary',
          //   outline: false,
          //   action: async (params) => {
          //     this.cms.openDialog(CollaboratorBasicStrategyProductFormComponent, {
          //       context: {
          //         data: [
          //           params.node.data,
          //         ],
          //         onDialogSave(newData) {
          //           console.log(newData);
          //           let currentNode: IRowNode = $this.gridApi.getRowNode($this.cms.getObjectId(params.data.Product) + '-' + $this.cms.getObjectId(params.data.Unit));
          //           currentNode.setData(newData[0]);
          //         },
          //       }
          //     });
          //     return true;
          //   }
          // },
        ]),
        // width: 123,
        headerName: 'Lệnh',
      },
    ];

    this.productsColumnDefs = [
      {
        ...agMakeSelectionColDef(this.cms),
        headerName: 'STT',
        field: 'Id',
        valueGetter: 'node.data.Product',
      },
      {
        ...agMakeImageColDef(this.cms),
        headerName: 'Hình',
        field: 'FeaturePicture',
        width: 100,
      },
      {
        headerName: 'Sku',
        field: 'Sku',
        width: 100,
        filter: 'agTextColumnFilter',
        cellRenderer: AgTextCellRenderer,
        pinned: 'left',
      },
      {
        headerName: 'Danh mục',
        field: 'Categories',
        // pinned: 'left',
        width: 200,
        cellRenderer: AgTextCellRenderer,
        filter: AgSelect2Filter,
        filterParams: {
          select2Option: {
            ...this.cms.select2OptionForTemplate,
            multiple: true,
            logic: 'OR',
            allowClear: true,
            ajax: {
              delay: 300,
              data: function (params) {
                return {
                  ...params,
                  offset: params['offset'] || 0,
                  limit: params['limit'] || 10
                };
              },
              transport: (settings: JQueryAjaxSettings, success?: (data: any) => null, failure?: () => null) => {
                console.log(settings);
                const params = settings.data;
                const rs = this.categoryFilterData.filter(f => this.cms.smartFilter(f.text, params['term']));
                success({ data: rs, total: rs.length });
                return null;
              },
              processResults: (rs: { data: any[], total: number }, params: any) => {
                const data = rs.data;
                const total = rs.total;
                params.limit = params.limit || 10;
                params.offset = params.offset || 0;
                params.offset = params.offset += params.limit;
                return {
                  results: data.map(item => {
                    item.thumbnail = item?.FeaturePicture?.Thumbnail;
                    return item;
                  }),
                  pagination: {
                    more: params.offset < total
                  }
                };
              },
            }
          }
        },
      },
      {
        ...agMakeTextColDef(this.cms),
        headerName: 'Tên sản phẩm',
        field: 'ProductName',
        width: 250,
        // filter: 'agTextColumnFilter',
        // cellRenderer: AgTextCellRenderer,
        // pinned: 'left',
      },
      {
        headerName: 'ĐVT',
        field: 'Unit',
        width: 110,
        filter: 'agTextColumnFilter',
        cellRenderer: AgTextCellRenderer,
        // pinned: 'right',
      },
      {
        ...agMakeCurrencyColDef(this.cms),
        headerName: 'Giá EU',
        field: 'ListedPrice',
        width: 150,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Chiết khấu',
        field: 'CommissionRatio',
        width: 120,
        filter: 'agTextColumnFilter',
        cellRenderer: AgNumberCellInput,
        cellRendererParams: {
          takeUntilDelay: 0,
          changed: (commissionRatio: number, params: { node: RowNode }) => {
            params.node.setDataValue('Price', this.calculateDiscountPrice(params?.node?.data));
            params['status'] = 'success';
            return true;
          }
        },
      },
      {
        ...agMakeCurrencyColDef(this.cms),
        headerName: 'Giá sau CK',
        field: 'Price',
        width: 150,
        filter: 'agTextColumnFilter',
        valueGetter: params => this.calculateDiscountPrice(params?.node?.data),
      },
      // {
      //   headerName: 'Thưởng tuần',
      //   field: 'Level1WeeklyAwardRatio',
      //   width: 150,
      //   filter: 'agTextColumnFilter',
      //   cellRenderer: AgTextCellRenderer,
      //   // pinned: 'right',
      // },
      // {
      //   headerName: 'Thưởng tháng',
      //   field: 'Level1MonthlyAwardRatio',
      //   width: 150,
      //   filter: 'agTextColumnFilter',
      //   cellRenderer: AgTextCellRenderer,
      //   // pinned: 'right',
      // },
      // {
      //   headerName: 'Thưởng quý',
      //   field: 'Level1QuarterlyAwardRatio',
      //   width: 150,
      //   filter: 'agTextColumnFilter',
      //   cellRenderer: AgTextCellRenderer,
      //   // pinned: 'right',
      // },
      // {
      //   headerName: 'Thưởng năm',
      //   field: 'Level1YearlyAwardRatio',
      //   width: 150,
      //   filter: 'agTextColumnFilter',
      //   cellRenderer: AgTextCellRenderer,
      //   // pinned: 'right',
      // },
      {
        ...agMakeCommandColDef(null, this.cms, false, (params) => {
          $this.productGridApi.applyTransaction({ remove: [params] });
          $this.updatePartnerProducts();
          this.updateCategoryFilterData();
        }, false, [
          // {
          //   name: 'setting',
          //   title: 'Cài đặt',
          //   icon: 'settings-2-outline',
          //   status: 'primary',
          //   outline: false,
          //   action: async (params) => {
          //     this.cms.openDialog(CollaboratorBasicStrategyProductFormComponent, {
          //       context: {
          //         data: [
          //           params.node.data,
          //         ],
          //         onDialogSave(newData) {
          //           console.log(newData);
          //           let currentNode: IRowNode = $this.productGridApi.getRowNode($this.cms.getObjectId(params.data.Product) + '-' + $this.cms.getObjectId(params.data.Unit));
          //           currentNode && currentNode.setData({ ...currentNode.data, ...newData[0] });
          //           $this.updatePartnerProducts();
          //         },
          //       }
          //     });
          //     return true;
          //   }
          // },
        ]),
        // width: 123,
        headerName: 'Lệnh',
      },
    ];
    /** End AG-Grid */
  }

  calculateDiscountPrice(data: B2bAdvanceSalesCommissionConfigProductModel): number {
    return (data.ListedPrice || 0) - (data.ListedPrice || 0) * (data.CommissionRatio || 0) / 100;
  }

  /** AG-Grid */
  public gridApi: GridApi;
  public productGridApi: GridApi;
  public gridColumnApi: ColumnApi;
  public columnDefs: ColDef[];
  public productsColumnDefs: ColDef[];
  public gridParams;
  public categoryFilterData = [];

  onGridReady(params) {
    this.gridParams = params;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadList();
  }

  onProductGridReady(params) {
    this.productGridApi = params.api;
    this.productGridApi.setRowData([]);
  }

  updateCategoryFilterData() {
    const categoryFilterDataMap = {};
    this.productGridApi.forEachNode((rowNode, imdex) => {
      if (Array.isArray(rowNode.data?.Categories)) {
        for (const i in rowNode.data.Categories) {
          categoryFilterDataMap[this.cms.getObjectId(rowNode.data.Categories[i])] = rowNode.data.Categories[i];
        }
      }
    });
    this.categoryFilterData = Object.keys(categoryFilterDataMap).map(k => categoryFilterDataMap[k]);
  }

  loadList(callback?: (list: CollaboratorBasicStrategyProductModel[]) => void) {
    if (this.gridApi) {
      let partners: B2bAdvanceSalesCommissionConfigPartnerModel[] = (this.array.controls[0].get('Partners').value || []).map((pertner: B2bAdvanceSalesCommissionConfigPartnerModel) => {
        if (!pertner.Products) {
          pertner.Products = [];
        }
        pertner.Products.map(product => {
          product['id'] = this.cms.getObjectId(product.Product) + '-' + this.cms.getObjectId(product.Unit);
          product['text'] = product.ProductName + '/' + this.cms.getObjectText(product.Unit) + '/' + product.CommissionRatio;
        });
        return pertner;
      });
      this.gridApi.setRowData(partners);
    }
  }
  select2OptionForPage = {
    placeholder: 'Chọn trang...',
    allowClear: false,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
  };

  async loadCache() {
    // iniit category
    // this.categoryList = (await this.apiService.getPromise<ProductCategoryModel[]>('/admin-product/categories', { limit: 'nolimit' })).map(cate => ({ id: cate.Code, text: cate.Name })) as any;
    // this.groupList = (await this.apiService.getPromise<ProductGroupModel[]>('/admin-product/groups', { limit: 'nolimit' })).map(cate => ({ id: cate.Code, text: cate.Name })) as any;
    // this.productList = (await this.apiService.getPromise<ProductModel[]>('/admin-product/products', { limit: 100, includeIdText: true }));
  }

  getRequestId(callback: (id?: string[]) => void) {
    if (this.mode === 'page') {
      super.getRequestId(callback);
    } else {
      callback(this.inputId);
    }
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  async init() {
    await this.loadCache();
    return super.init().then(rs => {
      return rs;
    });
  }

  /** Execute api get */
  executeGet(params: any, success: (resources: ProductModel[]) => void, error?: (e: HttpErrorResponse) => void) {
    params['includePartners'] = true;
    params['includeProducts'] = true;
    super.executeGet(params, success, error);
  }

  async formLoad(formData: ProductModel[], formItemLoadCallback?: (index: number, newForm: FormGroup, formData: ProductModel) => void) {
    return super.formLoad(formData, async (index, newForm, itemFormData) => {

      if (this.gridApi) {
        this.loadList();
      }

      // Direct callback
      if (formItemLoadCallback) {
        formItemLoadCallback(index, newForm, itemFormData);
      }
    });
  }

  makeNewFormGroup(data?: ProductModel): FormGroup {
    const currentDate = new Date();
    const newForm = this.formBuilder.group({
      Code: { value: '', disabled: true },
      Title: ['', Validators.required],
      Page: [this.b2bCenterService.currentpage$.value, Validators.required],
      // DateRange: [[Date.today(), Date.today().next().month()], Validators.required],
      DateOfStart: [],
      DateOfEnd: [],
      Partners: [[]],
    });
    if (data) {
      // data.DateRange = [data.DateOfStart, data.DateOfEnd];
      newForm.patchValue(data);
    }
    return newForm;
  }
  onAddFormGroup(index: number, newForm: FormGroup, formData?: ProductModel): void {
    super.onAddFormGroup(index, newForm, formData);
  }
  onRemoveFormGroup(index: number): void {

  }

  goback(): false {
    super.goback();
    if (this.mode === 'page') {
      this.router.navigate(['/admin-product/product/list']);
    } else {
      this.ref.close();
    }
    return false;
  }

  onUpdatePastFormData(aPastFormData: { formData: any; meta: any; }): void { }
  onUndoPastFormData(aPastFormData: { formData: any; meta: any; }): void { }

  /** Execute api put */
  executePut(params: any, data: ProductModel[], success: (data: ProductModel[]) => void, error: (e: any) => void) {
    return super.executePut(params, data, success, error);
  }

  /** Execute api post */
  executePost(params: any, data: ProductModel[], success: (data: ProductModel[]) => void, error: (e: any) => void) {
    return super.executePost(params, data, success, error);
  }

  onGridInit(component: AgDynamicListComponent<any>) {
    const $this = this;
    let actionButtonList = component.actionButtonList;
    // actionButtonList = actionButtonList.filter(f => f.name != 'choose');
    actionButtonList = [];
    actionButtonList.unshift({
      type: 'button',
      name: 'reset',
      title: 'Reset',
      status: 'danger',
      // label: 'Reset',
      outline: true,
      iconPack: 'eva',
      icon: 'sync-outline',
      size: 'medium',
      click: (event) => {
        component.reset();
        return true;
      }
    });
    actionButtonList.unshift({
      type: 'button',
      name: 'delete',
      title: 'Gở CTV Bán Hàng',
      status: 'danger',
      label: 'Gở',
      iconPack: 'eva',
      icon: 'minus-square-outline',
      size: 'medium',
      click: (event) => {
        const selectedNodes: IRowNode[] = this.gridApi.getSelectedNodes();
        $this.gridApi.applyTransaction({ remove: selectedNodes.map(m => m.data) });

        return true;
      }
    });
    actionButtonList.unshift({
      type: 'button',
      name: 'add',
      title: 'Thêm khách hàng',
      status: 'success',
      label: 'Thêm khách hàng',
      iconPack: 'eva',
      icon: 'plus-square-outline',
      size: 'medium',
      click: (event) => {
        // const selectedNodes: IRowNode[] = this.gridApi.getSelectedNodes();

        this.cms.openDialog(B2bSalesCustomerListComponent, {
          context: {
            gridHeight: '90vh',
            onDialogChoose: (chooseItems) => {
              console.log(chooseItems);
              const newRowNodeTrans = this.gridApi.applyTransaction({
                add: chooseItems.map(m => ({
                  id: m.Contact,
                  text: m.Name,
                  Partner: m.Contact,
                  PartnerName: m.Name,
                  Avatar: m.Avatar,
                  DateOfStart: this.array.controls[0].get('DateOfStart').value,
                  DateOfEnd: this.array.controls[0].get('DateOfEnd').value,
                }))
              });
              console.log('New Row Node Trans: ', newRowNodeTrans);
            },
          }
        });

        return true;
      }
    });
    actionButtonList.unshift({
      type: 'button',
      name: 'copyto',
      title: 'Copy to',
      status: 'info',
      label: 'Copy',
      iconPack: 'eva',
      icon: 'copy-outline',
      size: 'medium',
      disabled: () => this.gridApi.getSelectedNodes().length != 1,
      click: (event) => {
        const selectedNode: IRowNode = this.gridApi.getSelectedNodes()[0];
        const nodes: IRowNode[] = [];
        this.gridApi.forEachNode(node => nodes.push(node));

        if (selectedNode) {
          const $this = this;
          this.cms.openDialog(AgDynamicListComponent, {
            context: {
              title: 'Copy danh sách sản phẩm sang các CTV khác',
              width: '905px',
              height: '400px',
              // apiPath: '/accounting/reports',
              hideChooseButton: true,
              rowModelType: 'clientSide',
              idKey: ['Id'],
              rowData: nodes.map(m => m.data).filter(f => this.cms.getObjectId(f.Partner) != this.cms.getObjectId(selectedNode.data.Partner)),
              extendActionButtons: [
                {
                  name: 'confirm',
                  label: 'Copy',
                  title: 'Copy',
                  size: 'medium',
                  icon: 'copy-outline',
                  status: 'danger',
                  click: (event, option, component: AgDynamicListComponent<Model>) => {
                    if (component.rowData && component.rowData.length > 0) {
                      // component.loading = true;
                      const targetNodes = component.gridApi.getSelectedNodes();
                      for (const i in targetNodes) {
                        const targetNodeData = targetNodes[i].data;
                        $this.gridApi.forEachNode((node: IRowNode) => {
                          if (this.cms.getObjectId(targetNodeData.Partner) == this.cms.getObjectId(node.data.Partner)) {
                            targetNodeData.Products = selectedNode.data.Products.map(m => ({ ...m, Id: null, SystemUuid: null, Partner: targetNodeData.Partner }));
                            node.setData(targetNodeData);
                          }
                        });
                      }

                      component.close();
                      this.cms.showToast('Đã copy cấu hình chiết khấu sang các CTV đã chọn', 'Đã copy cấu hình chiết khấu', { status: 'success' })

                      // this.apiService.putPromise<any[]>('/admin-product/products', { id: component.rowData.map(m => this.cms.getObjectId(m.Product)) }, component.rowData.map(m => ({
                      //   Code: m.Product,
                      //   Name: m.Name,
                      // }))).then(rs => {
                      //   component.loading = false;
                      //   component.close();
                      //   this.refresh();
                      // }).catch(err => {
                      //   component.loading = false;
                      // });
                    }
                    return true;
                  }
                }
              ],
              onDialogChoose: (chooseItems) => {

              },
              columnDefs: this.columnDefs,
              onInit: (component) => {
                component.actionButtonList = component.actionButtonList.filter(f => ['close', 'choose', 'preview', 'refresh', 'confirm'].indexOf(f.name) > -1);
              }
            },
            closeOnEsc: false,
          });
        }

        return true;
      }
    });
    actionButtonList.unshift({
      type: 'button',
      name: 'syncDateRange',
      title: 'Đồng bộ thời gian bắt đầu và kết thúc của từng khách hàng với thoi gian của cấu hình',
      label: 'Đồng bộ thời gian',
      status: 'info',
      outline: true,
      iconPack: 'eva',
      icon: 'sync-outline',
      size: 'medium',
      click: (event) => {
        this.gridApi.forEachNode((rowNode) => {
          rowNode.setDataValue('DateOfStart', this.array.controls[0].get('DateOfStart').value);
          rowNode.setDataValue('DateOfEnd', this.array.controls[0].get('DateOfEnd').value);
        });
        return true;
      }
    });

    // actionButtonList.unshift({
    //   type: 'button',
    //   name: 'settings',
    //   title: 'Cấu hình',
    //   status: 'primary',
    //   label: 'Cài đặt',
    //   iconPack: 'eva',
    //   icon: 'settings-2-outline',
    //   size: 'medium',
    //   click: (event) => {
    //     const selectedNodes: IRowNode[] = $this.gridApi.getSelectedNodes();

    //     // Setting for product
    //     if (selectedNodes && selectedNodes.length > 0) {
    //       this.cms.openDialog(CollaboratorBasicStrategyProductFormComponent, {
    //         context: {
    //           data: selectedNodes.map(m => m.data),
    //           onDialogSave(newData) {
    //             console.log(newData);
    //             for (const itemData of newData) {
    //               let currentNode: IRowNode = $this.gridApi.getRowNode($this.cms.getObjectId(itemData.Product) + '-' + $this.cms.getObjectId(itemData.Unit));
    //               currentNode.setData(itemData);
    //             }
    //           },
    //         }
    //       });
    //     }

    //     return true;
    //   }
    // });

    component.actionButtonList = actionButtonList;
  }

  onProductGridInit(component: AgDynamicListComponent<any>) {
    const $this = this;
    let actionButtonList = component.actionButtonList;
    // actionButtonList = actionButtonList.filter(f => f.name != 'choose');
    actionButtonList = [];
    actionButtonList.unshift({
      type: 'button',
      name: 'reset',
      title: 'Reset',
      status: 'danger',
      // label: 'Reset',
      outline: true,
      iconPack: 'eva',
      icon: 'sync-outline',
      size: 'medium',
      click: (event) => {
        component.reset();
        return true;
      }
    });
    actionButtonList.unshift({
      type: 'button',
      name: 'delete',
      title: 'Gở sản phẩm',
      status: 'danger',
      label: 'Gở',
      iconPack: 'eva',
      icon: 'minus-square-outline',
      size: 'medium',
      disabled: () => !$this.productExtendData?.partner,
      click: (event) => {
        const selectedNodes: IRowNode[] = $this.productGridApi.getSelectedNodes();
        $this.productGridApi.applyTransaction({ remove: selectedNodes.map(m => m.data) });
        $this.updatePartnerProducts();
        this.updateCategoryFilterData();
        return true;
      }
    });
    actionButtonList.unshift({
      type: 'button',
      name: 'add',
      title: 'Thêm sản phẩm',
      status: 'success',
      label: 'Thêm sản phẩm',
      iconPack: 'eva',
      icon: 'plus-square-outline',
      size: 'medium',
      disabled: () => !$this.productExtendData?.partner,
      click: (event) => {
        // const selectedNodes: IRowNode[] = this.gridApi.getSelectedNodes();

        // this.cms.openDialog(B2bPublicProductListComponent, {
        //   context: {
        //     // gridHeight: '90vh',
        //     extendsParams: {
        //       includeListedPrice: true,
        //     },
        //     onDialogChoose: (chooseItems) => {
        //       console.log(chooseItems);
        //       const data = [];
        //       for (const i in chooseItems) {
        //         const chooseItem = chooseItems[i];
        //         for (const u in chooseItem.UnitConversions) {
        //           const unitConversion = chooseItem.UnitConversions[u];
        //           const idKey = this.cms.getObjectId(chooseItem.Product) + '-' + this.cms.getObjectId(unitConversion.Unit);
        //           if (!($this.gridApi as any).rowModel?.nodeManager?.allNodesMap[idKey]) {
        //             data.push({
        //               id: this.cms.getObjectId(chooseItem.Product),
        //               text: chooseItem.ProductName,
        //               Product: chooseItem.Product,
        //               ProductName: chooseItem.ProductName,
        //               Sku: chooseItem.Sku,
        //               Unit: typeof unitConversion.Unit == 'string' ? { id: unitConversion.Unit, text: unitConversion.UnitLabel } : unitConversion.Unit,
        //               UnitLabel: unitConversion.UnitLabel,
        //               Pictures: chooseItem.Pictures,
        //               FeaturePicture: chooseItem.FeaturePicture,
        //               Categories: chooseItem.Categories,
        //               Groups: chooseItem.Groups,
        //               ListedPrice: unitConversion.ListedPrice,
        //             });
        //           } else {
        //             console.warn('Duplicate: ' + idKey);
        //           }
        //         }
        //       }
        //       const newRowNodeTrans = $this.productGridApi.applyTransaction({
        //         add: data
        //       });
        //       console.log('New Row Node Trans: ', newRowNodeTrans);
        //       $this.updatePartnerProducts();
        //       this.updateCategoryFilterData();
        //     },
        //   }
        // });

        this.cms.showDialog('Thêm sản phẩm chiết khấu cơ bản', 'Bạn muốn thêm tất cả sản phẩm, thêm theo danh muc hay thêm từng sản phẩm ?', [
          {
            label: 'Tất cả',
            status: 'danger',
            action: async (item, dialog) => {
              dialog.loading = true;
              let productList = [];
              const limit = 100;
              let offset = 0;
              try {
                do {
                  productList = await this.apiService.getPromise<ProductModel[]>('/b2b-center/product/publics', {
                    includeChildrenCategories: true,
                    includeListedPrice: true,
                    limit: limit,
                    offset: offset,
                    page: this.cms.getObjectId(this.b2bCenterService.currentpage$.value),
                  });

                  const data = [];
                  for (const i in productList) {
                    const chooseItem = productList[i];
                    for (const u in chooseItem.UnitConversions) {
                      const unitConversion = chooseItem.UnitConversions[u];
                      const idKey = this.cms.getObjectId(chooseItem.Product) + '-' + this.cms.getObjectId(unitConversion.Unit);
                      if (!(this.productGridApi as any).rowModel?.nodeManager?.allNodesMap[idKey]) {
                        data.push({
                          id: this.cms.getObjectId(chooseItem.Product),
                          text: chooseItem.ProductName,
                          Product: chooseItem.Product,
                          ProductName: chooseItem.ProductName,
                          Sku: chooseItem.Sku,
                          Unit: typeof unitConversion.Unit == 'string' ? { id: unitConversion.Unit, text: unitConversion.UnitLabel } : unitConversion.Unit,
                          UnitLabel: unitConversion.UnitLabel,
                          Pictures: chooseItem.Pictures,
                          FeaturePicture: chooseItem.FeaturePicture,
                          Categories: chooseItem.Categories,
                          Groups: chooseItem.Groups,
                          ListedPrice: unitConversion.ListedPrice,
                        });
                      } else {
                        console.warn('Duplicate: ' + idKey);
                      }
                    }
                  }
                  const newRowNodeTrans = this.productGridApi.applyTransaction({
                    add: data
                  });
                  offset += limit;
                } while (productList.length >= limit);
                dialog.loading = false;
                $this.updatePartnerProducts();
                this.updateCategoryFilterData();
              } catch (err) {
                dialog.loading = false;
                console.error(err);
              }
              return true;
            },
          },
          {
            label: 'Theo danh mục',
            status: 'primary',
            action: () => {
              this.cms.openDialog(DialogFormComponent, {
                context: {
                  width: '512px',
                  controls: [
                    {
                      name: 'Categories',
                      label: 'Danh mục',
                      type: 'select2',
                      option: {
                        ...this.cms.makeSelect2AjaxOption('/admin-product/categories', { onlyIdText: true }, {
                          prepareReaultItem: (item) => {
                            // item.label = item.id + ' - ' + item.text;
                            return item;
                          }
                        }),
                        placeholder: 'Chọn danh mục...',
                        allowClear: false,
                        width: '100%',
                        dropdownAutoWidth: true,
                        minimumInputLength: 0,
                        multiple: true,
                        keyMap: {
                          id: 'id',
                          text: 'text',
                        },
                      },
                    }
                  ],
                  actions: [
                    {
                      label: 'Back',
                      status: 'basic',
                      outline: true,
                      action: async () => {
                        return true;
                      }
                    },
                    {
                      label: 'Thêm',
                      status: 'primary',
                      action: async (form, dialog) => {
                        const categories = form.get('Categories').value as Model[];
                        console.log(categories);

                        if (!categories || categories.length == 0) {
                          this.cms.showToast('Bạn chưa chọn danh mục', 'Chưa chọn danh mục', { status: 'warning' });
                          return false;
                        }

                        let productList = [];
                        const limit = 100;
                        let offset = 0;
                        do {
                          productList = await this.apiService.getPromise<ProductModel[]>('/b2b-center/product/publics', {
                            includeChildrenCategories: true,
                            includeListedPrice: true,
                            eq_Categories: '[' + categories.map(m => this.cms.getObjectId(m)).join(',') + ']',
                            limit: limit,
                            offset: offset
                          });

                          const data = [];
                          for (const i in productList) {
                            const chooseItem = productList[i];
                            for (const u in chooseItem.UnitConversions) {
                              const unitConversion = chooseItem.UnitConversions[u];
                              const idKey = this.cms.getObjectId(chooseItem.Product) + '-' + this.cms.getObjectId(unitConversion.Unit);
                              if (!(this.productGridApi as any).rowModel?.nodeManager?.allNodesMap[idKey]) {
                                data.push({
                                  id: this.cms.getObjectId(chooseItem.Product),
                                  text: chooseItem.ProductName,
                                  Product: chooseItem.Product,
                                  ProductName: chooseItem.ProductName,
                                  Sku: chooseItem.Sku,
                                  Unit: typeof unitConversion.Unit == 'string' ? { id: unitConversion.Unit, text: unitConversion.UnitLabel } : unitConversion.Unit,
                                  UnitLabel: unitConversion.UnitLabel,
                                  Pictures: chooseItem.Pictures,
                                  FeaturePicture: chooseItem.FeaturePicture,
                                  Categories: chooseItem.Categories,
                                  Groups: chooseItem.Groups,
                                  ListedPrice: unitConversion.ListedPrice,
                                });
                              } else {
                                console.warn('Duplicate: ' + idKey);
                              }
                            }
                          }
                          const newRowNodeTrans = this.productGridApi.applyTransaction({
                            add: data
                          });
                          offset += limit;
                        } while (productList.length >= limit);
                        this.updateCategoryFilterData();
                        $this.updatePartnerProducts();
                        return true;
                      }
                    },
                  ],
                }
              });
            },
          },
          {
            label: 'Chọn sản phẩm',
            status: 'info',
            action: () => {
              this.cms.openDialog(B2bPublicProductListComponent, {
                context: {
                  extendsParams: {
                    includeListedPrice: true,
                  },
                  onDialogChoose: (chooseItems) => {
                    console.log(chooseItems);
                    const data = [];
                    for (const i in chooseItems) {
                      const chooseItem = chooseItems[i];
                      for (const u in chooseItem.UnitConversions) {
                        const unitConversion = chooseItem.UnitConversions[u];
                        const idKey = this.cms.getObjectId(chooseItem.Product) + '-' + this.cms.getObjectId(unitConversion.Unit);
                        if (!(this.productGridApi as any).rowModel?.nodeManager?.allNodesMap[idKey]) {
                          data.push({
                            id: this.cms.getObjectId(chooseItem.Product),
                            text: chooseItem.ProductName,
                            Product: chooseItem.Product,
                            ProductName: chooseItem.ProductName,
                            Sku: chooseItem.Sku,
                            Unit: typeof unitConversion.Unit == 'string' ? { id: unitConversion.Unit, text: unitConversion.UnitLabel } : unitConversion.Unit,
                            UnitLabel: unitConversion.UnitLabel,
                            Pictures: chooseItem.Pictures,
                            FeaturePicture: chooseItem.FeaturePicture,
                            Categories: chooseItem.Categories,
                            Groups: chooseItem.Groups,
                            ListedPrice: unitConversion.ListedPrice,
                          });
                        } else {
                          console.warn('Duplicate: ' + idKey);
                        }
                      }
                    }
                    const newRowNodeTrans = this.productGridApi.applyTransaction({
                      add: data
                    });
                    this.updateCategoryFilterData();
                    $this.updatePartnerProducts();
                    console.log('New Row Node Trans: ', newRowNodeTrans);
                  },
                }
              });
            },
          },
        ]);

        return true;
      }
    });

    actionButtonList.unshift({
      type: 'button',
      name: 'settings',
      title: 'Cài chiết khấu cho các dòng đã lọc',
      status: 'primary',
      label: 'Cài chiết khấu',
      iconPack: 'eva',
      icon: 'settings-2-outline',
      size: 'medium',
      click: (event) => {

        this.cms.openDialog(DialogFormComponent, {
          context: {
            width: '512px',
            controls: [
              {
                name: 'CommissionRatio',
                label: '% Chiết khấu',
                placeholder: 'Điền % chiết khấu cho các dong đang lọc',
                type: 'number',
              }
            ],
            actions: [
              {
                label: 'Trở về',
                status: 'basic',
                outline: true,
                action: async (form, dialog) => {
                  return true;
                }
              },
              {
                label: 'OK',
                status: 'success',
                action: async (form, dialog) => {
                  const commissionRatio = form.get('CommissionRatio').value;
                  this.productGridApi.forEachNodeAfterFilterAndSort((rowNode, index) => {
                    rowNode.setDataValue('CommissionRatio', commissionRatio);
                  });
                  return true;
                }
              }
            ],
          }
        })

        return true;
      }
    });

    component.actionButtonList = actionButtonList;
  }

  productExtendData: any = {};
  selectedPartnerNode: IRowNode<B2bAdvanceSalesCommissionConfigPartnerModel> = null;
  onPartnersSelected(nodes: IRowNode<B2bAdvanceSalesCommissionConfigPartnerModel>[]) {
    console.log('On Partners selected: ', nodes);
    if (nodes.length == 1) {
      // Load relative products
      const time = Date.now();
      if (!nodes[0].data.Products) {
        nodes[0].data.Products = [];
      }
      nodes[0].data.Products['__timestamp'] = time;
      console.log(nodes[0].data.Products);
      this.productGridApi.setRowData(nodes[0].data.Products);
      this.productExtendData.partner = nodes[0].data;
      this.selectedPartnerNode = nodes[0];
      this.updateCategoryFilterData();
    } else {
      // Clear relative products
      this.productExtendData.partner = null;
      this.selectedPartnerNode = null;
      this.productGridApi.setRowData([]);
    }
  }

  updatePartnerProducts() {
    if (this.selectedPartnerNode) {
      // const products = [];

      // Emplty products
      this.selectedPartnerNode.data.Products.splice(0, this.selectedPartnerNode.data.Products.length);

      // Add new producs
      this.productGridApi.forEachNode(rowNode => {
        rowNode.data.id = this.cms.getObjectId(rowNode.data['Product']) + '-' + this.cms.getObjectId(rowNode.data['Unit']);
        rowNode.data.text = rowNode.data['ProductName'] + '/' + this.cms.getObjectText(rowNode.data['Unit']) + '/' + rowNode.data['Level1CommissionRatio'];
        this.selectedPartnerNode.data.Products.push(rowNode.data);
        // this.selectedPartnerNode.data.Products.push({
        //   ...rowNode.data,
        //   id: this.cms.getObjectId(rowNode.data['Product']) + '-' + this.cms.getObjectId(rowNode.data['Unit']),
        //   text: rowNode.data['ProductName'] + '/' + this.cms.getObjectText(rowNode.data['Unit']) + '/' + rowNode.data['Level1CommissionRatio'],
        // });
      });
      // this.selectedPartnerNode.data.Products = products;
      // this.selectedPartnerNode.setData(this.selectedPartnerNode.data);
    }
  }

  /** Hight performance config */
  patchedDataAfterSave = false;
  cleanedDataBeforeSave = true;
  /**
   * Override: Clean data for detail form items
   */
  getRawFormData() {
    const data = super.getRawFormData();
    for (const item of data.array) {
      // Get details data from ag-grid
      item.Partners = [];
      this.gridApi.forEachNode((rowNode, index) => {
        console.log(rowNode, index);
        const rawDetail = {};
        for (const prop in rowNode.data) {
          rawDetail[prop] = this.cms.getObjectId(rowNode.data[prop]);
        }
        item.Partners.push(rawDetail);
      });
    }
    return data;
  }
  /** Override: Auto update SystemUuid for detail form item */
  onItemAfterSaveSubmit(formItemData: B2bAdvanceSalesCommissionConfigModel, index: number, method: string) {
    const result = super.onItemAfterSaveSubmit(formItemData, index, method);
    if (result && formItemData.Partners) {
      for (const d in formItemData.Partners) {
        const partnerNode = this.gridApi.getRowNode(this.cms.getObjectId(formItemData.Partners[d].Partner));
        partnerNode.setData(formItemData.Partners[d]);
        if (this.selectedPartnerNode) {
          if (!formItemData.Partners[d].Products) {
            formItemData.Partners[d].Products = [];
          }
          for (const p in formItemData.Partners[d].Products) {
            const product = formItemData.Partners[d].Products[p];
            const productNode = this.productGridApi.getRowNode(this.cms.getObjectId(product.Product) + this.cms.getObjectId(product.Unit));
            if (productNode) {
              productNode.setData(product);
            }
          }
        }
      }
    }
    return result;
  }
  /** End Hight performance config */
}
