<div class="dialog-wrap {{inputMode}}" #dialogWrap>
  <nb-card [nbSpinner]="loading || data.__loading" *ngFor="let data of this.data; let i=index;" style="max-height: initial; margin: 0 auto; width: 23cm;" class="small-header">
    <nb-card-header>
      <ngx-card-header [option]="{controlOption: {index: i}}" [size]="size" [icon]="favicon" [title]="data['Title'] || ('Đơn hàng B2B' | translate:{definition: '', action: cms.translate.instant('Common.print')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
    </nb-card-header>
    <nb-card-body class="print-body" #printContent>
      <div id="print-area" style="position: relative;">
        <ngx-print-header></ngx-print-header>
        <div>
          <div class="print-title">{{ 'Purchase.Order.title' | translate:{action: '', definition: ''} | uppercase }}
          </div>
          <div class="under-title">{{ data.Code }} - {{ data.DateOfOrder | date:'short' }}</div>
        </div>
        <div>
          <div style="display: flex;">
            <div style="flex: 1;">
              <div class="under-line">{{'Voucher.dearSupplier' | translate | headtitlecase}} :
                {{renderValue(data.ObjectName)}}
              </div>
            </div>
            <div>
              <div class="under-line">{{'Common.phone' | translate | headtitlecase}} :
                {{renderValue(data.ObjectPhone)}}
              </div>
            </div>
            <div>
              <div class="under-line">{{'Common.supplierId' | translate | headtitlecase}} :
                {{renderValue(data.Object | objectid)}}
              </div>
            </div>
          </div>
          <div class="under-line">{{'Common.address' | translate | headtitlecase}} : {{renderValue(data.ObjectAddress)}}</div>
          <ng-container *ngIf="data.Contact">
            <div style="display: flex;">
              <div style="flex: 1;">
                <div class="under-line">{{'Contact.name' | translate | headtitlecase}} :
                  {{renderValue(data.ContactName)}}
                </div>
              </div>
              <div>
                <div class="under-line">{{'Contact.phone' | translate | headtitlecase}} :
                  {{renderValue(data.ContactPhone)}}
                </div>
              </div>
              <div>
                <div class="under-line">{{'Contact.id' | translate | headtitlecase}} :
                  {{renderValue(data.Contact | objectid)}}
                </div>
              </div>
            </div>
            <div class="under-line">{{'Contact.address' | translate | headtitlecase}} : {{renderValue(data.ContactAddress)}}</div>

          </ng-container>
        </div>
        <div>
          <div>
            <table style="width: 100%;" class="print-voucher-detail-table">
              <thead class="print-voucher-detail-header">
                <td>#</td>
                <td *ngIf="showPicture" style="white-space: nowrap">{{'Hình' | translate | headtitlecase}}</td>
                <td style="white-space: nowrap">
                  <div style="margin-left: 10px;">{{'Sku' | translate | headtitlecase}}</div>
                </td>
                <td style="white-space: nowrap">{{'Sản phẩm' | translate | headtitlecase}}</td>
                <td class="text-align-right" style="white-space: nowrap">{{'Voucher.shortUnit' | translate | headtitlecase}}</td>
                <td class="text-align-right" style="white-space: nowrap">{{'Voucher.shortQuantity' | translate | headtitlecase}}</td>
                <td class="text-align-right" style="white-space: nowrap">{{'Voucher.price' | translate | headtitlecase}}</td>
                <!-- <td class="text-align-right">{{'Voucher.tax' | translate | headtitlecase}}</td> -->
                <td class="text-align-right" style="white-space: nowrap">{{'Voucher.toMoney' | translate | headtitlecase}}</td>
              </thead>
              <ng-container *ngFor="let detail of data.Details; let i = index;">
                <tr class="print-voucher-detail-line" *ngIf="detail.Type === 'CATEGORY'">
                  <td colspan="8" style="font-weight: bold;" [innerHTML]="renderValue(detail.Description)"></td>
                </tr>
                <tr class="print-voucher-detail-line" *ngIf="detail.Type !== 'CATEGORY'">
                  <td>{{detail.No}}</td>
                  <td *ngIf="showPicture">
                    <img *ngIf="detail?.Image && detail?.Image[0]?.SmallImage" style="width: 150px;" [src]="detail?.Image[0]?.SmallImage">
                  </td>
                  <td>

                    <ngx-barcode [bc-value]="detail.Product?.Sku" [bc-display-value]="false" [bc-format]="'CODE128'" [bc-height]="30" [bc-width]="1" [bc-margin]="0"></ngx-barcode>
                    <div style="margin-left: 10px">
                      <span>{{detail.Product?.Sku}}</span>
                      <span style="font-style: italic; font-size: 0.5rem;"> - ID: {{renderValue(detail.Product | objectid)}}</span>
                    </div>
                    <div style="margin-left: 10px">
                      <span *ngIf="detail.SupplierSku">Sku NCC: {{detail.SupplierSku}}</span>
                    </div>

                  </td>
                  <td>
                    <span class="description" (click)="updateSalePrice(detail)">{{renderValue(detail.Description)}}</span><br>
                    <span *ngIf="detail.SupplierProductName">Tên theo NCC: {{renderValue(detail.SupplierProductName)}}</span>
                  </td>
                  <td class="text-align-right">{{detail.Unit | objecttext}}</td>
                  <td class="text-align-right">{{renderValue(detail.Quantity) | number}}</td>
                  <td class="text-align-right">{{renderValue(detail.Price) | currency:'VND'}}</td>
                  <!-- <td class="text-align-right">{{detail.Tax | objecttext}}</td> -->
                  <td class="text-align-right" style="font-weight: bold;">{{detail['ToMoney'] | currency:'VND'}}</td>
                </tr>
              </ng-container>
              <tr style="font-weight: bold;">
                <td colspan="2">{{'Common.note' | translate | headtitlecase}}</td>
                <td *ngIf="showPicture" colspan="5" class="text-align-right">{{'Voucher.total' | translate | headtitlecase}}</td>
                <td *ngIf="!showPicture" colspan="4" class="text-align-right">{{'Voucher.total' | translate | headtitlecase}}</td>
                <td class="text-align-right">{{data['Total'] | currency:'VND'}}</td>
              </tr>
            </table>
          </div>
        </div>
        <div>
          <div class="under-line" *ngIf="data.Note"><span [innerHTML]="renderValue(data.Note) + '<br>'"></span></div><span style="font-style: italic;">Chú ý: Hàng không đúng quy cách không nhận hàng !</span>
          <!-- <div class="under-line">{{'Purchase.deliveryAddress' | translate | headtitlecase}}: -->
          <!-- {{renderValue(data.DeliveryAddress)}} -->
          <!-- </div> -->
          <!-- <div class="under-line">{{'Sales.directReceiver' | translate | headtitlecase}}:
            {{renderValue(data.DirectReceiverName)}}</div> -->
        </div>
        <div style="display: flex; flex-wrap: wrap">
          <div style="text-align: center; flex: 0 0 50%;">
            <br>
            Bên mua
            <br><br><br>
          </div>
          <div style="text-align: center; flex: 0 0 50%;">
            <br>
            Bên bán
            <br><br><br>
          </div>
        </div>
        <div class="default-print-footer" [innerHTML]="cms.systemConfigs$.value.PARAMETERS.DEFAULT_PURCHASEORDER_PRINT_FOOTER"></div>
        <div class="default-probox-one-print-footer">{{cms.env.defaultPrintFooter}}</div>
      </div>
    </nb-card-body>
    <nb-card-footer *ngIf="data.RelativeVouchers && data.RelativeVouchers.length > 0" class="print-relative-vouchers">
      <span class="tag" *ngFor="let relationVoucher of data.RelativeVouchers" nbTooltip="{{relationVoucher.type?.text}}: {{relationVoucher | objectstext}}">
        <nb-icon pack="eva" icon="pricetags-outline" class="icon"></nb-icon><span class="label" (click)="openRelativeVoucher(relationVoucher)">{{relationVoucher.type?.symbol | objectid}}:{{relationVoucher | objectid}}</span>
        <nb-icon pack="eva" icon="close-outline" class="close-btn"></nb-icon>
      </span>
    </nb-card-footer>
    <nb-card-footer>
      <div class="buttons-row">
        <button *ngIf="mode === 'print'" nbButton hero status="info" (click)="edit(data);">
          <nb-icon pack="eva" icon="edit"></nb-icon>{{'Common.edit' | translate | headtitlecase}}
        </button>
        <button nbButton hero status="info" (click)="prepareCopy(data);" style="float: left;">
          <nb-icon pack="eva" icon="copy"></nb-icon>{{'Common.copy' | translate | headtitlecase}}
        </button>
        <!-- <button nbButton hero status="primary" (click)="print();" [disabled]="!identifier"><nb-icon pack="eva" icon="printer"></nb-icon>{{'Common.print' | translate | headtitlecase}}</button> -->
        <!-- <button nbButton hero status="info" (click)="exportExcel('excel');">{{'Common.exportTo' | translate:{to: 'Excel'} | headtitlecase}}</button>
            <button nbButton hero status="info" (click)="exportExcel('pdf');">{{'Common.exportTo' | translate:{to: 'PDF'} | headtitlecase}}</button> -->
        <!-- <button nbButton hero status="danger" [disabled]="['COMPLETE'].indexOf(data.State) > -1" (click)="approvedConfirm(data, i);">{{(processMapList[i]?.nextStateLabel || '') | translate | headtitlecase}}<nb-icon pack="eva" icon="skip-forward"></nb-icon></button> -->

        <!--State process buttons-->
        <button *ngFor="let nextState of processMapList[i]?.nextStates" nbButton hero status="{{nextState.status}}" (click)="stateActionConfirm(data, nextState);">{{nextState.confirmLabel | translate | headtitlecase}}<nb-icon pack="{{nextState.iconPack || 'eva'}}" icon="{{nextState.icon || 'skip-forward'}}"></nb-icon></button>
        <!--End State process buttons-->

        <button *ngIf="!sourceOfDialog || sourceOfDialog === 'form'" nbButton hero status="success" (click)="saveAndClose(data);">
          <nb-icon pack="eva" icon="save"></nb-icon>{{'Common.saveAndClose' | translate | headtitlecase}}
        </button>
      </div>
    </nb-card-footer>
  </nb-card>
</div>