import { CommonService } from '../../../services/common.service';
import { Component, Input, ViewChild, AfterViewInit, ElementRef, OnInit, HostListener, OnDestroy } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { MytableContent } from '../../../lib/custom-element/my-components/my-table/my-table.component';
import { Subject, Subscription } from 'rxjs';
import { ActionControl } from '../../../lib/custom-element/action-control-list/action-control.interface';
import { Router } from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { environment } from '../../../../environments/environment';
import { Icon } from '../../../lib/custom-element/card-header/card-header.component';
import { agMakeSelectionColDef } from '../../../lib/custom-element/ag-list/column-define/selection.define';
import { agMakeTextColDef } from '../../../lib/custom-element/ag-list/column-define/text.define';
import { agMakeCommandColDef } from '../../../lib/custom-element/ag-list/column-define/command.define';
import { AgDynamicListComponent } from '../../general/ag-dymanic-list/ag-dymanic-list.component';
import { CollaboratorBasicStrategyProductModel } from '../../../models/collaborator.model';
import { ColDef, ColumnApi, GridApi, IRowNode } from '@ag-grid-community/core';
import { Model } from '../../../models/model';

export interface DialogActionButton {
  label: string;
  icon?: string;
  rightIcon?: string;
  status?: string;
  outline?: boolean;
  disabled?: boolean;
  focus?: boolean;
  keyShortcut?: string;
  action?: (item?: DialogActionButton, dialog?: ProgressDialogComponent) => Promise<boolean>;
};

@Component({
  selector: 'ngx-progress-dialog',
  templateUrl: './progress-dialog.component.html',
  styleUrls: ['./progress-dialog.component.scss'],
})
export class ProgressDialogComponent implements AfterViewInit, OnInit, OnDestroy {

  @Input() content: string;
  @Input() footerContent: string;
  @Input() tableContent: MytableContent;
  @Input() actions: DialogActionButton[] = [];
  @ViewChild('dialogWrap', { static: true }) dialogWrap: ElementRef;
  @Input() onClose?: (asCase?: string) => void;
  @Input() onInit: (dialog: ProgressDialogComponent) => Promise<boolean>;
  closeCase?: string = 'default';

  processing = false;

  progress = 0;
  progressStatus = 'primary';
  progressLabel = 'Loading...';
  label = 'Tiến trình';

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.cms.dialogStack[this.cms.dialogStack.length - 1] === this.ref) {
      console.log(event.key + ': listen on show case dialog...');
      const action = this.actions?.find(f => f.keyShortcut == event.key);
      if (action) {
        action.action(action, this);
        return false;
      }
      if (this.onKeyboardEvent) {
        return this.onKeyboardEvent(event, this);
      }
    }
    return true;
  }

  onButtonClick(item: DialogActionButton) {
    item?.action(item, this);
  }

  setLoading(status: boolean) {
    this.loading = status;
  }


  dismiss(asCase?: string) {
    this.closeCase = asCase;
    // this.onClose && this.onClose(asCase);
    this.ref.close();
  }

  componentName = '';
  requiredPermissions: string[] = ['ACCESS'];

  protected subcriptions: Subscription[] = [];
  protected destroy$: Subject<void> = new Subject<void>();
  @Input() reuseDialog = false;
  public showLoadinng = true;
  public sourceOfDialog: string = null;
  public loading = false;

  public overlayWraper: JQuery;
  public overlayBackdrop: JQuery;

  registerInfo: any = {
    voucherInfo: this.cms.translateText('Information.Voucher.register'),
    voucherLogo: environment.register.logo.voucher,
    voucherLogoHeight: 60,
  };


  @Input() inputMode: 'dialog' | 'page' | 'inline';
  @Input() onDialogClose?: () => void;
  @Input() onDialogHide?: () => void;
  @Input() onAfterInit?: (component?: ProgressDialogComponent) => void;
  @Input() selectedItemsAction?: (component: ProgressDialogComponent, items: Model[]) => void;

  favicon: Icon = { pack: 'eva', name: 'list', size: 'medium', status: 'primary' };
  @Input() title?: string;
  @Input() size?: string = 'medium';
  @Input() actionButtonList?: ActionControl[] = [];

  constructor(
    public cms: CommonService,
    public router: Router,
    public apiService: ApiService,
    public ref?: NbDialogRef<ProgressDialogComponent>,
  ) {
    cms.iconsLibrary.registerFontPack('ion', { iconClassPrefix: 'ion' });
    this.cms.systemConfigs$.subscribe(settings => {
      if (settings.LICENSE_INFO && settings.LICENSE_INFO.register && settings.LICENSE_INFO.register) {
        this.registerInfo.voucherInfo = settings.LICENSE_INFO.register.voucherInfo.replace(/\\n/g, '<br>');
        this.registerInfo.voucherLogo = settings.LICENSE_INFO.register.voucherLogo;
        this.registerInfo.voucherLogoHeight = settings.LICENSE_INFO.register.voucherLogoHeight;
      }
    });



    /** AG-Grid */
    this.columnDefs = [
      {
        ...agMakeSelectionColDef(this.cms),
        headerName: 'ID',
        field: 'Id',
        width: 100,
        valueGetter: 'node.data.id',
        // sortingOrder: ['desc', 'asc'],
        initialSort: 'desc',
        headerCheckboxSelection: true,
      },
      {
        ...agMakeTextColDef(this.cms),
        headerName: 'ID',
        field: 'id',
        width: 180,
        filter: 'agTextColumnFilter',
        // pinned: 'left',
      },
      {
        ...agMakeTextColDef(this.cms),
        headerName: 'Mô tả',
        field: 'text',
        width: 720,
        filter: 'agTextColumnFilter',
        // pinned: 'left',
      },
      {
        ...agMakeTextColDef(this.cms),
        headerName: 'Trạng thái',
        field: 'status',
        width: 500,
        filter: 'agTextColumnFilter',
        // pinned: 'left',
      },
      {
        ...agMakeCommandColDef(null, this.cms, false, (params) => {
          this.gridApi.applyTransaction({ remove: [params] });
        }, false, [

        ]),
        // width: 123,
        headerName: 'Lệnh',
      },
    ];
    /** End AG-Grid */

  }

  restrict() {
    this.cms.checkPermission(this.componentName, 'ACCESS', result => {
      if (!result) {
        console.warn('Bạn không có quyền truy cập ' + this.componentName + ' !!! tạm thời vẫn cho vào component nhưng sẽ phải fix lại là không cho vào component khi không có quyền');
        return false;
      }
      return true;
    });
  }

  onKeyboardEvent(event: KeyboardEvent, component?: ProgressDialogComponent) {
    return true;
  }
  onKeyupEvent(event: KeyboardEvent) {
    return true;
  }

  // @HostListener('document:keyup', ['$event'])
  // handleKeyupEvent(event: KeyboardEvent) {
  //   if (this.ref instanceof NbDialogRef) {
  //     if (this.cms.dialogStack[this.cms.dialogStack.length - 1] === this.ref) {
  //       if (event.key == 'Escape' && this.ref['originalCloseOnEsc'] === true) {
  //         this.ref.close();
  //       }
  //       return this.onKeyupEvent(event);
  //     }
  //   } else {
  //     return this.onKeyupEvent(event);
  //   }
  //   return true;
  // }

  ngOnInit(): void {
    if (!this.ref) {
      this.cms.clearHeaderActionControlList();
    }
    this.restrict();
    this.init();

    if (this.onInit) {
      this.onInit(this).then(rs => {

      });
    }

    if (this.actions) {
      for (const element of this.actions) {
        // if (!element.action) {
        // }

        if (typeof element.disabled === 'undefined') {
          element.disabled = false;
        }

        const superAction = element.action;
        element.action = async (item?: DialogActionButton, dialog?: ProgressDialogComponent) => {
          const actionRs = superAction && (await superAction(item, dialog));
          if (actionRs) {
            this.dismiss('action');
          }
          return actionRs;
        };
        if (!element.status) {
          element.status = 'info';
        }
      };
    }
  }

  async init(): Promise<boolean> {
    await this.cms.waitForReady();
    this.actionButtonList = [
      {
        name: 'refresh',
        status: 'success',
        // label: 'Refresh',
        icon: 'sync',
        title: this.cms.textTransform(this.cms.translate.instant('Common.refresh'), 'head-title'),
        size: 'medium',
        disabled: () => {
          return false;
        },
        click: () => {
          this.refresh();
          return false;
        },
      },
      {
        name: 'close',
        status: 'danger',
        // label: 'Refresh',
        icon: 'close',
        title: this.cms.textTransform(this.cms.translate.instant('Common.close'), 'head-title'),
        size: 'medium',
        disabled: () => false,
        hidden: () => !this.ref || Object.keys(this.ref).length === 0 ? true : false,
        click: () => {
          this.close();
          return false;
        },
      }
    ];
    return true;
  }

  onResume() {
    this.cms.clearHeaderActionControlList();
    this.restrict();
  }

  ngOnDestroy(): void {
    if (!this.ref) {
      this.cms.clearHeaderActionControlList();
    }
    if (this.subcriptions) {
      this.subcriptions.forEach(subciption => {
        subciption.unsubscribe();
      });
    }
    this.destroy$.next();
    this.destroy$.complete();
    setTimeout(() => {
      this.ref = null;
    }, 500);
  }

  ngAfterViewInit(): void {
    // const nativeEle = this;
    // Fix dialog scroll
    if (this.ref) {
      const dialog: NbDialogRef<ProgressDialogComponent> = this.ref;
      if (dialog && dialog.componentRef && dialog.componentRef.location && dialog.componentRef.location.nativeElement) {
        const nativeEle = dialog.componentRef.location.nativeElement;
        // tslint:disable-next-line: ban
        const compoentNativeEle = $(nativeEle);
        this.overlayWraper = compoentNativeEle.closest('.cdk-global-overlay-wrapper');
        this.overlayWraper.addClass('scrollable-container');
        this.overlayBackdrop = this.overlayWraper.prev();

        // Hide dialog
        (this.ref as any).hide = () => {
          this.overlayWraper.fadeOut(100);
          this.overlayBackdrop.fadeOut(100);
          // this.overlayWraper.removeClass('dialog');
          setTimeout(() => {
            dialog.close();
          }, 100);
          if (this.onDialogHide) this.onDialogHide();
        };

        // Show dialog
        (this.ref as any).show = (config?: { events?: { [key: string]: any } }) => {
          if (config && config.events) {
            Object.keys(config.events).forEach((eventName: string) => {
              this[eventName] = config.events[eventName];
            });
          }
          const lastBackdrop = $('.cdk-global-overlay-wrapper:last');
          lastBackdrop.after(this.overlayBackdrop);
          this.overlayBackdrop.after(this.overlayWraper);
          // this.overlayWraper.addClass('dialog');
          this.overlayWraper.fadeIn(100);
          this.overlayBackdrop.fadeIn(100);
          // if (this.onDialogHide) this.onDialogHide();
        };

        compoentNativeEle.closest('.cdk-global-overlay-wrapper').addClass('dialog');
        console.log(compoentNativeEle);
      }
    }
  }

  async refresh(): Promise<any> {
    return true;
  }

  close() {
    if (this.ref) {
      this.ref.close();
      // }
    }
  }

  hide() {
    if (this.ref && (this.ref as any).hide) {
      (this.ref as any).hide();
    }
  }

  show() {
    if (this.ref) {
    }
  }

  async loadCache(): Promise<any> {
    return true;
  }

  async clearCache(): Promise<any> {
    return true;
  }

  encodeId(id: string) {
    return this.cms.getObjectId(id || '').replace(/-/g, '~!');
  }

  decodeId(id: string) {
    return id.replace(/~!/g, '-');
  }


  /** AG-Grid */
  public gridApi: GridApi;
  public gridColumnApi: ColumnApi;
  public columnDefs: ColDef[];
  public gridParams;
  @Input() gridData: Model[] = [];

  onGridReady(params) {
    this.gridParams = params;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadList();
  }

  loadList(callback?: (list: CollaboratorBasicStrategyProductModel[]) => void) {
    if (this.gridApi) {
      // let products: CollaboratorBasicStrategyProductModel[] = (this.array.controls[0].get('Products').value || []).map((detail: CollaboratorBasicStrategyProductModel) => {
      //   return detail;
      // });
      this.gridApi.setRowData(this.gridData);
    }
  }

  onGridInit(component: AgDynamicListComponent<any>) {
    const $this = this;
    let actionButtonList = component.actionButtonList;
    // actionButtonList = actionButtonList.filter(f => f.name != 'choose');
    actionButtonList = [];
    actionButtonList.unshift({
      type: 'button',
      name: 'reset',
      title: 'Reset',
      status: 'danger',
      // label: 'Reset',
      outline: true,
      iconPack: 'eva',
      icon: 'sync-outline',
      size: 'medium',
      click: (event) => {
        component.reset();
        return true;
      }
    });
    actionButtonList.unshift({
      type: 'button',
      name: 'delete',
      title: 'Gở sản phẩm',
      status: 'danger',
      label: 'Gở',
      iconPack: 'eva',
      icon: 'minus-square-outline',
      size: 'medium',
      click: (event) => {
        const selectedNodes: IRowNode[] = this.gridApi.getSelectedNodes();
        $this.gridApi.applyTransaction({ remove: selectedNodes.map(m => m.data) });

        return true;
      }
    });
    actionButtonList.unshift({
      type: 'button',
      name: 'selectedItemsAction',
      title: 'Xử lý các dòng đã chọn',
      status: 'danger',
      label: 'Xử lý',
      iconPack: 'eva',
      icon: 'flash-outline',
      size: 'medium',
      disabled: () => component.selectedItems.length == 0,
      click: (event) => {
        this.selectedItemsAction && this.selectedItemsAction(this, component.selectedItems);
        return true;
      }
    });
    component.actionButtonList = actionButtonList;
  }

  writeLog(log: { [key: string]: any, id: string, text: string }) {
    const newRowNodeTrans = this.gridApi.applyTransaction({
      add: [log],
    });
    // newRowNodeTrans.add[0].setSelected(true);
    // this.gridApi.ensureIndexVisible(newRowNodeTrans.add[0].rowIndex, 'bottom');
  }
  /** End Ag-Grid */

}
