<form [formGroup]="form" (keydown.shift.enter)="onControlEnter($event)" (keydown.enter)="onControlEnter($event)" class="dialog-wrap popup">
    <div formArrayName="array">
        <div *ngFor="let formItem of array.controls; let i = index">
            <nb-card [nbSpinner]="isProcessing" class="small-header popup">
                <nb-card-header>
                    <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="(title ? title : ('Đơn hàng B2B' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)) + ' - Trang: ' + (formItem.value.SalesPage | objecttext) + ' => ' + ((formItem.value.SalesPage | objecttext) || 'Chưa chọn trang đối tác')" [controls]="actionButtonList"></ngx-card-header>
                </nb-card-header>
                <nb-card-body>
                    <div *ngIf="formItem.__state" class="component-state">
                        <nb-tag [status]="formItem.__state.status" appearance="filled" [text]="formItem.__state | objecttext"></nb-tag>
                    </div>
                    <div [formGroup]="formItem">
                        <label class="label label-with-border-below">{{'Common.contactInfo' | translate | headtitlecase}}</label>
                        <div class="row dashed-radius-border">
                            <div class="col-sm-12">
                                <nb-tabset>
                                    <nb-tab tabTitle="{{'Common.mainInfo' | translate | headtitlecase}} (*)">
                                        <!-- <label class="label label-with-border-below">{{'Common.mainInfo' | translate | headtitlecase}}</label> -->
                                        <div class="row">
                                            <!-- <input type="hidden" formControlName="Code"> -->
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="SalesObject" label="Voucher.organizationOrPersornal" [array]="array" [index]="i" [allowCopy]="false" [customIcons]="objectControlIcons" [touchedValidate]="false">
                                                    <ngx-select2 formControlName="SalesObject" [select2Option]="select2OptionForContact" (selectChange)="onSalesObjectChange(formItem, $event, i)"></ngx-select2>
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-6">
                                                <ngx-form-group [formGroup]="formItem" name="SalesObjectName" label="Common.name" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                                    <input type="text" nbInput fullWidth formControlName="SalesObjectName" placeholder="{{'Common.name' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <ngx-form-group [formGroup]="formItem" name="Thread" label="Luồng (theo dự án/công trình)" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                            <input type="text" nbInput fullWidth formControlName="Thread" placeholder="{{'Thread' | translate | headtitlecase}}">
                                                        </ngx-form-group>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <ngx-form-group [formGroup]="formItem" name="Code" label="ID" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                            <input type="text" disabled nbInput fullWidth formControlName="Code" placeholder="{{'ID' | translate | headtitlecase}}">
                                                        </ngx-form-group>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="SalesObjectIdentifiedNumber" label="Common.identifiedNumber" [array]="array" [index]="i" [allowCopy]="false">
                                                    <input type="text" nbInput fullWidth formControlName="SalesObjectIdentifiedNumber" placeholder="{{'Common.identifiedNumber' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-6">
                                                <ngx-form-group [formGroup]="formItem" name="SalesObjectAddress" label="Common.address" [array]="array" [index]="i" [allowCopy]="false">
                                                    <input type="text" nbInput fullWidth formControlName="SalesObjectAddress" placeholder="{{formItem.get('SalesObjectAddress').placeholder || ('Common.address' | translate | headtitlecase)}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <ngx-form-group [formGroup]="formItem" name="SalesObjectEmail" label="Common.email" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                            <input type="text" nbInput fullWidth formControlName="SalesObjectEmail" placeholder="{{formItem.get('SalesObjectEmail').placeholder || ('Common.email' | translate | headtitlecase)}}">
                                                        </ngx-form-group>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <ngx-form-group [formGroup]="formItem" name="SalesObjectPhone" label="Common.phone" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                            <input type="text" nbInput fullWidth formControlName="SalesObjectPhone" placeholder="{{formItem.get('SalesObjectPhone').placeholder || ('Common.phone' | translate | headtitlecase)}}">
                                                        </ngx-form-group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </nb-tab>
                                    <nb-tab tabTitle="{{'Common.contact' | translate | headtitlecase}}">
                                        <!-- <label class="label label-with-border-below">{{'Common.contact' | translate | headtitlecase}}</label> -->
                                        <div class="row">
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="SalesContact" label="Common.contact" [array]="array" [index]="i" [allowCopy]="false" [customIcons]="contactControlIcons">
                                                    <ngx-select2 formControlName="SalesContact" [select2Option]="select2OptionForContact" (selectChange)="onSalesContactChange(formItem, $event, i)"></ngx-select2>
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-6">
                                                <ngx-form-group [formGroup]="formItem" name="SalesContactName" label="Contact.name" [array]="array" [index]="i" [allowCopy]="false">
                                                    <input type="text" nbInput fullWidth formControlName="SalesContactName" placeholder="{{'Contact.name' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="SalesContactPhone" label="Contact.phone" [array]="array" [index]="i" [allowCopy]="false">
                                                    <input type="text" nbInput fullWidth formControlName="SalesContactPhone" placeholder="{{formItem.get('SalesContactPhone').placeholder || ('Contact.phone' | translate | headtitlecase)}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="SalesContactIdentifiedNumber" label="Common.identifiedNumber" [array]="array" [index]="i" [allowCopy]="false">
                                                    <input type="text" nbInput fullWidth formControlName="SalesContactIdentifiedNumber" placeholder="{{'Common.identifiedNumber' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-6">
                                                <ngx-form-group [formGroup]="formItem" name="SalesContactAddress" label="Contact.address" [array]="array" [index]="i" [allowCopy]="false">
                                                    <input type="text" nbInput fullWidth formControlName="SalesContactAddress" placeholder="{{formItem.get('SalesContactAddress').placeholder || ('Contact.address' | translate | headtitlecase)}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="SalesContactEmail" label="Contact.email" [array]="array" [index]="i" [allowCopy]="false">
                                                    <input type="text" nbInput fullWidth formControlName="SalesContactEmail" placeholder="{{formItem.get('SalesContactEmail').placeholder || ('Contact.email' | translate | headtitlecase)}}">
                                                </ngx-form-group>
                                            </div>
                                        </div>
                                    </nb-tab>
                                </nb-tabset>
                            </div>
                        </div>

                        <label class="label label-with-border-below">{{'Common.receivedAndPayment' | translate | headtitlecase}}</label>
                        <div class="row dashed-radius-border">
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="SalesObjectBankName" label="Bank.name" [array]="array" [index]="i" [allowCopy]="false">
                                    <input type="text" nbInput fullWidth formControlName="SalesObjectBankName" placeholder="{{'Bank.name' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-6">
                                <ngx-form-group [formGroup]="formItem" name="SalesObjectBankCode" label="Bank.code" [array]="array" [index]="i" [allowCopy]="false">
                                    <input type="text" nbInput fullWidth formControlName="SalesObjectBankCode" placeholder="{{'Bank.code' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-lg-3">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <ngx-form-group [formGroup]="formItem" name="DateOfOrder" label="{{validateVoucherDate(formItem.get('DateOfOrder'), 'Purchase.dateOfPurchaseOrder')}}" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                            <input type="text" [owlDateTime]="DateOfOrder" [owlDateTimeTrigger]="DateOfOrder" nbInput fullWidth formControlName="DateOfOrder" placeholder="{{'Purchase.dateOfPurchaseOrder' | translate | headtitlecase}}">
                                            <owl-date-time #DateOfOrder [startAt]="formItem.get('DateOfOrder')?.value || this.cms.lastVoucherDate"></owl-date-time>
                                        </ngx-form-group>
                                    </div>
                                    <div class="col-lg-6">
                                        <ngx-form-group [formGroup]="formItem" name="RequireInvoice" label="Yêu cầu xuất hóa đơn" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                            <nb-checkbox formControlName="RequireInvoice" nbTooltip="Yêu cầu người bán xuất hóa đơn VAT">Y/C xuất hóa đơn</nb-checkbox>
                                        </ngx-form-group>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12" *ngIf="formItem.get('RelativeVouchers').value?.length > 0">
                                <div class="form-group">
                                    <label class="label"><span class="valid">{{'Common.relativeVoucher' | translate | headtitlecase}}: </span></label>
                                    <div>
                                        <span class="tag" *ngFor="let relationVoucher of formItem.get('RelativeVouchers').value" nbTooltip="{{relationVoucher.typeMap?.text}}: {{relationVoucher | objectstext}}">
                                            <nb-icon pack="eva" icon="pricetags-outline" class="icon"></nb-icon><span class="label" (click)="openRelativeVoucher(relationVoucher)">{{relationVoucher.typeMap?.symbol | objectid}}:{{relationVoucher | objectid}}</span>
                                            <nb-icon pack="eva" icon="close-outline" class="close-btn" (click)="removeRelativeVoucher(formItem, relationVoucher)"></nb-icon>
                                        </span>
                                    </div>
                                    <div class="break"></div>
                                </div>
                            </div>

                            <div class="col-sm-9">
                                <ngx-form-group [formGroup]="formItem" name="DeliveryAddress" label="Sales.deliveryAddress" [array]="array" [index]="i" [allowCopy]="false">
                                    <input type="text" nbInput fullWidth formControlName="DeliveryAddress" placeholder="{{'Sales.deliveryAddress' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-3">

                                <div class="row">
                                    <div class="col-lg-6">
                                        <ngx-form-group [formGroup]="formItem" name="RelativeVouchers" label="Common.relationVoucher" [array]="array" [index]="i" [allowCopy]="false">
                                            <button class="fit-control-button" [outline]="true" nbButton status="primary" fullWidth (click)="openRelativeVoucherChoosedDialog(formItem)" size="medium" hero [disabled]="isProcessing || formItem.__disabled">
                                                <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>Thêm chứng từ liên quan
                                            </button>
                                        </ngx-form-group>
                                    </div>
                                    <div class="col-lg-6">
                                        <ngx-form-group [formGroup]="formItem" name="SalesPage" label="Trang đối tác" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                            <ngx-select2 formControlName="SalesPage" [select2Option]="select2OptionForPurchasePage" (selectChange)="onSalesPageChange($event)"></ngx-select2>
                                        </ngx-form-group>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="RelativeVouchers" label="Common.relationVoucher" [array]="array" [index]="i" [allowCopy]="false">
                                    <button class="fit-control-button" [outline]="true" nbButton status="primary" fullWidth (click)="openRelativeVoucherChoosedDialog(formItem)" size="medium" hero>
                                        <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>Thêm chứng từ liên quan
                                    </button>
                                </ngx-form-group>
                            </div> -->

                            <div class="col-sm-12">
                                <ngx-form-group [formGroup]="formItem" name="Title" label="Common.title" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                    <input type="text" nbInput fullWidth formControlName="Title" placeholder="{{'Common.title' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <!-- <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="Employee" label="Common.employee" [array]="array" [index]="i" [allowCopy]="true">
                                    <ngx-select2 formControlName="Employee" [select2Option]="selectEmployeeOption"></ngx-select2>
                                </ngx-form-group>
                            </div> -->

                            <div class="col-sm-12">
                                <ngx-form-group [formGroup]="formItem" name="Note" label="Common.note" [array]="array" [index]="i" [allowCopy]="false">
                                    <textarea class="scrollable-container" nbInput fullWidth formControlName="Note" placeholder="{{'Common.note' | translate | headtitlecase}}" rows="4"></textarea>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-12">
                                <ngx-form-group [formGroup]="formItem" name="SubNote" label="Common.subNote" [array]="array" [index]="i" [allowCopy]="false">
                                    <textarea class="scrollable-container" nbInput fullWidth formControlName="SubNote" placeholder="{{'Common.subNote' | translate | headtitlecase}}" rows="3"></textarea>
                                </ngx-form-group>
                            </div>
                        </div>

                        <label class="label label-with-border-below">{{'Hóa đơn VAT' | translate | headtitlecase}}</label>
                        <div class="row dashed-radius-border">
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="TaxAuthority" label="Mã CQT" [array]="array" [index]="i" [allowCopy]="false">
                                    <input type="text" nbInput fullWidth formControlName="TaxAuthority" placeholder="{{'Mã Cơ quan thuế' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="TaxReleaseDate" label="{{validateVoucherDate(formItem.get('TaxReleaseDate'), 'Ngày phát hành')}}" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                    <input type="text" [owlDateTime]="TaxReleaseDate" [owlDateTimeTrigger]="TaxReleaseDate" nbInput fullWidth formControlName="TaxReleaseDate" placeholder="{{'Ngày phát hành' | translate | headtitlecase}}">
                                    <owl-date-time #TaxReleaseDate [startAt]="formItem.get('TaxReleaseDate')?.value"></owl-date-time>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="TaxSymbol" label="Ký hiệu" [array]="array" [index]="i" [allowCopy]="false">
                                    <input type="text" nbInput fullWidth formControlName="TaxSymbol" placeholder="{{'Ký hiệu' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="TaxNo" label="Số" [array]="array" [index]="i" [allowCopy]="false">
                                    <input type="text" nbInput fullWidth formControlName="TaxNo" placeholder="{{'Số' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-6">
                                <ngx-form-group [formGroup]="formItem" name="TaxPdf" label="File PDF" [array]="array" [index]="i" [allowCopy]="true">
                                    <ngx-file-input formControlName="TaxPdf"></ngx-file-input>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-6">
                                <ngx-form-group [formGroup]="formItem" name="TaxXml" label="File XML" [array]="array" [index]="i" [allowCopy]="true">
                                    <ngx-file-input formControlName="TaxXml"></ngx-file-input>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-12">
                                <ngx-form-group [formGroup]="formItem" name="TaxSupplierName" label="Tên nhà cung cấp hóa đơn" [array]="array" [index]="i" [allowCopy]="false">
                                    <input type="text" nbInput fullWidth formControlName="TaxSupplierName" placeholder="{{'Tên nhà cung cấp hóa đơn' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-6">
                                <ngx-form-group [formGroup]="formItem" name="TaxQueryLink" label="Link tra cứu" [array]="array" [index]="i" [allowCopy]="false">
                                    <input type="text" nbInput fullWidth formControlName="TaxQueryLink" placeholder="{{'Link tra cứu' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-6">
                                <ngx-form-group [formGroup]="formItem" name="TaxQueryCode" label="Mã tra cứu" [array]="array" [index]="i" [allowCopy]="false">
                                    <input type="text" nbInput fullWidth formControlName="TaxQueryCode" placeholder="{{'Mã tra cứu' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                        </div>


                        <!-- Detail form -->
                        <label class="label">{{'Voucher.productOrservice' | translate | headtitlecase}}</label>
                        <div formArrayName="Details" class="form-details" [sortablejs]="formItem._details" [sortablejsOptions]="{ handle: '.sorting-handle' }">
                            <!-- <cdk-virtual-scroll-viewport #detailsViewport [itemSize]="240" style="height: 80vh;" class="scrollable-container"> -->

                            <div class="form-detail-item" *ngFor="let detail of formItem._details.controls; let ic=index" [formGroup]="detail" style="height: 240px;">
                                <hr>
                                <div class="row">
                                    <div class="col-xl-2 col-lg-2 col-md-4 col-sm-12 col-is-12">
                                        <div class="row">
                                            <div class="col-md-3 col-sm-3 col-is-3">
                                                <ngx-form-group *ngIf="detail.__type !== 'CATEGORY'" [hideLabel]="false" [formGroup]="detail" name="No" label="STT" [array]="formItem._details" [index]="ic" [allowCopy]="false">
                                                    <input nbInput class="sorting-handle" title="Kéo thả để di chuyển" fullWidth formControlName="No" value="{{ic+1}}" readonly style="text-align: center; cursor: grab; width: 100%; height: 2.5rem; padding: 0;">
                                                    <!-- <input type="hidden" formControlName="Id"> -->
                                                </ngx-form-group>
                                                <div class="form-group">
                                                    <label class="label" *ngIf="detail.__type === 'CATEGORY'">Gở</label>
                                                    <button nbButton [outline]="true" status="{{detail.__type !== 'CATEGORY' ? 'danger' : 'primary'}}" hero size="medium" (click)="removeDetailGroup(formItem, detail, ic)" style="width: 100%; margin-bottom: 3px;" [disabled]="isProcessing || formItem.__disabled">
                                                        <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                                                    </button>
                                                </div>
                                                <div class="form-group" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'">
                                                    <button nbButton [outline]="true" status="primary" hero size="medium" (click)="addDetailAfter(formItem, detail, ic)" style="width: 100%; margin-bottom: 3px;" nbTooltip="Chèn dòng bên dưới" [disabled]="isProcessing || formItem.__disabled">
                                                        <nb-icon pack="eva" icon="corner-down-right-outline"></nb-icon>
                                                    </button>
                                                </div>
                                            </div>

                                            <div class="col-md-9 col-sm-9 col-is-9">
                                                <ngx-form-group *ngIf="detail.__type !== 'CATEGORY'" [hideLabel]="false" [formGroup]="detail" name="PurchaseImage" label="Hỉnh ảnh" [array]="formItem._details" [index]="ic" [allowCopy]="false">
                                                    <ngx-files-input formControlName="PurchaseImage" [config]="{style: {height: detail.__type === 'CATEGORY' && '2.5rem' || '11.5rem', padding: '0'}, thumbnailStype: {height: '10.8rem', width: '100%'}}"></ngx-files-input>
                                                </ngx-form-group>
                                                <div class="form-group" *ngIf="detail.__type === 'CATEGORY'">
                                                    <label class="label">Di chuyển</label>
                                                    <input type="text" nbInput fullWidth placeholder="Kéo để di chuyển" disabled class="sorting-handle" style="cursor: pointer;">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-10 col-lg-10 col-md-8 col-sm-12 col-is-12">
                                        <div class="row">
                                            <div class="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-is-12">
                                                <div class="row">
                                                    <div class="col-lg-{{detail.__type !== 'CATEGORY' ? '4' : '12'}} col-sm-{{detail.__type !== 'CATEGORY' ? '3' : '12'}} col-is-12">
                                                        <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Type" label="Common.type" [array]="formItem._details" [index]="ic" [allowCopy]="false">
                                                            <ngx-select2 [data]="select2DataForType" formControlName="Type" [select2Option]="select2OptionForType"></ngx-select2>
                                                        </ngx-form-group>
                                                    </div>
                                                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-is-12" *ngIf="detail.__type !== 'CATEGORY'">
                                                        <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="PurchaseProduct" label="Hàng hóa" [array]="formItem._details" [index]="ic" [allowCopy]="false" [customIcons]="getCustomIcons(i + ic)">
                                                            <ngx-select2 formControlName="PurchaseProduct" [select2Option]="select2OptionForProduct" (selectChange)="onSelectProduct(detail, $event, formItem)"></ngx-select2>
                                                        </ngx-form-group>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-is-12" *ngIf="detail.__type !== 'CATEGORY'">
                                                        <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="PurchaseBusiness" label="Common.accountingBusiness" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="true" [touchedValidate]="false">
                                                            <ngx-select2 [data]="accountingBusinessList" formControlName="PurchaseBusiness" (selectChange)="toMoney(formItem, detail, 'PurchaseBusiness', ic)" [select2Option]="select2OptionForAccountingBusiness"></ngx-select2>
                                                        </ngx-form-group>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-is-12">
                                                <div class="row">
                                                    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-is-6" *ngIf="detail.__type !== 'CATEGORY'">
                                                        <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Quantity" label="Voucher.quantity" [array]="formItem._details" [index]="ic" [allowCopy]="false" [align]="'right'">
                                                            <input type="text" nbInput fullWidth formControlName="Quantity" placeholder="{{'Voucher.quantity' | translate | headtitlecase}}" class="align-right" [inputMask]="towDigitsInputMask">
                                                        </ngx-form-group>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-is-6" *ngIf="detail.__type !== 'CATEGORY'">
                                                        <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="PurchaseUnit" label="Voucher.unit" [array]="formItem._details" [index]="ic" [allowCopy]="false">
                                                            <ngx-select2 formControlName="PurchaseUnit" [data]="detail['UnitList'] || unitList" [select2Option]="select2OptionForUnit"></ngx-select2>
                                                        </ngx-form-group>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-is-6" *ngIf="detail.__type !== 'CATEGORY'">
                                                        <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Price" label="Voucher.price" [array]="formItem._details" [index]="ic" [allowCopy]="false" [align]="'right'">
                                                            <input type="text" nbInput fullWidth formControlName="Price" placeholder="{{'Voucher.price' | translate | headtitlecase}}" class="align-right" currencyMask [options]="curencyFormat">
                                                        </ngx-form-group>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-4 col-md-3 col-sm-6 col-is-6" *ngIf="detail.__type !== 'CATEGORY'">
                                                        <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="ToMoney" label="Voucher.toMoney" [array]="formItem._details" [index]="ic" [allowCopy]="false" [align]="'right'">
                                                            <input type="text" nbInput fullWidth formControlName="ToMoney" placeholder="Thành tiền" class="align-right" [ngClass]="{'imported': detail?.IsImport}" currencyMask [options]="curencyFormat">
                                                        </ngx-form-group>
                                                    </div>
                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-is-12">
                                                        <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Description" label="Mô tả" [array]="formItem._details" [index]="ic" [allowCopy]="false">
                                                            <textarea class="scrollable-container" nbInput fullWidth formControlName="Description" placeholder="{{'Mô tả' | translate | headtitlecase}}" [ngClass]="{'imported': detail?.IsImport}"></textarea>
                                                        </ngx-form-group>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-is-12" *ngIf="detail.__type !== 'CATEGORY'">
                                                <div class="row">
                                                    <div class="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-is-12">
                                                        <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="SalesSku" label="Sku NCC" [array]="formItem._details" [index]="ic" [allowCopy]="false">
                                                            <input type="text" nbInput fullWidth formControlName="SalesSku" placeholder="{{'Sku NCC' | translate | headtitlecase}}">
                                                        </ngx-form-group>
                                                    </div>
                                                    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-is-12">
                                                        <div class="row">
                                                            <div class="col-xl-5 col-lg-5 col-md-4 col-sm-12 col-is-12">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="SalesProductName" label="Tên theo bên bán" [array]="formItem._details" [index]="ic" [allowCopy]="false">
                                                                    <input type="text" nbInput fullWidth formControlName="SalesProductName" placeholder="{{'Tên theo bên bán' | translate | headtitlecase}}">
                                                                </ngx-form-group>
                                                            </div>
                                                            <div class="col-xl-5 col-lg-5 col-md-4 col-sm-12 col-is-12">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="SalesProductTaxName" label="Tên thuế theo bên bán" [array]="formItem._details" [index]="ic" [allowCopy]="false">
                                                                    <input type="text" nbInput fullWidth formControlName="SalesProductTaxName" placeholder="{{'Tên thuế theo bên bán' | translate | headtitlecase}}">
                                                                </ngx-form-group>
                                                            </div>
                                                            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-12 col-is-12">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="SalesTax" label="Thuế VAT bên bán" [array]="formItem._details" [index]="ic" [allowCopy]="false" [align]="'right'">
                                                                    <input type="text" nbInput fullWidth formControlName="SalesTax" placeholder="{{'Thuế VAT bên bán' | translate | headtitlecase}}" class="align-right">
                                                                </ngx-form-group>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- </cdk-virtual-scroll-viewport> -->
                            <hr>
                            <div class="row" style="display: flex; align-items: center">
                                <div class="buttons-row" style="flex: 1">
                                    <button nbButton status="success" [outline]="true" (click)="addDetailFormGroup(formItem)" size="medium" hero [disabled]="isProcessing || formItem.__disabled">
                                        <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>{{'Thêm hàng hóa' | translate | headtitlecase}}
                                    </button>
                                    <!-- <button nbButton status="primary" (click)="addMultiProducts(formItem)" size="medium" hero [disabled]="isProcessing || formItem.__disabled">
                                        <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>{{'Thêm nhiều hàng hóa/dịch vụ' | translate | headtitlecase}}
                                    </button> -->
                                    <!-- <button [disabled]="!formItem.get('Object')?.value?.id || processing || formItem.__disabled" type="button" nbButton status="danger" hero size="medium" (click)="chooseFile.click() && false" title="{{'Common.choose' | translate | headtitlecase}}">
                                        <nb-icon pack="eva" icon="file"></nb-icon>{{formItem.get('Object')?.value?.id && 'Nhập chi tiết' || 'Chọn nhà cung cấp để nhập chi tiết'}}
                                    </button>
                                    <input type="file" #chooseFile (change)="chooseFileAndFillDetails(formItem, $event)" style="display: none">
                                    <button [disabled]="!formItem.get('Object')?.value?.id || processing || formItem.__disabled" type="button" nbButton status="danger" hero size="medium" (click)="chooseFile2.click() && false" title="{{'Common.choose' | translate | headtitlecase}}">
                                        <nb-icon pack="eva" icon="file"></nb-icon>{{formItem.get('Object')?.value?.id && 'Nhập chi tiết từ báo giá' || 'Chọn nhà cung cấp để nhập chi tiết từ báo giá'}}
                                    </button>
                                    <input type="file" #chooseFile2 (change)="chooseFileAndImportDetails(formItem, $event)" style="display: none"> -->
                                </div>
                                <div style="font-weight: bold; padding-right: 28px;">{{'Voucher.total' | translate | headtitlecase}}: {{formItem.get('_total').value | currency:'VND'}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12" style="text-align: right;">

                            </div>
                        </div>
                        <!-- End Detail form -->

                    </div>
                </nb-card-body>
                <nb-card-footer>
                    <div class="buttons-row" style="text-align: right;">
                        <button nbButton hero status="primary" (click)="preview(formItem)" [disabled]="!form.valid || isProcessing">
                            <nb-icon nb-icon pack="eva" icon="printer"></nb-icon>{{'Common.print' | translate | headtitlecase}}
                        </button>
                        <button nbButton status="danger" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
                        <button nbButton status="success" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="save()">{{'Common.save' | translate | headtitlecase}}</button>
                    </div>
                </nb-card-footer>
            </nb-card>
        </div>
    </div>
</form>