import { CurrencyPipe } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NbToastrService, NbDialogService, NbDialogRef, NbThemeService } from "@nebular/theme";
import { GridApi, ColumnApi, ColDef, IRowNode } from "@ag-grid-community/core";
import { AgNumberCellInput } from "../../../../../lib/custom-element/ag-list/cell/input/number.component";
import { AgTextCellRenderer } from "../../../../../lib/custom-element/ag-list/cell/text.component";
import { agMakeCommandColDef } from "../../../../../lib/custom-element/ag-list/column-define/command.define";
import { agMakeImageColDef } from "../../../../../lib/custom-element/ag-list/column-define/image.define";
import { agMakeSelectionColDef } from "../../../../../lib/custom-element/ag-list/column-define/selection.define";
import { DataManagerFormComponent } from "../../../../../lib/data-manager/data-manager-form.component";
import { ProductUnitModel, ProductModel } from "../../../../../models/product.model";
import { ApiService } from "../../../../../services/api.service";
import { CommonService } from "../../../../../services/common.service";
import { RootServices } from "../../../../../services/root.services";
import { AgDynamicListComponent } from "../../../../general/ag-dymanic-list/ag-dymanic-list.component";
import { RowNode } from "ag-grid-community";
import { agMakeCurrencyColDef } from "../../../../../lib/custom-element/ag-list/column-define/currency.define";
import { B2bBasicSalesCommissionConfigModel, B2bBasicSalesCommissionConfigProductModel } from "../../../../b2b-center/b2b-center.model";
import { B2bServerService } from "../../../b2b-server.service";
import { AgSelect2Filter } from "../../../../../lib/custom-element/ag-list/filter/select2.component.filter";
import { DialogFormComponent } from "../../../../dialog/dialog-form/dialog-form.component";
import { Model } from "../../../../../models/model";
import { B2bPublicProductListComponent } from "../../../../b2b-center/product/public/public-product-list/public-product-list.component";

@Component({
  selector: 'ngx-b2b-basic-commision-config-form',
  templateUrl: './basic-commission-config-form.component.html',
  styleUrls: ['./basic-commission-config-form.component.scss'],
  providers: [
    CurrencyPipe
  ]
})
export class B2bBasicCommissionConfigFormComponent extends DataManagerFormComponent<B2bBasicSalesCommissionConfigModel> implements OnInit {


  componentName: string = 'B2bBasicCommissionConfigFormComponent';
  idKey = ['Code'];
  // baseFormUrl = '/collaborator/basic-strategy/form';
  apiPath = '/b2b-server/commission/config/basics';
  themeName = this.themeService.currentTheme == 'default' ? '' : this.themeService.currentTheme;
  unitList: ProductUnitModel[] = [];

  constructor(
    public rsv: RootServices,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public toastrService: NbToastrService,
    public dialogService: NbDialogService,
    public cms: CommonService,
    public ref?: NbDialogRef<B2bBasicCommissionConfigFormComponent>,
    public b2bServerService?: B2bServerService,
    public themeService?: NbThemeService,
    public onDetectChangeRef?: ChangeDetectorRef
  ) {
    super(rsv, activeRoute, router, formBuilder, apiService, toastrService, dialogService, cms);


    const $this = this;
    /** AG-Grid */
    this.columnDefs = [
      {
        ...agMakeSelectionColDef(this.cms),
        headerName: 'STT',
        field: 'Id',
        valueGetter: 'node.data.Product',
      },
      {
        ...agMakeImageColDef(this.cms),
        headerName: 'Hình',
        field: 'FeaturePicture',
        width: 100,
      },
      {
        headerName: 'Sku',
        field: 'Sku',
        width: 100,
        filter: 'agTextColumnFilter',
        cellRenderer: AgTextCellRenderer,
        pinned: 'left',
      },
      {
        headerName: 'Danh mục',
        field: 'Categories',
        // pinned: 'left',
        width: 200,
        cellRenderer: AgTextCellRenderer,
        filter: AgSelect2Filter,
        filterParams: {
          select2Option: {
            ...this.cms.select2OptionForTemplate,
            multiple: true,
            logic: 'OR',
            allowClear: true,
            ajax: {
              delay: 300,
              data: function (params) {
                return {
                  ...params,
                  offset: params['offset'] || 0,
                  limit: params['limit'] || 10
                };
              },
              transport: (settings: JQueryAjaxSettings, success?: (data: any) => null, failure?: () => null) => {
                console.log(settings);
                const params = settings.data;
                const rs = this.categoryFilterData.filter(f => this.cms.smartFilter(f.text, params['term']));
                success({ data: rs, total: rs.length });
                return null;
              },
              processResults: (rs: { data: any[], total: number }, params: any) => {
                const data = rs.data;
                const total = rs.total;
                params.limit = params.limit || 10;
                params.offset = params.offset || 0;
                params.offset = params.offset += params.limit;
                return {
                  results: data.map(item => {
                    item.thumbnail = item?.FeaturePicture?.Thumbnail;
                    return item;
                  }),
                  pagination: {
                    more: params.offset < total
                  }
                };
              },
            }
          }
        },
      },
      {
        headerName: 'Tên sản phẩm',
        field: 'ProductName',
        width: 400,
        filter: 'agTextColumnFilter',
        cellRenderer: AgTextCellRenderer,
        // pinned: 'left',
      },
      {
        headerName: 'ĐVT',
        field: 'Unit',
        width: 110,
        filter: 'agTextColumnFilter',
        cellRenderer: AgTextCellRenderer,
        // pinned: 'right',
      },
      {
        ...agMakeCurrencyColDef(this.cms),
        headerName: 'Giá EU',
        field: 'ListedPrice',
        width: 150,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Chiết khấu',
        field: 'CommissionRatio',
        width: 120,
        filter: 'agTextColumnFilter',
        cellRenderer: AgNumberCellInput,
        cellRendererParams: {
          takeUntilDelay: 0,
          changed: (commissionRatio: number, params: { node: RowNode }) => {
            params.node.setDataValue('Price', this.calculateDiscountPrice(params?.node?.data));
            params['status'] = 'success';
            return true;
          }
        },
      },
      {
        ...agMakeCurrencyColDef(this.cms),
        headerName: 'Giá sau CK',
        field: 'Price',
        width: 150,
        filter: 'agTextColumnFilter',
        valueGetter: params => this.calculateDiscountPrice(params?.node?.data),
      },
      // {
      //   headerName: 'Thưởng tuần',
      //   field: 'Level1WeeklyAwardRatio',
      //   width: 150,
      //   filter: 'agTextColumnFilter',
      //   cellRenderer: AgTextCellRenderer,
      //   // pinned: 'right',
      // },
      // {
      //   headerName: 'Thưởng tháng',
      //   field: 'Level1MonthlyAwardRatio',
      //   width: 150,
      //   filter: 'agTextColumnFilter',
      //   cellRenderer: AgTextCellRenderer,
      //   // pinned: 'right',
      // },
      // {
      //   headerName: 'Thưởng quý',
      //   field: 'Level1QuarterlyAwardRatio',
      //   width: 150,
      //   filter: 'agTextColumnFilter',
      //   cellRenderer: AgTextCellRenderer,
      //   // pinned: 'right',
      // },
      // {
      //   headerName: 'Thưởng năm',
      //   field: 'Level1YearlyAwardRatio',
      //   width: 150,
      //   filter: 'agTextColumnFilter',
      //   cellRenderer: AgTextCellRenderer,
      //   // pinned: 'right',
      // },
      {
        ...agMakeCommandColDef(null, this.cms, false, (params) => {
          this.gridApi.applyTransaction({ remove: [params] });
          this.updateCategoryFilterData();
        }, false, [
          // {
          //   name: 'setting',
          //   title: 'Cài đặt',
          //   icon: 'settings-2-outline',
          //   status: 'primary',
          //   outline: false,
          //   action: async (params) => {
          //     this.cms.openDialog(B2bBasicSalesCommissionConfigProductFormComponent, {
          //       context: {
          //         data: [
          //           params.node.data,
          //         ],
          //         onDialogSave(newData) {
          //           console.log(newData);
          //           let currentNode: IRowNode = $this.gridApi.getRowNode($this.cms.getObjectId(params.data.Product) + '-' + $this.cms.getObjectId(params.data.Unit));
          //           currentNode.setData(newData[0]);
          //         },
          //       }
          //     });
          //     return true;
          //   }
          // },
        ]),
        // width: 123,
        headerName: 'Lệnh',
      },
    ];
    /** End AG-Grid */
  }

  calculateDiscountPrice(data: B2bBasicSalesCommissionConfigProductModel): number {
    return (data.ListedPrice || 0) - (data.ListedPrice || 0) * (data.CommissionRatio || 0) / 100;
  }

  /** AG-Grid */
  public gridApi: GridApi;
  public gridColumnApi: ColumnApi;
  public columnDefs: ColDef[];
  public gridParams;

  public categoryFilterData = [];

  onGridReady(params) {
    this.gridParams = params;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadList();
  }

  loadList(callback?: (list: B2bBasicSalesCommissionConfigProductModel[]) => void) {
    if (this.gridApi) {
      let products: B2bBasicSalesCommissionConfigProductModel[] = (this.array.controls[0].get('Products').value || []).map((detail: B2bBasicSalesCommissionConfigProductModel) => {
        return detail;
      });
      this.gridApi.setRowData(products);
      this.updateCategoryFilterData();
    }
  }
  select2OptionForPage = {
    placeholder: 'Chọn trang...',
    allowClear: false,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
  };

  async loadCache() {
    // iniit category
    // this.categoryList = (await this.apiService.getPromise<ProductCategoryModel[]>('/admin-product/categories', { limit: 'nolimit' })).map(cate => ({ id: cate.Code, text: cate.Name })) as any;
    // this.groupList = (await this.apiService.getPromise<ProductGroupModel[]>('/admin-product/groups', { limit: 'nolimit' })).map(cate => ({ id: cate.Code, text: cate.Name })) as any;
    // this.productList = (await this.apiService.getPromise<ProductModel[]>('/admin-product/products', { limit: 100, includeIdText: true }));
  }

  getRequestId(callback: (id?: string[]) => void) {
    if (this.mode === 'page') {
      super.getRequestId(callback);
    } else {
      callback(this.inputId);
    }
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  async init() {
    await this.loadCache();
    return super.init().then(rs => {
      return rs;
    });
  }

  /** Execute api get */
  executeGet(params: any, success: (resources: ProductModel[]) => void, error?: (e: HttpErrorResponse) => void) {
    params['includeProducts'] = true;
    super.executeGet(params, success, error);
  }

  async formLoad(formData: ProductModel[], formItemLoadCallback?: (index: number, newForm: FormGroup, formData: ProductModel) => void) {
    return super.formLoad(formData, async (index, newForm, itemFormData) => {

      if (this.gridApi) {
        this.loadList();
      }

      // Direct callback
      if (formItemLoadCallback) {
        formItemLoadCallback(index, newForm, itemFormData);
      }
    });
  }

  makeNewFormGroup(data?: ProductModel): FormGroup {
    const currentDate = new Date();
    const newForm = this.formBuilder.group({
      Code: { value: '', disabled: true },
      Title: ['', Validators.required],
      Page: [this.b2bServerService.currentpage$.value, Validators.required],
      // DateRange: [[Date.today(), Date.today().next().month()], Validators.required],
      DateOfStart: [],
      DateOfEnd: [],
      Products: [[]],
    });
    if (data) {
      // data.DateRange = [data.DateOfStart, data.DateOfEnd];
      newForm.patchValue(data);
    }
    return newForm;
  }
  onAddFormGroup(index: number, newForm: FormGroup, formData?: ProductModel): void {
    super.onAddFormGroup(index, newForm, formData);
  }
  onRemoveFormGroup(index: number): void {

  }

  goback(): false {
    super.goback();
    if (this.mode === 'page') {
      this.router.navigate(['/admin-product/product/list']);
    } else {
      this.ref.close();
    }
    return false;
  }

  onUpdatePastFormData(aPastFormData: { formData: any; meta: any; }): void { }
  onUndoPastFormData(aPastFormData: { formData: any; meta: any; }): void { }

  /** Execute api put */
  executePut(params: any, data: ProductModel[], success: (data: ProductModel[]) => void, error: (e: any) => void) {
    return super.executePut(params, data, success, error);
  }

  /** Execute api post */
  executePost(params: any, data: ProductModel[], success: (data: ProductModel[]) => void, error: (e: any) => void) {
    return super.executePost(params, data, success, error);
  }

  getRawFormData() {
    const data = super.getRawFormData();
    for (const item of data.array) {
      // Extract date range
      // if (item.DateRange) {
      //   item.DateOfStart = item.DateRange[0];
      //   item.DateOfEnd = item.DateRange[1];
      // }

      // Get details data from ag-grid
      item.Products = [];
      this.gridApi.forEachNode((rowNode, index) => {
        console.log(rowNode, index);
        const rawDetail = {};
        for (const prop in rowNode.data) {
          rawDetail[prop] = this.cms.getObjectId(rowNode.data[prop]);
        }
        item.Products.push(rawDetail);
      });
    }
    return data;
  }

  async save(): Promise<ProductModel[]> {
    return super.save();
  }

  updateCategoryFilterData() {
    const categoryFilterDataMap = {};
    this.gridApi.forEachNode((rowNode, imdex) => {
      if (Array.isArray(rowNode.data?.Categories)) {
        for (const i in rowNode.data.Categories) {
          categoryFilterDataMap[this.cms.getObjectId(rowNode.data.Categories[i])] = rowNode.data.Categories[i];
        }
      }
    });
    this.categoryFilterData = Object.keys(categoryFilterDataMap).map(k => categoryFilterDataMap[k]);
  }

  onGridInit(component: AgDynamicListComponent<any>) {
    // const $this = this;
    let actionButtonList = component.actionButtonList;
    // actionButtonList = actionButtonList.filter(f => f.name != 'choose');
    actionButtonList = [];
    actionButtonList.unshift({
      type: 'button',
      name: 'reset',
      title: 'Reset',
      status: 'danger',
      // label: 'Reset',
      outline: true,
      iconPack: 'eva',
      icon: 'sync-outline',
      size: 'medium',
      click: (event) => {
        component.reset();
        return true;
      }
    });

    actionButtonList.unshift({
      type: 'button',
      name: 'delete',
      title: 'Gở sản phẩm',
      status: 'danger',
      label: 'Gở',
      iconPack: 'eva',
      icon: 'minus-square-outline',
      size: 'medium',
      click: (event) => {
        const selectedNodes: IRowNode[] = this.gridApi.getSelectedNodes();
        this.gridApi.applyTransaction({ remove: selectedNodes.map(m => m.data) });
        this.updateCategoryFilterData();
        return true;
      }
    });
    actionButtonList.unshift({
      type: 'button',
      name: 'addProduct',
      title: 'Thêm sản phẩm',
      status: 'success',
      label: 'Thêm sản phẩm',
      iconPack: 'eva',
      icon: 'plus-square-outline',
      size: 'medium',
      click: (event) => {
        // const selectedNodes: IRowNode[] = this.gridApi.getSelectedNodes();

        this.cms.showDialog('Thêm sản phẩm chiết khấu cơ bản', 'Bạn muốn thêm tất cả sản phẩm, thêm theo danh muc hay thêm từng sản phẩm ?', [
          {
            label: 'Tất cả',
            status: 'danger',
            action: async (item, dialog) => {
              dialog.loading = true;
              let productList = [];
              const limit = 100;
              let offset = 0;
              try {
                do {
                  productList = await this.apiService.getPromise<ProductModel[]>('/b2b-center/product/publics', {
                    includeChildrenCategories: true,
                    includeListedPrice: true,
                    limit: limit,
                    offset: offset,
                    page: this.cms.getObjectId(this.b2bServerService.currentpage$.value),
                  });

                  const data = [];
                  for (const i in productList) {
                    const chooseItem = productList[i];
                    for (const u in chooseItem.UnitConversions) {
                      const unitConversion = chooseItem.UnitConversions[u];
                      const idKey = this.cms.getObjectId(chooseItem.Product) + '-' + this.cms.getObjectId(unitConversion.Unit);
                      if (!(this.gridApi as any).rowModel?.nodeManager?.allNodesMap[idKey]) {
                        data.push({
                          id: this.cms.getObjectId(chooseItem.Product),
                          text: chooseItem.ProductName,
                          Product: chooseItem.Product,
                          ProductName: chooseItem.ProductName,
                          Sku: chooseItem.Sku,
                          Unit: typeof unitConversion.Unit == 'string' ? { id: unitConversion.Unit, text: unitConversion.UnitLabel } : unitConversion.Unit,
                          UnitLabel: unitConversion.UnitLabel,
                          Pictures: chooseItem.Pictures,
                          FeaturePicture: chooseItem.FeaturePicture,
                          Categories: chooseItem.Categories,
                          Groups: chooseItem.Groups,
                          ListedPrice: unitConversion.ListedPrice,
                        });
                      } else {
                        console.warn('Duplicate: ' + idKey);
                      }
                    }
                  }
                  const newRowNodeTrans = this.gridApi.applyTransaction({
                    add: data
                  });
                  offset += limit;
                } while (productList.length >= limit);
                dialog.loading = false;
                this.updateCategoryFilterData();
              } catch (err) {
                dialog.loading = false;
                console.error(err);
              }
              return true;
            },
          },
          {
            label: 'Theo danh mục',
            status: 'primary',
            action: () => {
              this.cms.openDialog(DialogFormComponent, {
                context: {
                  width: '512px',
                  controls: [
                    {
                      name: 'Categories',
                      label: 'Danh mục',
                      type: 'select2',
                      option: {
                        ...this.cms.makeSelect2AjaxOption('/admin-product/categories', { onlyIdText: true }, {
                          prepareReaultItem: (item) => {
                            // item.label = item.id + ' - ' + item.text;
                            return item;
                          }
                        }),
                        placeholder: 'Chọn danh mục...',
                        allowClear: false,
                        width: '100%',
                        dropdownAutoWidth: true,
                        minimumInputLength: 0,
                        multiple: true,
                        keyMap: {
                          id: 'id',
                          text: 'text',
                        },
                      },
                    }
                  ],
                  actions: [
                    {
                      label: 'Back',
                      status: 'basic',
                      outline: true,
                      action: async () => {
                        return true;
                      }
                    },
                    {
                      label: 'Thêm',
                      status: 'primary',
                      action: async (form, dialog) => {
                        const categories = form.get('Categories').value as Model[];
                        console.log(categories);

                        if (!categories || categories.length == 0) {
                          this.cms.showToast('Bạn chưa chọn danh mục', 'Chưa chọn danh mục', { status: 'warning' });
                          return false;
                        }

                        let productList = [];
                        const limit = 100;
                        let offset = 0;
                        do {
                          productList = await this.apiService.getPromise<ProductModel[]>('/b2b-center/product/publics', {
                            includeChildrenCategories: true,
                            includeListedPrice: true,
                            eq_Categories: '[' + categories.map(m => this.cms.getObjectId(m)).join(',') + ']',
                            limit: limit,
                            offset: offset
                          });

                          const data = [];
                          for (const i in productList) {
                            const chooseItem = productList[i];
                            for (const u in chooseItem.UnitConversions) {
                              const unitConversion = chooseItem.UnitConversions[u];
                              const idKey = this.cms.getObjectId(chooseItem.Product) + '-' + this.cms.getObjectId(unitConversion.Unit);
                              if (!(this.gridApi as any).rowModel?.nodeManager?.allNodesMap[idKey]) {
                                data.push({
                                  id: this.cms.getObjectId(chooseItem.Product),
                                  text: chooseItem.ProductName,
                                  Product: chooseItem.Product,
                                  ProductName: chooseItem.ProductName,
                                  Sku: chooseItem.Sku,
                                  Unit: typeof unitConversion.Unit == 'string' ? { id: unitConversion.Unit, text: unitConversion.UnitLabel } : unitConversion.Unit,
                                  UnitLabel: unitConversion.UnitLabel,
                                  Pictures: chooseItem.Pictures,
                                  FeaturePicture: chooseItem.FeaturePicture,
                                  Categories: chooseItem.Categories,
                                  Groups: chooseItem.Groups,
                                  ListedPrice: unitConversion.ListedPrice,
                                });
                              } else {
                                console.warn('Duplicate: ' + idKey);
                              }
                            }
                          }
                          const newRowNodeTrans = this.gridApi.applyTransaction({
                            add: data
                          });
                          offset += limit;
                        } while (productList.length >= limit);
                        this.updateCategoryFilterData();
                        return true;
                      }
                    },
                  ],
                }
              });
            },
          },
          {
            label: 'Chọn sản phẩm',
            status: 'info',
            action: () => {
              this.cms.openDialog(B2bPublicProductListComponent, {
                context: {
                  extendsParams: {
                    includeListedPrice: true,
                  },
                  onDialogChoose: (chooseItems) => {
                    console.log(chooseItems);
                    const data = [];
                    for (const i in chooseItems) {
                      const chooseItem = chooseItems[i];
                      for (const u in chooseItem.UnitConversions) {
                        const unitConversion = chooseItem.UnitConversions[u];
                        const idKey = this.cms.getObjectId(chooseItem.Product) + '-' + this.cms.getObjectId(unitConversion.Unit);
                        if (!(this.gridApi as any).rowModel?.nodeManager?.allNodesMap[idKey]) {
                          data.push({
                            id: this.cms.getObjectId(chooseItem.Product),
                            text: chooseItem.ProductName,
                            Product: chooseItem.Product,
                            ProductName: chooseItem.ProductName,
                            Sku: chooseItem.Sku,
                            Unit: typeof unitConversion.Unit == 'string' ? { id: unitConversion.Unit, text: unitConversion.UnitLabel } : unitConversion.Unit,
                            UnitLabel: unitConversion.UnitLabel,
                            Pictures: chooseItem.Pictures,
                            FeaturePicture: chooseItem.FeaturePicture,
                            Categories: chooseItem.Categories,
                            Groups: chooseItem.Groups,
                            ListedPrice: unitConversion.ListedPrice,
                          });
                        } else {
                          console.warn('Duplicate: ' + idKey);
                        }
                      }
                    }
                    const newRowNodeTrans = this.gridApi.applyTransaction({
                      add: data
                    });
                    this.updateCategoryFilterData();
                    console.log('New Row Node Trans: ', newRowNodeTrans);
                  },
                }
              });
            },
          },
        ]);


        return true;
      }
    });

    actionButtonList.unshift({
      type: 'button',
      name: 'settings',
      title: 'Cài chiết khấu cho các dòng đã lọc',
      status: 'primary',
      label: 'Cài chiết khấu cho các dòng đã lọc',
      iconPack: 'eva',
      icon: 'settings-2-outline',
      size: 'medium',
      click: (event) => {

        this.cms.openDialog(DialogFormComponent, {
          context: {
            width: '512px',
            controls: [
              {
                name: 'CommissionRatio',
                label: '% Chiết khấu',
                placeholder: 'Điền % chiết khấu cho các dong đang lọc',
                type: 'number',
              }
            ],
            actions: [
              {
                label: 'Trở về',
                status: 'basic',
                outline: true,
                action: async (form, dialog) => {
                  return true;
                }
              },
              {
                label: 'OK',
                status: 'success',
                action: async (form, dialog) => {
                  const commissionRatio = form.get('CommissionRatio').value;
                  this.gridApi.forEachNodeAfterFilterAndSort((rowNode, index) => {
                    rowNode.setDataValue('CommissionRatio', commissionRatio);
                  });
                  return true;
                }
              }
            ],
          }
        })

        return true;
      }
    });

    component.actionButtonList = actionButtonList;
  }
}
