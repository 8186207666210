import { ProcessMap } from "../../models/process-map.model";
import { stateMap } from "../../state.map";

export class B2bCenterStateMap {

    static SENT: ProcessMap = {
        state: 'SENT',
        label: 'Đã gửi',
        id: 'SENT',
        text: 'Đã gửi',
        confirmLabel: 'Gửi đơn hàng',
        status: 'info',
        color: 'ligthblue',
        outline: false,
        confirmTitle: 'Gửi đơn hàng',
        confirmText: 'Bạn có muốn gửi đơn hàng này tới bên bán ?',
        responseTitle: 'Đã gửi đơn hàng',
        responseText: 'Đã gửi đơn hàng thành công',
    };
    static RECEIVED: ProcessMap = {
        state: 'RECEIVED',
        label: 'Đã nhận',
        id: 'RECEIVED',
        text: 'Đã nhận',
        confirmLabel: 'Tải đơn hàng',
        status: 'primary',
        color: 'blue',
        outline: false,
        confirmTitle: 'Tải đơn hàng',
        confirmText: 'Bạn có muốn tải đơn hàng này tới bên mua ?',
        responseTitle: 'Đã tải đơn hàng',
        responseText: 'Đã tải đơn hàng thành công',
    };
    static PURCHASECOMPLETED: ProcessMap = {
        state: 'PURCHASECOMPLETED',
        label: 'Đã hoàn tất mua hàng',
        id: 'PURCHASECOMPLETED',
        text: 'Đã hoàn tất mua hàng',
        confirmLabel: 'Hoàn tất mua hàng',
        status: 'basic',
        color: 'gray',
        outline: true,
        confirmTitle: 'Chuyển trạng thái',
        confirmText: 'Bạn có muốn hoàn tất mua hàng',
        responseTitle: 'Chuyển trạng thái',
        responseText: 'Đã hoàn tất',
    };
    static SALESCOMPLETED: ProcessMap = {
        state: 'SALESCOMPLETED',
        label: 'Đã hoàn tất bán hàng',
        id: 'SALESCOMPLETED',
        text: 'Đã hoàn tất bán hàng',
        confirmLabel: 'Hoàn tất bán hàng',
        status: 'basic',
        color: 'gray',
        outline: true,
        confirmTitle: 'Chuyển trạng thái',
        confirmText: 'Bạn có muốn hoàn tất bán hàng',
        responseTitle: 'Chuyển trạng thái',
        responseText: 'Đã hoàn tất',
    };

    static pageStateMap: { [key: string]: ProcessMap } = {
        ACTIVE: {
            state: 'ACTIVE',
            label: 'Common.active',
            status: 'success',
            outline: false,
            nextState: 'INACTIVE',
            nextStateLabel: 'Common.changeToInactive',
            confirmText: 'Common.inactiveConfirm',
            responseTitle: 'Common.inactivated',
            responseText: 'Common.inactiveSuccess',
        },
        INACTIVE: {
            state: 'INACTIVE',
            label: 'Common.inactive',
            status: 'danger',
            outline: true,
            nextState: 'ACTIVE',
            nextStateLabel: 'Common.active',
            confirmText: 'Common.activeConfirm',
            responseTitle: 'Common.activated',
            responseText: 'Common.activeSuccess',
        },
        NOTJUSTACTIVE: {
            state: 'NOTJUSTACTIVE',
            label: 'Common.notjustactive',
            status: 'danger',
            outline: false,
            nextState: 'ACTIVE',
            nextStateLabel: 'Common.active',
            confirmText: 'Common.activeConfirm',
            responseTitle: 'Common.activated',
            responseText: 'Common.activeSuccess',
        },
        "": {
            state: 'NOTJUSTACTIVE',
            label: 'Common.notjustactive',
            status: 'danger',
            outline: false,
            nextState: 'ACTIVE',
            nextStateLabel: 'Common.active',
            confirmText: 'Common.activeConfirm',
            responseTitle: 'Common.activated',
            responseText: 'Common.activeSuccess',
        },
    };

    static b2bCenterPurchaseOrderStateMap: { [key: string]: ProcessMap } = {
        NEW: {
            ...stateMap.NEW,
            nextState: 'SENT',
            nextStates: [
                B2bCenterStateMap.SENT,
            ],
        },
        SENT: {
            ...B2bCenterStateMap.SENT,
            nextState: 'RECEIVED',
            nextStates: [
                B2bCenterStateMap.RECEIVED,
                stateMap.UNRECORDED,
            ],
        },
        RECEIVED: {
            ...B2bCenterStateMap.RECEIVED,
            nextState: 'PROCESSING',
            nextStates: [
                stateMap.PROCESSING,
                stateMap.UNRECORDED,
            ],
        },
        PROCESSING: {
            ...stateMap.PROCESSING,
            nextState: 'CLOSEORDER',
            nextStates: [
                stateMap.CLOSEORDER,
                stateMap.UNRECORDED,
            ],
        },
        CLOSEORDER: {
            ...stateMap.CLOSEORDER,
            label: 'Chốt đơn',
            nextState: 'DEPLOYED',
            nextStates: [
                stateMap.DEPLOYED,
                stateMap.UNRECORDED,
            ],
        },
        DEPLOYED: {
            ...stateMap.DEPLOYED,
            nextState: 'PURCHASECOMPLETED',
            nextStates: [
                B2bCenterStateMap.PURCHASECOMPLETED,
                stateMap.UNRECORDED,
            ],
        },
        PURCHASECOMPLETED: {
            ...B2bCenterStateMap.PURCHASECOMPLETED,
            nextState: 'UNRECORDED',
            nextStates: [
                stateMap.UNRECORDED,
                stateMap.DEPLOYED,
            ],
        },
        UNRECORDED: {
            ...stateMap.UNRECORDED,
            nextState: 'SENT',
            nextStates: [
                B2bCenterStateMap.SENT,
            ],
        },
    };

    static b2bCenterSalesOrderStateMap: { [key: string]: ProcessMap } = {
        NEW: {
            ...stateMap.NEW,
            nextState: 'SENT',
            nextStates: [
                B2bCenterStateMap.SENT,
            ],
        },
        SENT: {
            ...B2bCenterStateMap.SENT,
            nextState: 'RECEIVED',
            nextStates: [
                B2bCenterStateMap.RECEIVED,
                stateMap.UNRECORDED,
            ],
        },
        RECEIVED: {
            ...B2bCenterStateMap.RECEIVED,
            nextState: 'PROCESSING',
            nextStates: [
                stateMap.PROCESSING,
                stateMap.UNRECORDED,
            ],
        },
        PROCESSING: {
            ...stateMap.PROCESSING,
            nextState: 'CLOSEORDER',
            nextStates: [
                stateMap.CLOSEORDER,
                stateMap.UNRECORDED,
            ],
        },
        CLOSEORDER: {
            ...stateMap.CLOSEORDER,
            label: 'Chốt đơn',
            nextState: 'DEPLOYED',
            nextStates: [
                stateMap.DEPLOYED,
                stateMap.UNRECORDED,
            ],
        },
        DEPLOYED: {
            ...stateMap.DEPLOYED,
            nextState: 'SALESCOMPLETED',
            nextStates: [
                B2bCenterStateMap.SALESCOMPLETED,
                stateMap.UNRECORDED,
            ],
        },
        SALESCOMPLETED: {
            ...B2bCenterStateMap.SALESCOMPLETED,
            nextState: 'UNRECORDED',
            nextStates: [
                stateMap.UNRECORDED,
                stateMap.DEPLOYED,  
            ],
        },
        UNRECORDED: {
            ...stateMap.UNRECORDED,
            nextState: 'SENT',
            nextStates: [
                B2bCenterStateMap.SENT,
            ],
        },
    };

    static salesCommissionConfigStateMap = {
        APPROVED: {
            ...stateMap.APPROVED,
            nextState: 'RUNNING',
            nextStates: [
                stateMap.RUNNING,
                stateMap.UNRECORDED,
            ],
        } as ProcessMap,
        RUNNING: {
            ...stateMap.RUNNING,
            nextState: 'UNRECORDED',
            nextStates: [
                stateMap.COMPLETED,
                stateMap.UNRECORDED,
            ],
        } as ProcessMap,
        COMPLETED: {
            ...stateMap.COMPLETED,
            nextState: 'COMPLETED',
            nextStates: [
            ],
        } as ProcessMap,
        UNRECORDED: {
            ...stateMap.UNRECORDED,
            nextState: 'APPROVED',
            nextStates: [
                stateMap.APPROVED,
            ],
        } as ProcessMap,
        NOTJUSTAPPROVED: {
            ...stateMap.NOTJUSTAPPROVED,
            nextState: 'RUNNING',
            nextStates: [
                stateMap.APPROVED,
                stateMap.UNRECORDED,
            ],
        } as ProcessMap,
        '': {
            ...stateMap.NOTJUSTAPPROVED,
            nextState: 'RUNNING',
            nextStates: [
                stateMap.APPROVED,
                stateMap.UNRECORDED,
            ],
        } as ProcessMap,
    }

}
