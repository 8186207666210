import { filter } from 'rxjs/operators';
import { IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams } from "@ag-grid-community/core";

import { Component } from "@angular/core";
import { IFilterAngularComp, IFloatingFilterAngularComp } from "@ag-grid-community/angular";
import { Select2Option } from "../../select2/select2.component";
import { FormControl } from "@angular/forms";
import { CommonService } from "../../../../services/common.service";
import { IFloatingFilterComp } from "ag-grid-community";
import { Model } from "../../../../models/model";

// export class AgFilterValue {
//     obj?: any;
//     constructor(obj) {
//         this.obj = obj;
//     }

//     get id() {
//         return this.obj.id;
//     };

//     get text() {
//         return this.obj.text;
//     };

//     toString() {
//         return this.obj.id;
//     }
// }

@Component({
    template: `
        <div style="max-width: 500px">
        <ngx-select2 [formControl]="inputControl" [select2Option]="params['select2Option']" [data]="data" (selectChange)="onFilterChanged($event)"></ngx-select2>
        </div>
    `
})
export class AgSelect2Filter implements IFilterAngularComp {
    inputControl = new FormControl();
    select2Option: Select2Option & { data?: () => any[] };
    logic: 'AND' | 'OR' = 'AND';
    data: any[] = [];
    condition = 'filter';

    public params!: any;

    constructor(
        public cms: CommonService,
    ) {

    }

    agInit(params: any): void {
        // if (params.filterChangedCallback) {
        this.params = params;
        // } else {
        //     this.params = params['filterParams'];
        // }
    }

    onFilterChanged($event: any) {
        if (!this.settingModel) {
            this.params.filterChangedCallback();
        }
    }

    getModel() {
        // const filter = (this.inputControl.value || []).map(m => new Model(m));
        const filter = (this.inputControl.value && (!Array.isArray(this.inputControl.value) && [this.inputControl.value] || this.inputControl.value) || []).map(m => {

            if (m && m.id) {
                return new Model({ id: m.id, text: m.text, label: m.label });
            }
            return m;

        });
        return filter.length > 0 ? {
            filter: filter,
            filterType: 'text',
            type: 'equals',
        } : null;
    }

    settingModel = false;
    setModel(model: any) {
        this.settingModel = true;
        // this.inputControl.setValue(model?.filter, { emitEvent: false, emitModelToViewChange: false, emitViewToModelChange: false });
        this.inputControl.setValue(model?.filter);
        setTimeout(() => {
            this.settingModel = false;
        }, 300);
    }

    doesFilterPass(params: IDoesFilterPassParams) {
        // const rowSkills = params.data.skills;
        // let passed = true;

        // for (const skill of this.skills) {
        //     passed = passed && (skill.selected ? (skill.selected && rowSkills[skill.field]) : true);
        // }

        // return passed;
        // this.params['rowModel'];
        // return false;// dev for server data mode first





        const filterModel = this.getModel();
        const filterValue = Array.isArray(filterModel.filter) ? filterModel.filter : (filterModel.filter ? [filterModel.filter] : []);
        if (filterValue && filterValue.length > 0) {
            if (Array.isArray(params.data[this.params.colDef.field])) {
                return params.data[this.params.colDef.field].some(s => filterValue.some(f => this.cms.getObjectId(f) == this.cms.getObjectId(s)));
            } else {
                return filterValue.some(f => this.cms.getObjectId(f) == this.cms.getObjectId(params.data[this.params.colDef.field]));
            }
            return false;
        }




        return true;// dev for server data mode first
    };

    public isFilterActive() {
        const filter = (this.inputControl.value && (!Array.isArray(this.inputControl.value) && [this.inputControl.value] || this.inputControl.value) || [])
        return filter.length > 0;
    };

    helloFromSkillsFilter() {
        alert("Hello From The Skills Filter!");
    }

    getModelAsString() {
        const filter = (this.inputControl.value && (!Array.isArray(this.inputControl.value) && [this.inputControl.value] || this.inputControl.value) || []).map(m => m.label || this.cms.getObjectText(m));
        return filter.join(', ');
    }
}
