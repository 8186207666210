import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService, NbToastrService, NbDialogRef, NbThemeService } from '@nebular/theme';
import { AccountModel } from '../../../../models/accounting.model';
import { ApiService } from '../../../../services/api.service';
import { RootServices } from '../../../../services/root.services';
import { CommonService } from '../../../../services/common.service';
import { AccAccountFormComponent } from '../acc-account-form/acc-account-form.component';
import { AgGridDataManagerListComponent } from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { AgTextCellRenderer } from '../../../../lib/custom-element/ag-list/cell/text.component';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import { AccountingService } from '../../accounting.service';
import { agMakeTextColDef } from '../../../../lib/custom-element/ag-list/column-define/text.define';
import { agMakeCommandColDef } from '../../../../lib/custom-element/ag-list/column-define/command.define';

@Component({
  selector: 'ngx-acc-account-list',
  templateUrl: './acc-account-list.component.html',
  styleUrls: ['./acc-account-list.component.scss']
})
export class AccAccountListComponent extends AgGridDataManagerListComponent<AccountModel, AccAccountFormComponent> implements OnInit {

  componentName: string = 'AccAccountListComponent';
  formPath = '/accounting/account/form';
  apiPath = '/accounting/accounts';
  idKey = ['Code'];
  formDialog = AccAccountFormComponent;

  reuseDialog = true;

  // Smart table
  static filterConfig: any;
  static sortConf: any;
  static pagingConf = { page: 1, perPage: 40 };

  totalBalance: { Debit: number, Credit: number } = { Debit: 0, Credit: 0 };
  typeMap = {
    'DEBIT': { id: 'DEBIT', text: 'Nợ' },
    'CREDIT': { id: 'CREDIT', text: 'Có' },
  }

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public _http: HttpClient,
    public ref: NbDialogRef<AccAccountListComponent>,
    public themeService: NbThemeService,
    public accountingService: AccountingService,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);
  }

  async init() {
    // await this.loadCache();
    return super.init().then(async rs => {
      this.apiService.getPromise<any>(this.apiPath, { getTotalBalance: true }).then(balances => this.totalBalance = balances);

      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Code',
          // sortingOrder: ['desc', 'asc'],
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: this.cms.translateText('Common.code'),
          field: 'Code',
          width: 150,
          pinned: 'left',
          initialSort: 'asc',
          // cellRenderer: (params) => {
          //   if (params && params.data) {
          //     return params.data.Code;
          //   }
          // },
          // filter: AgSelect2Filter,
          // filterParams: {
          //   select2Option: {
          //     placeholder: 'Chọn...',
          //     allowClear: true,
          //     width: '100%',
          //     dropdownAutoWidth: true,
          //     minimumInputLength: 0,
          //     // withThumbnail: true,
          //     keyMap: {
          //       id: 'id',
          //       text: 'text',
          //     },
          //     ajax: {
          //       data: function (params) {
          //         return {
          //           ...params,
          //           offset: params['offset'] || 0,
          //           limit: params['limit'] || 10
          //         };
          //       },
          //       transport: (settings: JQueryAjaxSettings, success?: (data: any) => null, failure?: () => null) => {
          //         const params = settings.data;
          //         const offset = settings.data['offset'];
          //         const limit = settings.data['limit'];
          //         const results = !params['term'] ? this.accountingService.accountList$?.value : this.accountingService.accountList$?.value.filter(f => (new RegExp('^' + this.cms.convertUnicodeToNormal(params['term']), 'ig')).test(this.cms.convertUnicodeToNormal(f.Code)) || (new RegExp(this.cms.convertUnicodeToNormal(params['term']).toLowerCase().replace(/\s+/g, '.*'), 'ig')).test(this.cms.convertUnicodeToNormal(f.Name)));
          //         success({ data: results.slice(offset, offset + limit).map(m => { m.label = m.Code; return m; }), total: results.length });
          //         return null;
          //       },
          //       delay: 300,
          //       processResults: (rs: { data: any[], total: number }, params: any) => {
          //         const data = rs.data;
          //         const total = rs.total;
          //         params.limit = params.limit || 10;
          //         params.offset = params.offset || 0;
          //         params.offset = params.offset += params.limit;
          //         return {
          //           results: data.map(item => {
          //             item.id = item.Code;
          //             item.text = `${item.Code} - ${item.Name}`;
          //             // item.thumbnail = item?.FeaturePicture?.Thumbnail;
          //             return item;
          //           }),
          //           pagination: {
          //             more: params.offset < total
          //           }
          //         };
          //       },
          //     },
          //     multiple: true,
          //     logic: 'OR',
          //     // allowClear: true,
          //   }
          // },
        },
        {
          headerName: this.cms.translateText('Common.name'),
          field: 'Name',
          width: 450,
          // pinned: 'left',
          cellRenderer: AgTextCellRenderer
        },
        {
          headerName: this.cms.translateText('Common.description'),
          field: 'Description',
          width: 200,
          // pinned: 'left',
          cellRenderer: AgTextCellRenderer
        },
        {
          headerName: this.cms.translateText('Common.currency'),
          field: 'Currency',
          width: 100,
          pinned: 'right',
          cellRenderer: AgTextCellRenderer
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: this.cms.translateText('Common.property'),
          field: 'Property',
          width: 150,
          pinned: 'right',
          valueGetter: params => params && params.data && this.typeMap[params.data.Property],
        },
        {
          headerName: this.cms.translateText('Loại'),
          field: 'Type',
          width: 100,
          pinned: 'right',
          cellRenderer: AgTextCellRenderer
        },
        {
          headerName: this.cms.translateText('Level'),
          field: 'Level',
          width: 100,
          pinned: 'right',
          cellRenderer: AgTextCellRenderer
        },
        {
          headerName: this.cms.translateText('Nhóm'),
          field: 'Group',
          width: 150,
          pinned: 'right',
          cellRenderer: AgTextCellRenderer
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, true),
          headerName: 'Lệnh',
        },
        // {
        //   ...agMakeCommandColDef(this, this.cms, false, false, false, [
        //     {
        //       name: 'REPORT',
        //       label: 'Chi tiết',
        //       title: 'Báo cáo chi tiết theo tài khoản',
        //       status: 'primary',
        //       outline: true,
        //       action: async (params: any, buttonConfig?: any) => {
        //         this.openInstantDetailReport(params.data);
        //         return true;
        //       }
        //     }
        //   ]),
        //   headerName: 'Lệnh',
        //   maxWidth: 100,
        //   resizable: true,
        // },
      ] as ColDef[]);

      return rs;
    });
  }

  // loadListSetting(): any {
  //   return this.configSetting({
  //     columns: {
  //       Code: {
  //         title: this.cms.translateText('Common.code'),
  //         type: 'string',
  //         width: '5%',
  //       },
  //       Name: {
  //         title: this.cms.translateText('Common.name'),
  //         type: 'string',
  //         width: '20%',
  //         // filterFunction: (value: string, query: string) => this.cms.smartFilter(value, query),
  //       },
  //       Description: {
  //         title: this.cms.translateText('Common.description'),
  //         type: 'string',
  //         width: '20%',
  //       },
  //       Debit: {
  //         title: this.cms.translateText('Common.debit'),
  //         type: 'currency',
  //         width: '8%',
  //       },
  //       Credit: {
  //         title: this.cms.translateText('Common.credit'),
  //         type: 'currency',
  //         width: '8%',
  //       },
  //       Currency: {
  //         title: this.cms.translateText('Common.currency'),
  //         type: 'string',
  //         width: '8%',
  //       },
  //       Property: {
  //         title: this.cms.translateText('Common.property'),
  //         type: 'string',
  //         width: '8%',
  //       },
  //       Type: {
  //         title: this.cms.translateText('Common.type'),
  //         type: 'string',
  //         width: '5%',
  //         // filterFunction: (value: string, query: string) => this.cms.smartFilter(value, query),
  //       },
  //       Level: {
  //         title: this.cms.textTransform(this.cms.translate.instant('Common.level'), 'head-title'),
  //         type: 'string',
  //         width: '5%',
  //       },
  //       Group: {
  //         title: this.cms.translateText('Common.group'),
  //         type: 'string',
  //         width: '8%',
  //       },
  //     },
  //   });
  // }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  // initDataSource() {
  //   const source = super.initDataSource();

  //   // Set DataSource: prepareParams
  //   source.prepareParams = (params: any) => {
  //     params['includeParent'] = true;
  //     params['includeAmount'] = true;
  //     return params;
  //   };

  //   return source;
  // }

  prepareApiParams(params: any, getRowParams?: IGetRowsParams, component?: AgGridDataManagerListComponent<AccountModel, AccAccountFormComponent>) {
    // params['includeAmount'] = true;
    return params;
  }

  // /** Api get funciton */
  // executeGet(params: any, success: (resources: AccountModel[]) => void, error?: (e: HttpErrorResponse) => void, complete?: (resp: AccountModel[] | HttpErrorResponse) => void) {
  //   // params['includeParent'] = true;
  //   params['includeAmount'] = true;
  //   super.executeGet(params, success, error, complete);
  // }

  // getList(callback: (list: AccountModel[]) => void) {
  //   super.getList((rs) => {
  //     // rs.forEach(item => {
  //     //   item.Content = item.Content.substring(0, 256) + '...';
  //     // });
  //     if (callback) callback(rs);
  //   });
  // }

  // /** Config for paging */
  // protected configPaging() {
  //   return {
  //     display: true,
  //     perPage: 99999,
  //   };
  // }

  // async refresh() {
  //   super.refresh();
  //   this.apiService.getPromise<any>(this.apiPath, { getTotalBalance: true }).then(balances => this.totalBalance = balances);
  // }

}
