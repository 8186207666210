import { Component, OnInit } from '@angular/core';
import { MiniErpModel } from '../../../../models/minierp.model';
import { ApiService } from '../../../../services/api.service';
import { RootServices } from '../../../../services/root.services';
import { Router } from '@angular/router';
import { CommonService } from '../../../../services/common.service';
import { NbDialogRef, NbDialogService, NbThemeService, NbToastrService } from '@nebular/theme';
import { MinierpService } from '../../minierp-service.service';
import { SmartTableIconComponent } from '../../../../lib/custom-element/smart-table/smart-table.component';
import { ShowcaseDialogComponent } from '../../../dialog/showcase-dialog/showcase-dialog.component';
import { SmartTableSetting } from '../../../../lib/data-manager/data-manger-list.component';
import { SmartTableSelect2FilterComponent } from '../../../../lib/custom-element/smart-table/smart-table.filter.component';
import { DialogFormComponent } from '../../../dialog/dialog-form/dialog-form.component';
import { FormGroup } from '@angular/forms';
import { AgGridDataManagerListComponent } from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { MinierpFormComponent } from '../minierp-form/minierp-form.component';
import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { AgDateCellRenderer } from '../../../../lib/custom-element/ag-list/cell/date.component';
import { agMakeCommandColDef } from '../../../../lib/custom-element/ag-list/column-define/command.define';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import { agMakeCheckboxColDef } from '../../../../lib/custom-element/ag-list/column-define/checkbox.define';
import { AgIconCellRenderer } from '../../../../lib/custom-element/ag-list/cell/icon.component';

@Component({
  selector: 'ngx-minierp-list',
  templateUrl: './minierp-list.component.html',
  styleUrls: ['./minierp-list.component.scss'],
})
export class MinierpListComponent extends AgGridDataManagerListComponent<MiniErpModel, MinierpFormComponent> implements OnInit {

  componentName: string = 'MinierpListComponent';
  formPath = '/minierp/minierps/form';
  apiPath = '/mini-erp/minierps';
  idKey = ['Code'];
  reuseDialog = true;
  static _dialog: NbDialogRef<MinierpListComponent>;

  stateMap: { [key: string]: { state: string, status: string, icon: string, label: string } } = {
    'DOWNLOAD_UPDATE': {
      state: 'UPDATING',
      status: 'danger',
      icon: 'arrow-circle-up',
      label: 'Tải cập nhật',
    },
    'EXTRACT_UPDATE': {
      state: 'UPDATING',
      status: 'danger',
      icon: 'arrow-circle-up',
      label: 'Giải nén cập nhật',
    },
    'UPDATE_SOURCE_STRUCTURE': {
      state: 'UPDATING',
      status: 'danger',
      icon: 'arrow-circle-up',
      label: 'Cập nhật cấu trúc thư mục',
    },
    'UPDATE_FOLDER_WRITABLE': {
      state: 'UPDATING',
      status: 'danger',
      icon: 'arrow-circle-up',
      label: 'Cập nhật quyền ghi cho thư mục',
    },
    'UPDATE_DB': {
      state: 'UPDATING',
      status: 'danger',
      icon: 'arrow-circle-up',
      label: 'Cập nhật CSDL',
    },
    'UPDATE_PERMISSION': {
      state: 'UPDATING',
      status: 'danger',
      icon: 'arrow-circle-up',
      label: 'Cập nhật phân quyền',
    },
    'UPDATE_VERSION': {
      state: 'UPDATING',
      status: 'danger',
      icon: 'arrow-circle-up',
      label: 'Cập nhật phiên bản',
    },
    'UPDATE_RUNNING': {
      state: 'UPDATING',
      status: 'danger',
      icon: 'arrow-circle-up',
      label: 'Đang cập nhật',
    },
    'CHECK_UPDATE': {
      state: 'UPDATED',
      status: 'success',
      icon: 'checkmark-circle-2',
      label: 'Kiểm tra cập nhật',
    },
    'UPDATE_SUCCESS': {
      state: 'UPDATED',
      status: 'success',
      icon: 'checkmark-circle-2',
      label: 'Cập nhật thành công',
    },
    'UPDATE_ERROR': {
      state: 'UPDATEERROR',
      status: 'danger',
      icon: 'close-circle',
      label: 'Lỗi cập nhật',
    },
    'PREPARE_UPDATE': {
      state: 'PREPAREUPDATE',
      status: 'primary',
      icon: 'clock',
      label: 'Chuẩn bị cập nhật',
    },
    '': {
      state: '',
      status: 'success',
      icon: 'checkmark-circle-2',
      label: 'UNKNOWN'
    }
  };

  stateList: { id: string, text: string }[];
  autoUpdateList: { id: any, text: string }[];
  requireUpdateList: { id: any, text: string }[];
  moduleSettings: { [key: string]: any } = {};
  autoRefresh = false;

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public themeService: NbThemeService,
    public minierpService: MinierpService,
    public ref: NbDialogRef<MinierpListComponent>,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);
  }

  async init() {
    return super.init().then(async rs => {

      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Code',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
        },
        {
          headerName: 'Mã',
          field: 'Code',
          width: 140,
          filter: 'agTextColumnFilter',
          // pinned: 'left',
        },
        {
          headerName: 'Tên',
          field: 'Name',
          width: 300,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          headerName: 'Câp nhật lần cuối',
          field: 'LastUpdate',
          width: 180,
          filter: 'agDateColumnFilter',
          filterParams: {
            inRangeFloatingFilterDateFormat: 'DD/MM/YY',
          },
          cellRenderer: AgDateCellRenderer,
        },
        {
          headerName: 'Nhật ký',
          field: 'LastLog',
          width: 300,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          headerName: 'Phiên bản',
          field: 'Version',
          width: 120,
          filter: 'agTextColumnFilter',
          autoHeight: true,
          pinned: 'right',
        },
        {
          headerName: 'C.Nhật',
          field: 'RequireUpdate',
          width: 300,
          filter: 'agTextColumnFilter',
          autoHeight: true,
          cellRenderer: AgIconCellRenderer,
          cellRendererParams: {
            onInit: (params: any, comp: AgIconCellRenderer) => {
              const state = this.stateMap[params.data?.State];
              if (state) {
                comp.icon.next(state.icon);
                comp.status.next(state.status);
                comp.label.next(state.label);
              }
            }
          },
          pinned: 'right',
        },
        {
          ...agMakeCheckboxColDef(this, this.cms, (params, checked) => {
            this.apiService.putPromise<MiniErpModel[]>('/mini-erp/minierps', {}, [{ Code: params.data.Code, AutoUpdate: checked }]).then(rs => {
              console.info(rs);
            });
          }),
          headerName: 'C.Nhật',
          field: 'AutoUpdate',
          width: 130,
          filter: 'agTextColumnFilter',
          autoHeight: true,
          pinned: 'right',
        },
        {
          ...agMakeCommandColDef(this, this.cms, false, true, false),
          headerName: 'Lệnh',
        },
      ] as ColDef[]);

      this.stateList = [
        { id: 'UPDATE_FOLDER_WRITABLE', text: 'Cập nhật quyền ghi cho thư mục' },
        { id: 'CHECK_UPDATE', text: 'Kiểm tra cập nhật' },
        { id: 'DOWNLOAD_UPDATE', text: 'Tải cập nhật' },
        { id: 'EXTRACT_UPDATE', text: 'Giải nén cập nhật' },
        { id: 'UPDATE_SOURCE_STRUCTURE', text: 'Cập nhật cấu trúc thư mục' },
        { id: 'UPDATE_DB', text: 'Cập nhật CSDL' },
        { id: 'UPDATE_PERMISSION', text: 'Cập nhật phân quyền' },
        { id: 'UPDATE_VERSION', text: 'Cập nhật phiên bản' },
        { id: 'UPDATE_SUCCESS', text: 'Cập nhật thành công' },
        { id: 'UPDATE_RUNNING', text: 'Đang cập nhật' },
        { id: 'PREPARE_UPDATE', text: 'Chuẩn bị cập nhật' },
        { id: 'UPDATE_ERROR', text: 'Lỗi cập nhật' },
      ];
      this.autoUpdateList = [
        { id: 'true', text: 'Tự động' },
        { id: 'false', text: 'Thủ công' },
      ];
      this.requireUpdateList = [
        { id: 'true', text: 'Yêu cầu cập nhật' },
        { id: 'false', text: 'Đã cập nhật' },
      ];
      this.actionButtonList = this.actionButtonList.filter(f => f.name != 'preview' && f.name != 'edit' && f.name != 'add');
      this.actionButtonList.unshift({
        name: 'allowUpdateAll',
        icon: 'arrow-circle-up',
        label: '...',
        status: 'primary',
        title: '...',
        size: 'medium',
        outline: true,
        click: () => {
          this.apiService.putPromise(this.apiPath + '/settings', {}, [{
            Name: 'MINIERP_ALLOW_UPDATE',
            Value: (this.moduleSettings && parseInt(this.moduleSettings['MINIERP_ALLOW_UPDATE'])) ? 0 : 1,
          }]).then(rs => {
            this.refresh();
          });
        },
      });
      this.actionButtonList.unshift({
        name: 'setTargetVersion',
        icon: 'pricetags',
        label: 'Phiên bản',
        status: 'success',
        outline: true,
        title: 'Cập nhật phiên bản phát hành',
        size: 'medium',
        click: () => {
          this.cms.openDialog(DialogFormComponent, {
            context: {
              title: 'Cập nhật phiên bản phát hành',
              width: '300px',
              controls: [
                {
                  name: 'Version',
                  label: 'Phiên bản sẽ được cập nhật',
                  initValue: this.moduleSettings['MINIERP_RELEASE_VERSION'],
                  placeholder: 'Phiên bản sẽ được cập nhật tự động',
                  type: 'text',
                },
              ],
              actions: [
                {
                  label: 'Trở về',
                  icon: 'back',
                  status: 'info',
                  action: async () => { return true; },
                },
                {
                  label: 'Cập nhật',
                  icon: 'generate',
                  status: 'success',
                  action: async (form: FormGroup) => {
                    this.apiService.putPromise(this.apiPath + '/settings', {}, [{
                      Name: 'MINIERP_RELEASE_VERSION',
                      Value: form.value['Version'],
                    }]).then(rs => {
                      this.refresh();
                    });
                    return true;
                  },
                },
              ],
            },
          });
        },
      });

      this.actionButtonList.unshift({
        name: 'autoRefresh',
        icon: 'refresh',
        label: 'Không T.Động làm mới',
        status: 'danger',
        title: 'Không tự động làm mới',
        size: 'medium',
        outline: true,
        click: (event: any, option: any) => {
          this.autoRefresh = !this.autoRefresh;
          const autoRefreshBtn = this.actionButtonList.find(f => f.name === 'autoRefresh');
          if (autoRefreshBtn) {
            autoRefreshBtn.label = autoRefreshBtn.title = (this.autoRefresh ? 'T.Động làm mới' : 'Không T.Động làm mới');
            autoRefreshBtn.status = this.autoRefresh ? 'primary' : 'danger';
          }
          this.actionButtonList = [...this.actionButtonList];
        },
      });

      this.actionButtonList.unshift({
        name: 'autoUpdateAllToggle',
        icon: 'checkmark-square',
        label: 'T.Động cập nhật',
        status: 'danger',
        title: 'Tự động cập nhật',
        size: 'medium',
        outline: true,
        click: (event: any, option: any) => {
          this.setUpdateUpdate();
        },
      });

      this.updateSettingBehavios();

      setInterval(() => {
        if (this.autoRefresh) this.refresh();
      }, 30000);
      return rs;
    });
  }

  loadListSetting(): any {
    return this.configSetting({
      mode: 'external',
      selectMode: 'multi',
      actions: {
        position: 'right',
      },
      add: this.configAddButton(),
      edit: this.configEditButton(),
      delete: this.configDeleteButton(),
      pager: this.configPaging(),
      columns: {
        Code: {
          title: 'Mã',
          type: 'string',
          width: '10%',
        },
        Name: {
          title: 'Tên',
          type: 'string',
          width: '20%',
          valuePrepareFunction: (cell, row) => {
            return cell + ' (' + row['Domain'] + ')'
          },
        },
        LastUpdate: {
          title: 'Câp nhật lần cuối',
          type: 'string',
          width: '10%',
        },
        Version: {
          title: 'Phiên bản',
          type: 'string',
          width: '10%',
        },
        LastLog: {
          title: 'Nhật ký',
          type: 'string',
          width: '25%',
        },
        State: {
          title: 'Trạng thái',
          type: 'string',
          width: '10%',
          valuePrepareFunction: (value: string, miniErp: MiniErpModel) => {
            return this.stateList.find(f => f.id === value)?.text || 'UNKNOWN';
          },
          filter: {
            type: 'custom',
            component: SmartTableSelect2FilterComponent,
            config: {
              delay: 0,
              select2Option: {
                placeholder: 'Trạng thái...',
                allowClear: true,
                width: '100%',
                dropdownAutoWidth: true,
                minimumInputLength: 0,
                keyMap: {
                  id: 'id',
                  text: 'text',
                },
                // multiple: true,
                ajax: {
                  url: (params: any) => {
                    return 'data:text/plan,[]';
                  },
                  delay: 0,
                  processResults: (data: any, params: any) => {
                    return {
                      results: this.stateList.filter(cate => !params.term || this.cms.smartFilter(cate.text, params.term)),
                    };
                  },
                },
              },
            },
          },
        },
        RequireUpdate: {
          title: 'C.Nhật',
          type: 'custom',
          width: '5%',
          renderComponent: SmartTableIconComponent,
          onComponentInitFunction: (instance: SmartTableIconComponent) => {
            instance.iconPack = 'eva';
            instance.icon = 'arrow-circle-up';
            instance.label = '';
            // instance.display = true;
            instance.status = 'warning';
            instance.valueChange.subscribe((info: { value: any, row: any }) => {
              const state = info.row['State'] ? this.stateMap[info.row['State']] : '';

              // if (!info.row['AutoUpdate']) {
              //   instance.status = 'disabled';
              //   instance.icon = 'close-circle';
              // } else {

              // if (state.state === 'UPDATEERROR') {
              //   instance.status = 'danger';
              //   instance.icon = 'close-circle';
              // } else if (state.state === 'UPDATING') {
              //   instance.status = 'danger';
              //   instance.icon = 'arrow-circle-up';
              // } else if (state.state === 'PREPAREUPDATE') {
              //   instance.status = 'primary';
              //   instance.icon = 'clock';
              // } else {

              //   if (info.value) {
              //     instance.status = 'warning';
              //     instance.icon = 'arrow-circle-up';
              //   } else {
              //     instance.status = 'success';
              //     instance.icon = 'checkmark-circle-2';
              //   }

              // }
              // }
            });
            instance.click.subscribe(async (row: MiniErpModel) => {
            });
          },
          filter: {
            type: 'custom',
            component: SmartTableSelect2FilterComponent,
            config: {
              delay: 0,
              condition: 'eq',
              select2Option: {
                placeholder: 'Yêu cầu cập nhật...',
                allowClear: true,
                width: '100%',
                dropdownAutoWidth: true,
                minimumInputLength: 0,
                keyMap: {
                  id: 'id',
                  text: 'text',
                },
                // multiple: true,
                ajax: {
                  url: (params: any) => {
                    return 'data:text/plan,[]';
                  },
                  delay: 0,
                  processResults: (data: any, params: any) => {
                    return {
                      results: this.requireUpdateList.filter(cate => !params.term || this.cms.smartFilter(cate.text, params.term)),
                    };
                  },
                },
              },
            },
          },
        },
        AutoUpdate: {
          title: 'Tự động C.Nhật',
          type: 'boolean',
          editable: true,
          width: '10%',
          onChange: (value, rowData: MiniErpModel) => {
            // rowData.AutoUpdate = value;
            this.apiService.putPromise<MiniErpModel[]>('/mini-erp/minierps', {}, [{ Code: rowData.Code, AutoUpdate: value }]).then(rs => {
              console.info(rs);
            });
          },
          filter: {
            type: 'custom',
            component: SmartTableSelect2FilterComponent,
            config: {
              delay: 0,
              condition: 'eq',
              select2Option: {
                placeholder: 'Cập nhật tự động...',
                allowClear: true,
                width: '100%',
                dropdownAutoWidth: true,
                minimumInputLength: 0,
                keyMap: {
                  id: 'id',
                  text: 'text',
                },
                // multiple: true,
                ajax: {
                  url: (params: any) => {
                    return 'data:text/plan,[]';
                  },
                  delay: 0,
                  processResults: (data: any, params: any) => {
                    return {
                      results: this.autoUpdateList.filter(cate => !params.term || this.cms.smartFilter(cate.text, params.term)),
                    };
                  },
                },
              },
            },
          },
        },
      },
    });
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  setUpdateUpdate() {
    this.cms.openDialog(ShowcaseDialogComponent, {
      context: {
        title: 'Mini-ERP',
        content: 'Đặt chế độ tự động cập nhật cho các site đã chọn',
        actions: [
          {
            label: 'Trở về',
            status: 'primary',
            action: () => {

            },
          },
          {
            label: 'Tự động',
            status: 'success',
            action: () => {
              const data: MiniErpModel[] = this.selectedItems.map(item => {
                return { Code: this.makeId(item), AutoUpdate: true };
              });
              this.apiService.putPromise<MiniErpModel[]>('/mini-erp/minierps', {}, data).then(rs => this.refresh());
            },
          },
          {
            label: 'Thủ công',
            status: 'warning',
            action: () => {
              const data: MiniErpModel[] = this.selectedIds.map(i => {
                return { Code: i, AutoUpdate: false };
              });
              this.apiService.putPromise<MiniErpModel[]>('/mini-erp/minierps', {}, data).then(rs => this.refresh());
            },
          },
        ],
      },
    });
  }

  prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    return params;
  }

  async refresh() {
    this.updateSettingBehavios();
    return super.refresh();
  }

  loadList(callback: (list: MiniErpModel[]) => void) {
    return super.loadList(callback);
  }

  async updateSettingBehavios() {
    return this.apiService.getPromise<{ [key: string]: any }>(this.apiPath + '/settings', {}).then(rs => {
      this.moduleSettings = rs;
      const allowUpdateBtn = this.actionButtonList.find(f => f.name === 'allowUpdateAll');
      if (allowUpdateBtn) {
        allowUpdateBtn.label = allowUpdateBtn.title = (this.moduleSettings && parseInt(this.moduleSettings['MINIERP_ALLOW_UPDATE'])) ? 'Cho phép C.Nhật tất cả' : 'Không cho phép C.Nhật tất cả';
        allowUpdateBtn.status = (this.moduleSettings && parseInt(this.moduleSettings['MINIERP_ALLOW_UPDATE'])) ? 'primary' : 'danger';
      }
      return rs;
    });
  }

  protected configPaging() {
    return {
      display: true,
      perPage: 99999,
    };
  }

}
