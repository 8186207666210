import { Component, ElementRef, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../../services/api.service';
import { RootServices } from '../../../../services/root.services';
import { Router } from '@angular/router';
import { CommonService } from '../../../../services/common.service';
import { NbDialogService, NbToastrService, NbDialogRef, NbThemeService } from '@nebular/theme';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AdminProductService } from '../../../admin-product/admin-product.service';
import { DecimalPipe } from '@angular/common';
import { ProductListComponent } from '../../../admin-product/product/product-list/product-list.component';
import { AppModule } from '../../../../app.module';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import { agMakeImageColDef } from '../../../../lib/custom-element/ag-list/column-define/image.define';
import { AgTextCellRenderer } from '../../../../lib/custom-element/ag-list/cell/text.component';
import { AgSelect2Filter } from '../../../../lib/custom-element/ag-list/filter/select2.component.filter';
import { AgDateCellRenderer } from '../../../../lib/custom-element/ag-list/cell/date.component';
import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { ProductCategoryModel, ProductGroupModel, ProductModel, ProductUnitModel } from '../../../../models/product.model';
import { AgGridDataManagerListComponent } from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { FormGroup } from '@angular/forms';
import { filter, take, takeUntil } from 'rxjs/operators';
import { UploaderOptions, UploadFile, UploadInput, humanizeBytes, UploadOutput } from '../../../../../vendor/ngx-uploader/src/public_api';
import { IdTextModel } from '../../../../models/common.model';
import { FileModel } from '../../../../models/file.model';
import { WarehouseGoodsContainerModel } from '../../../../models/warehouse.model';
import { AssignCategoriesFormComponent } from '../../../admin-product/product/assign-categories-form/assign-categories-form.component';
import { ProductFormComponent } from '../../../admin-product/product/product-form/product-form.component';
import { agMakeNumberColDef } from '../../../../lib/custom-element/ag-list/column-define/number.define';
import { agMakeButtonsColDef } from '../../../../lib/custom-element/ag-list/column-define/buttons.define';
import { AssignNewContainerFormComponent } from '../assign-new-containers-form/assign-new-containers-form.component';
import { WarehouseGoodsFindOrderTempPrintComponent } from '../warehouse-goods-find-order-temp-print/warehouse-goods-find-order-temp-print.component';
import { DialogFormComponent } from '../../../dialog/dialog-form/dialog-form.component';
import { WarehouseGoodsReceiptNoteDetailAccessNumberPrintComponent } from '../../goods-receipt-note/warehouse-goods-access-number-print/warehouse-goods-access-number-print.component';
import { AgDynamicListComponent } from '../../../general/ag-dymanic-list/ag-dymanic-list.component';
import { agMakeTagsColDef } from '../../../../lib/custom-element/ag-list/column-define/tags.define';
import { ContactModel } from '../../../../models/contact.model';
import { PurchaseOrderVoucherModel } from '../../../../models/purchase.model';

@Component({
  selector: 'ngx-warehouse-goods-queue',
  templateUrl: './warehouse-goods-queue.component.html',
  styleUrls: ['./warehouse-goods-queue.component.scss'],
  providers: [DecimalPipe]
})
export class WarehouseGoodsQueueComponent extends AgGridDataManagerListComponent<ProductModel, ProductFormComponent> implements OnInit {

  componentName: string = 'WarehouseGoodsQueueComponent';
  // formPath = '/warehouse/goods/form';
  apiPath = '/warehouse/goods-queues';
  idKey: string[] = ['Id'];
  formDialog = ProductFormComponent;

  // Use for load settings menu for context
  feature = {
    Module: { id: 'Warehouse', text: 'Kho bãi' },
    Feature: { id: 'Goods', text: 'Hàng hóa' }
  };

  @Input() reuseDialog = true;
  static _dialog: NbDialogRef<ProductListComponent>;

  // Smart table
  static filterConfig: any;
  static sortConf: any;
  static pagingConf = { page: 1, perPage: 40 };

  // Category list for filter
  categoryList: ProductCategoryModel[] = [];
  groupList: ProductGroupModel[] = [];
  unitList: ProductUnitModel[] = [];
  containerList: WarehouseGoodsContainerModel[] = [];
  // @Input() rowMultiSelectWithClick = true;
  // @Input() suppressRowClickSelection = false;

  @Input() width = '100%';
  @Input() height = '100%';

  shelfList: IdTextModel[];
  // @ViewChild('smartTable', { static: false }) smartTable: Ng2SmartTableComponent;

  // private smartTable: Ng2SmartTableComponent;

  @Input() pagingConfig: { display: boolean, perPage: number }
  @Input() paginationPageSize = 20;
  @Input() cacheBlockSize = this.paginationPageSize;
  @Input() getRowNodeId = (item: any) => {
    return item.Code + '-' + this.cms.getObjectId(item.Unit) + this.cms.getObjectId(item.Container);
  }

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public _http: HttpClient,
    public ref: NbDialogRef<WarehouseGoodsQueueComponent>,
    public prds: AdminProductService,
    public themeService: NbThemeService,
    public adminProductServer: AdminProductService,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /** Config for paging */
  protected configPaging() {
    if (this.pagingConfig) {
      return {
        ...super.configPaging(),
        ...this.pagingConfig,
      };
    }
    return super.configPaging();
  }

  async loadCache() {
    // iniit category
    this.adminProductServer.categoryList$.pipe(takeUntil(this.destroy$), filter(f => !!f)).subscribe(categoryList => this.categoryList = categoryList);
    this.adminProductServer.groupList$.pipe(takeUntil(this.destroy$), filter(f => !!f)).subscribe(groupList => this.groupList = groupList);
    this.containerList = (await this.apiService.getPromise<WarehouseGoodsContainerModel[]>('/warehouse/goods-containers', { includePath: true, includeIdText: true, limit: 'nolimit' })).map(container => ({ ...container, text: `${container.FindOrder} - ${container.Path}` })) as any;
    this.shelfList = (await this.apiService.getPromise<WarehouseGoodsContainerModel[]>('/warehouse/goods-containers', { includePath: true, limit: 'nolimit', eq_Type: 'SHELF' })).map(container => ({ id: container.Code, text: `${container.Name}` })) as any;

    // this.categoryList = (await this.apiService.getPromise<ProductCategoryModel[]>('/admin-product/categories', { limit: 'nolimit' })).map(cate => ({ ...cate, id: cate.Code, text: cate.Name })) as any;
    // this.groupList = (await this.apiService.getPromise<ProductGroupModel[]>('/admin-product/groups', { limit: 'nolimit' })).map(cate => ({ ...cate, id: cate.Code, text: cate.Name })) as any;
    // this.unitList = (await this.apiService.getPromise<UnitModel[]>('/admin-product/units', { includeIdText: true, limit: 'nolimit' }));
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    console.log(this.smartTable);
  }

  prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    params['includeFeaturePicture'] = true;
    params['includeCategories'] = true;
    params['includeUnit'] = true;
    params['includeContainer'] = true;
    params['includeContainerShelf'] = true;
    params['sort_Id'] = 'asc';
    return params;
  }

  async init() {
    await this.prds.unitList$.pipe(filter(f => !!f), take(1)).toPromise();
    await this.loadCache();
    return super.init().then(async rs => {

      // Load unit list
      this.prds.unitList$.pipe(takeUntil(this.destroy$)).subscribe(unitList => {
        this.unitList = unitList;
      });



      const processingMap = AppModule.processMaps['commercePos'];
      await this.cms.waitForLanguageLoaded();

      this.actionButtonList = this.actionButtonList.filter(f => ['add', 'edit', 'delete', 'preview'].indexOf(f.name) < 0)

      this.actionButtonList.unshift({
        name: 'printFindOrderTem',
        status: 'primary',
        label: 'In tem nhận thức',
        title: 'In tem nhận thức',
        icon: 'grid-outline',
        size: 'medium',
        disabled: () => this.selectedIds.length == 0,
        click: () => {
          // const editedItems = this.selectedItems;
          this.cms.openDialog(WarehouseGoodsFindOrderTempPrintComponent, {
            context: {
              priceTable: 'default',
              id: this.selectedItems.map(item => this.makeId(item)),
            }
          });
        }
      });

      this.actionButtonList.unshift({
        name: 'reprintAccessNumbers',
        status: 'success',
        label: 'In lại số truy xuất',
        title: 'Thêm vào sanh sách in lại',
        icon: 'pricetags-outline',
        size: 'medium',
        click: () => {
          this.cms.openDialog(DialogFormComponent, {
            context: {
              width: '500px',
              title: 'Thêm số truy xuất vào danh sách in lại',
              controls: [
                {
                  name: 'AccessNumbers',
                  label: 'Số truy xuất',
                  initValue: '',
                  placeholder: 'Mỗi dòng 1 số truy xuất',
                  type: 'textarea',
                },
              ],
              actions: [
                {
                  label: 'Trở về',
                  icon: 'back',
                  status: 'info',
                  action: async () => true,
                },
                {
                  label: 'In',
                  icon: 'printer-outline',
                  status: 'success',
                  action: async (form: FormGroup) => {

                    let accessNumbersText: string = form.get('AccessNumbers').value;
                    accessNumbersText = accessNumbersText.trim();
                    let accessNumbers = accessNumbersText.split('\n');

                    accessNumbers = accessNumbers.filter(f => {
                      f = f.trim();
                      return f && !/[^0-9]/.test(f) && /^127/.test(f);
                    })

                    console.log(accessNumbers);

                    this.cms.openDialog(WarehouseGoodsReceiptNoteDetailAccessNumberPrintComponent, {
                      context: {
                        id: accessNumbers,
                      }
                    });
                    return false; // do not close dialog after action
                  },
                },
              ],
            },
          });
        }
      });

      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'STT',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
          headerCheckboxSelection: true,
        },
        {
          ...agMakeImageColDef(this.cms, null, (rowData) => {
            return rowData.Pictures?.map(m => m['LargeImage']);
          }),
          headerName: 'Hình',
          // pinned: 'left',
          field: 'Thumbnail',
          width: 100,
        },
        {
          headerName: 'ID',
          field: 'Goods',
          width: 140,
          filter: 'agTextColumnFilter',
          // pinned: 'left',
          // initialSort: 'desc',
        },
        {
          headerName: 'Sku',
          field: 'Sku',
          // pinned: 'left',
          width: 120,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Tên',
          field: 'Name',
          // pinned: 'left',
          width: 350,
          filter: 'agTextColumnFilter',
          cellRenderer: AgTextCellRenderer,
        },
        {
          headerName: 'Kho',
          field: 'Warehouse',
          // pinned: 'left',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/warehouse/warehouses', { onlyIdText: true }, {
                placeholder: 'Chọn kho...', limit: 10, prepareReaultItem: (item) => {
                  // item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Kệ',
          field: 'ContainerShelf',
          pinned: 'right',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/warehouse/goods-containers', { onlyIdText: true, eq_Type: 'SHELF' }, {
                placeholder: 'Chọn kệ...', limit: 10, prepareReaultItem: (item) => {
                  // item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Vị trí',
          field: 'Container',
          // pinned: 'left',
          width: 300,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/warehouse/goods-containers', { onlyIdText: true }, {
                placeholder: 'Chọn vị trí...', limit: 10, prepareReaultItem: (item) => {
                  item['text'] = '[' + item['FindOrder'] + ']' + item['text'] + ' (' + item['id'] + ')';
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Danh mục',
          field: 'Categories',
          // pinned: 'left',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/admin-product/categories', { includeIdText: true, includeGroups: true, sort_Name: 'asc' }, {
                placeholder: 'Chọn danh mục...', limit: 10, prepareReaultItem: (item) => {
                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Nhóm',
          field: 'Groups',
          // pinned: 'left',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/admin-product/groups', { includeIdText: true, includeGroups: true, sort_Name: 'asc' }, {
                placeholder: 'Chọn nhóm...', limit: 10, prepareReaultItem: (item) => {
                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Ngày tạo',
          field: 'Created',
          width: 180,
          filter: 'agDateColumnFilter',
          filterParams: {
            inRangeFloatingFilterDateFormat: 'DD/MM/YY',
          },
          cellRenderer: AgDateCellRenderer,
        },
        {
          ...agMakeNumberColDef(this.cms),
          headerName: 'Tồn kho',
          field: 'Inventory',
          pinned: 'right',
          width: 120,
        },
        {
          headerName: 'ĐVT',
          field: 'Unit',
          pinned: 'right',
          width: 100,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              placeholder: 'Chọn ĐVT...',
              allowClear: true,
              width: '100%',
              dropdownAutoWidth: true,
              minimumInputLength: 0,
              withThumbnail: false,
              keyMap: {
                id: 'id',
                text: 'text',
              },
              multiple: true,
              logic: 'OR',
              data: this.prds.unitList$.value,
            }
          },
        },
        // {
        //   ...agMakeNumberColDef(this.cms),
        //   headerName: 'Giá trị tồn',
        //   field: 'InventoryCost',
        //   pinned: 'right',
        //   width: 120,
        // },
        {
          ...agMakeButtonsColDef(this.cms, [
            {
              name: 'order',
              label: 'Đặt hàng',
              status: 'danger',
              outline: false,
              icon: 'archive-outline',
              action: async (params: any, brnConfig, btn) => {
                this.cms.openDialog(DialogFormComponent, {
                  context: {
                    width: '512px',
                    title: 'Chọn nhà cung cấp sẽ đặt hàng',
                    controls: [
                      {
                        name: 'Supplier',
                        label: 'Nhà cung cấp',
                        type: 'select2',
                        option: {
                          ...this.cms.select2OptionForTemplate,
                          data: params?.data?.Objects
                        },
                      }
                    ],
                    actions: [
                      {
                        label: 'Trở về',
                        status: 'basic',
                        outline: true,
                        action: async () => true,
                      },
                      {
                        label: 'Đặt hàng',
                        status: 'success',
                        action: async (form, dialog) => {
                          dialog.processing = true;
                          return this.pushGoodsToPurchaseOrderQueue(form.value.Supplier, params.data, params.data.Unit, 0, '').then(po => {
                            return this.apiService.deletePromise(this.apiPath + '/' + this.cms.getObjectId(params.data.Id), {}).then(rs => {
                              this.refresh();
                              dialog.processing = false;
                              return true;
                            }).catch(err => {
                              dialog.processing = false;
                              return false;
                            });
                          }).catch(err => {
                            dialog.processing = false;
                            return false;
                          });
                        },
                      },
                      {
                        label: 'Chọn nhà cung cấp khác',
                        status: 'info',
                        // outline: true,
                        action: async () => {
                          this.cms.openDialog(DialogFormComponent, {
                            context: {
                              width: '512px',
                              title: 'Chọn nhà cung cấp khác',
                              controls: [
                                {
                                  name: 'Supplier',
                                  label: 'Nhà cung cấp',
                                  type: 'select2',
                                  option: {
                                    ...this.cms.select2OptionForContact,
                                  },
                                }
                              ],
                              actions: [
                                {
                                  label: 'Trở về',
                                  status: 'basic',
                                  outline: true,
                                  action: async () => true,
                                },
                                {
                                  label: 'Đặt hàng',
                                  status: 'success',
                                  action: async (form, dialog) => {
                                    dialog.processing = true;
                                    return this.pushGoodsToPurchaseOrderQueue(form.value.Supplier, params.data, params.data.Unit, 0, '').then(po => {
                                      return this.apiService.deletePromise(this.apiPath + '/' + this.cms.getObjectId(params.data.Id), {}).then(rs => {
                                        this.refresh();
                                        dialog.processing = false;
                                        return true;
                                      }).catch(err => {
                                        dialog.processing = false;
                                        return false;
                                      });
                                    }).catch(err => {
                                      dialog.processing = false;
                                      return false;
                                    });
                                  },
                                },
                              ],
                            }
                          });
                          return true;
                        },
                      },
                    ],
                  }
                });
                return true;
              }
            },
          ]),
          headerName: 'Action',
          width: 200,
        },
      ] as ColDef[]);

      return rs;
    });
  }

  async pushGoodsToPurchaseOrderQueue(supplier: ContactModel, product: ProductModel, unit: ProductUnitModel, quantity: number, description?: string, price?: number) {
    let queuePo = await this.apiService.getPromise<PurchaseOrderVoucherModel[]>('/purchase/order-vouchers', { eq_State: 'INQUEUE', eq_Object: supplier.id, sort_Id: 'desc', includeDetails: true }).then(rs => rs[0]);
    if (!queuePo) {
      const contact = await this.apiService.getPromise<ContactModel[]>('/contact/contacts/' + supplier.id).then(rs => rs[0]);
      queuePo = await this.apiService.postPromise<PurchaseOrderVoucherModel[]>('/purchase/order-vouchers', { eq_State: 'INQUEUE', Object: supplier.id, sort_Id: 'desc' }, [{
        Object: this.cms.getObjectId(supplier),
        ObjectName: supplier.text,
        ObjectPhone: contact.Phone,
        ObjectEmail: contact.Email,
        ObjectAddress: contact.Address,
        ObjectIdentified: contact.IdNumber,
        State: 'INQUEUE',
        Title: `Đặt hàng ${supplier.text} ${new Date().toLocaleString()}`,
      }]).then(rs => rs[0]);

      queuePo = await this.apiService.putPromise<PurchaseOrderVoucherModel[]>('/purchase/order-vouchers/' + queuePo.Code, { changeState: 'INQUEUE' }, [{ Code: queuePo.Code }]).then(rs => rs[0]);

    }
    if (queuePo) {
      if (!queuePo.Details) {
        queuePo.Details = [];
      }

      if (queuePo.Details.findIndex(f => this.cms.getObjectId(f.Product) == this.cms.getObjectId(product.Goods) && this.cms.getObjectId(f.Unit) == this.cms.getObjectId(unit)) > -1) {
        this.cms.showError('Hàng hóa đã có trong phiếu đặt mua hàng ' + queuePo.Code);
        return Promise.reject({ errorCode: 'DUPPLICATE', logs: ['Hàng hóa đã có trong phiếu đặt mua hàng ' + queuePo.Code] });
      }

      const productInfo = await this.apiService.getPromise<ProductModel[]>('/admin-product/products/' + this.cms.getObjectId(product.Goods), { includeUnitConversions: true }).then(rs => rs[0]);
      const business = [];
      if (productInfo && productInfo.UnitConversions) {
        // this.cms.showError('Không tìm thấy thông tin sản phẩm ');
        // return Promise.reject({ errorCode: 'DUPPLICATE', logs: ['Hàng hóa đã có trong phiếu đặt mua hàng ' + queuePo.Code] });
        const unitConversion = productInfo.UnitConversions.find(f => this.cms.getObjectId(f.Unit) == this.cms.getObjectId(unit));
        if (unitConversion) {
          if (unitConversion.IsAutoAdjustInventory) {
            business.push({
              id: 'PURCHASEWAREHOUSE',
              text: 'Nhập kho & mua hàng (công nợ)',
            });
          } else {
            business.push({
              id: 'PURCHASESKIPWAREHOUSE',
              text: 'Mua hàng không qua kho (công nợ)',
            });
          }
        }
      }

      queuePo.Details.push({
        Type: 'PRODUCT',
        Business: business,
        Image: [
          ...(product.Thumbnail ? [product.Thumbnail] : []),
          ...(product.FeaturePicture ? [product.FeaturePicture] : []),
          ...(product.Pictures || []).filter(f => f.Id != product.FeaturePicture?.Id)
        ],
        Product: product.Goods,
        Unit: unit,
        Quantity: quantity || 0,
        Price: price,
        Description: product.Name + (description ? (' - ' + description) : ''),
      });
      queuePo.DateOfOrder = new Date().toISOString();
      queuePo = await this.apiService.putPromise<PurchaseOrderVoucherModel[]>('/purchase/order-vouchers/' + queuePo.Code, {}, [queuePo]).then(rs => rs[0]);

      this.cms.showToast('Đã thêm hàng hóa vào phiếu đặt mua hàng ' + queuePo.Code, 'Thành công');

    } else {
      this.cms.showError('Không thể đặt hàng cho ' + product.Name + ' (' + product.UnitLabel + ')');
    }
    return queuePo;
  }

  editing = {};
  rows = [];

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  // initDataSource() {
  //   const source = super.initDataSource();

  //   // Set DataSource: prepareData
  //   source.prepareData = (data: ProductModel[]) => {
  //     data.map((product: ProductModel) => {
  //       if (product.WarehouseUnit && product.WarehouseUnit.Name) {
  //         product.WarehouseUnit.text = product.WarehouseUnit.Name;
  //       }

  //       if (product.Units && product.Units.length > 0) {
  //         product.Containers = product.Units.filter(f => !!f['Container']).map(m => m['Container']);
  //         for (const unitConversion of product.Units) {
  //           if (unitConversion.IsManageByAccessNumber) {
  //             unitConversion['status'] = 'danger';
  //             unitConversion['tip'] = unitConversion['text'] + ' (QL theo số truy xuất)';
  //           }
  //         }
  //       }

  //       // if (product.Container || product.Container.length > 0) {
  //       //   // product.Container = [product.Container];
  //       // } else {
  //       //   product.Container = { type: 'NEWCONTAINER', id: 'Gán vị trí', text: 'Gán vị trí' };
  //       // }

  //       return product;
  //     });
  //     return data;
  //   };

  //   // Set DataSource: prepareParams
  //   source.prepareParams = (params: any) => {
  //     params['includeCategories'] = true;
  //     params['includeGroups'] = true;
  //     params['includeWarehouseUnit'] = true;
  //     params['includeUnits'] = true;
  //     params['includeCreator'] = true;
  //     params['includeLastUpdateBy'] = true;

  //     params['sort_Id'] = 'desc';
  //     return params;
  //   };

  //   return source;
  // }

  /** Api get funciton */
  executeGet(params: any, success: (resources: ProductModel[]) => void, error?: (e: HttpErrorResponse) => void, complete?: (resp: ProductModel[] | HttpErrorResponse) => void) {
    params['includeCategories'] = true;
    super.executeGet(params, success, error, complete);
  }

  getList(callback: (list: ProductModel[]) => void) {
    super.getList((rs) => {
      // rs.map((product: any) => {
      //   product['Unit'] = product['Unit']['Name'];
      //   if (product['Categories']) {
      //     product['CategoriesRendered'] = product['Categories'].map(cate => cate['text']).join(', ');
      //   }
      //   return product;
      // });
      if (callback) callback(rs);
    });
  }

  /** Implement required */
  async openAssignCategoiesDialplog() {
    if (this.selectedIds.length > 0) {
      const editedItems = await this.convertIdsToItems(this.selectedIds);
      this.cms.openDialog(AssignCategoriesFormComponent, {
        context: {
          inputMode: 'dialog',
          inputProducts: this.selectedItems,
          onDialogSave: (newData: ProductModel[]) => {
            // this.refresh();
            this.updateGridItems(editedItems, newData);
          },
          onDialogClose: () => {
          },
        },
        closeOnEsc: false,
        closeOnBackdropClick: false,
      });
    }
  }

  /** ngx-uploader */
  options: UploaderOptions = { concurrency: 1, maxUploads: 0, maxFileSize: 1024 * 1024 * 1024 };
  formData: FormData;
  files: UploadFile[] = [];
  uploadInput: EventEmitter<UploadInput> = new EventEmitter<UploadInput>();
  humanizeBytes: Function = humanizeBytes;
  dragOver: { [key: string]: boolean } = {};
  filesIndex: { [key: string]: UploadFile } = {};
  pictureFormIndex: { [key: string]: FormGroup } = {};
  uploadForProduct: ProductModel;
  @ViewChild('uploadBtn') uploadBtn: ElementRef;

  async onUploadOutput(output: UploadOutput): Promise<void> {
    // console.log(output);
    // console.log(this.files);
    switch (output.type) {
      case 'allAddedToQueue':
        // uncomment this if you want to auto upload files when added
        this.cms.getAvailableFileStores().then(fileStores => {
          if (fileStores && fileStores.length > 0) {
            const event: UploadInput = {
              type: 'uploadAll',
              url: this.apiService.buildApiUrl(fileStores[0].Path + '/v1/file/files', { token: fileStores[0]['UploadToken'] }),
              method: 'POST',
              data: { foo: 'bar' },
            };
            this.uploadInput.emit(event);
          } else {
            this.cms.toastService.show('Không tìm thấy file store nào !', 'File Store', { status: 'warning' });
          }
        });
        break;
      case 'addedToQueue':
        if (typeof output.file !== 'undefined') {
          this.files.push(output.file);
          this.filesIndex[output.file.id] = output.file;
        }
        break;
      case 'uploading':
        if (typeof output.file !== 'undefined') {
          // update current data in files array for uploading file
          const index = this.files.findIndex((file) => typeof output.file !== 'undefined' && file.id === output.file.id);
          this.files[index] = output.file;
          console.log(`[${output.file.progress.data.percentage}%] Upload file ${output.file.name}`);
        }
        break;
      case 'removed':
        // remove file from array when removed
        this.files = this.files.filter((file: UploadFile) => file !== output.file);
        break;
      case 'dragOver':
        // this.dragOver[formItemIndex] = true;
        break;
      case 'dragOut':
      case 'drop':
        // this.dragOver[formItemIndex] = false;
        break;
      case 'done':
        // The file is downloaded
        console.log('Upload complete');
        const fileResponse: FileModel = output.file.response[0];

        try {

          if (fileResponse) {

            // get product
            const product = (await this.apiService.getPromise<ProductModel[]>('/admin-product/products', { id: [this.uploadForProduct.Code], includePictures: true }))[0];
            if (product) {
              if (!Array.isArray(product.Pictures)) product.Pictures = [];
              product.Pictures.push(fileResponse);
              await this.apiService.putPromise<ProductModel[]>('/admin-product/products', {}, [{
                Code: this.uploadForProduct.Code,
                FeaturePicture: fileResponse,
                Pictures: product.Pictures,
              }]);

              this.source['isLocalUpdate'] = true; // local reload
              await this.source.update(this.uploadForProduct, { ...this.uploadForProduct, FeaturePicture: fileResponse });
              this.source['isLocalUpdate'] = true;

              this.files = [];
              this.uploadBtn.nativeElement.value = '';

            } else {
              throw Error('Get product failed');
            }

          } else {
            throw Error('upload failed');
          }

          console.log(output);
        } catch (e) {
          this.files = [];
          this.uploadBtn.nativeElement.value = '';
        }

        break;
    }
  }

  startUpload(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      url: this.apiService.buildApiUrl('/file/files'),
      method: 'POST',
      data: { foo: 'bar' },
    };

    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }
  /** End ngx-uploader */

  openFormDialplog(ids?: string[], onDialogSave?: (newData: ProductModel[]) => void, onDialogClose?: () => void): void {
    throw new Error('Method not implemented.');
  }
}
