import { ProductObjectReferenceModel } from './../../../../models/product.model';
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../services/api.service';
import { RootServices } from '../../../../services/root.services';
import { Router } from '@angular/router';
import { CommonService } from '../../../../services/common.service';
import { NbDialogRef, NbDialogService, NbThemeService, NbToastrService } from '@nebular/theme';
import { HttpClient } from '@angular/common/http';
import { ProductObjectReferenceFormComponent } from '../product-object-reference-form/product-object-reference-form.component';
import { AgGridDataManagerListComponent } from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { ColDef, GridOptions, IGetRowsParams } from '@ag-grid-community/core';
import { AgTextCellRenderer } from '../../../../lib/custom-element/ag-list/cell/text.component';
import { agMakeCommandColDef } from '../../../../lib/custom-element/ag-list/column-define/command.define';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import { agMakeTextColDef } from '../../../../lib/custom-element/ag-list/column-define/text.define';
import { AgSelect2Filter } from '../../../../lib/custom-element/ag-list/filter/select2.component.filter';
import { agMakeImageColDef } from '../../../../lib/custom-element/ag-list/column-define/image.define';
import { agMakeDatetimeColDef } from '../../../../lib/custom-element/ag-list/column-define/datetime.define';

@Component({
  selector: 'ngx-product-object-reference-list',
  templateUrl: './product-object-reference-list.component.html',
  styleUrls: ['./product-object-reference-list.component.scss'],
})
export class ProductObjectReferenceListComponent extends AgGridDataManagerListComponent<ProductObjectReferenceModel, ProductObjectReferenceFormComponent> implements OnInit {

  componentName: string = 'ProductObjectReferenceListComponent';
  formPath = '/admin-product/product-object-reference/form';
  apiPath = '/admin-product/product-object-references';
  idKey = ['Id'];
  formDialog = ProductObjectReferenceFormComponent;

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public themeService: NbThemeService,
    public toastService: NbToastrService,
    public _http: HttpClient,
    public ref: NbDialogRef<ProductObjectReferenceListComponent>,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);
  }

  editing = {};
  rows = [];

  typeList = [
    { id: 'SUPPLIERPRODUCT', text: 'Tên sản phẩm theo NCC' },
    { id: 'SUPPLIERPRODUCTSKU', text: 'Sku sản phẩm theo NCC' },
    { id: 'SUPPLIERPRODUCTTAX', text: 'Tên thuế sản phẩm theo NCC' },
    { id: 'SUPPLIERPRODUCTAXVALUE', text: 'Thuế theo NCC' },
  ];
  typeMap = {
    SUPPLIERPRODUCT: { id: 'SUPPLIERPRODUCT', text: 'Tên sản phẩm theo NCC' },
    SUPPLIERPRODUCTSKU: { id: 'SUPPLIERPRODUCTSKU', text: 'Sku sản phẩm theo NCC' },
    SUPPLIERPRODUCTTAX: { id: 'SUPPLIERPRODUCTTAX', text: 'Tên thuế sản phẩm theo NCC' },
    SUPPLIERPRODUCTAXVALUE: { id: 'SUPPLIERPRODUCTAXVALUE', text: 'Thuế theo NCC' },
  };

  async init(): Promise<boolean> {
    return super.init().then(async status => {

      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
          headerCheckboxSelection: true,
        },
        {
          ...agMakeImageColDef(this.cms, null, (rowData) => {
            return rowData.Pictures?.map(m => m['LargeImage']);
          }),
          headerName: 'Hình',
          pinned: 'left',
          field: 'FeaturePicture',
          width: 100,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Sản phẩm',
          field: 'Product',
          width: 150,
          valueGetter: params => params?.data ? this.cms.getObjectId(params?.data['Product']) : null,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/admin-product/products', { includeIdText: true, sort_Name: 'asc' }, {
                placeholder: 'Chọn sản phẩm...', limit: 10, prepareReaultItem: (item) => {
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tên sản phẩm',
          field: 'ProductOriginName',
          width: 350,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Đối tượng',
          field: 'Object',
          width: 300,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/contact/contacts', { includeIdText: true, sort_Name: 'asc' }, {
                placeholder: 'Chọn đối tượng...', limit: 10, prepareReaultItem: (item) => {
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Loại',
          field: 'Type',
          width: 200,
          valueGetter: params => {
            if (params && params.data) {
              return this.typeMap[params.data.Type] && this.cms.getObjectText(this.typeMap[params.data.Type]) || params.data.Type;
            }
          },
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.select2OptionForTemplate,
              data: Object.keys(this.typeMap).map(m => this.typeMap[m]),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Giá trị theo đối tượng',
          field: 'ReferenceValue',
          width: 200,
        },
        {
          ...agMakeDatetimeColDef(this.cms),
          headerName: 'Cập nhật cuối',
          field: 'LastUpdate',
          width: 200,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Chứng từ liên quan',
          field: 'ReferenceCode',
          width: 200,
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, false),
          headerName: 'Sửa/Xóa',
        },
      ] as ColDef[]);

      return status;
    });
  }

  // loadListSetting(): any {
  //   return this.configSetting({
  //     mode: 'external',
  //     selectMode: 'multi',
  //     actions: {
  //       position: 'right',
  //     },
  //     // add: this.configAddButton(),
  //     // edit: this.configEditButton(),
  //     // delete: this.configDeleteButton(),
  //     // pager: this.configPaging(),
  //     columns: {
  //       FeaturePicture: {
  //         title: 'Hình',
  //         type: 'custom',
  //         width: '5%',
  //         // valuePrepareFunction: (value: any, product: ProductModel) => {
  //         //   return value['Thumbnail'];
  //         // },
  //         renderComponent: SmartTableThumbnailComponent,
  //         onComponentInitFunction: (instance: SmartTableThumbnailComponent) => {
  //           instance.valueChange.subscribe(value => {
  //           });
  //           instance.click.subscribe((row: ProductObjectReferenceModel) => {
  //             const pictureList = row?.Pictures || [];
  //             if ((pictureList.length == 0 && row.FeaturePicture?.OriginImage)) {
  //               pictureList.push(row.FeaturePicture);
  //             }
  //             if (pictureList.length > 0) {
  //               const currentIndex = pictureList.findIndex(f => f.Id == row.FeaturePicture.Id) || 0;
  //               if (pictureList.length > 1) {
  //                 const currentItems = pictureList.splice(currentIndex, 1);
  //                 pictureList.unshift(currentItems[0]);
  //               }
  //               this.cms.openDialog(ImagesViewerComponent, {
  //                 context: {
  //                   images: pictureList.map(m => m['OriginImage']),
  //                   imageIndex: 0,
  //                 }
  //               });
  //             }
  //           });
  //           instance.title = this.cms.translateText('click to change main product picture');
  //         },
  //       },
  //       Product: {
  //         title: 'Sản phẩm',
  //         type: 'string',
  //         width: '20%',
  //         valuePrepareFunction: (cell, row: ProductObjectReferenceModel) => {
  //           return `${row.Product} - ${row.ProductOriginName}`;
  //         },
  //         filter: {
  //           type: 'custom',
  //           component: SmartTableSelect2FilterComponent,
  //           config: {
  //             delay: 0,
  //             condition: 'eq',
  //             select2Option: {
  //               ...this.cms.makeSelect2AjaxOption('/admin-product/products', { includeIdText: true }, {
  //                 placeholder: 'Chọn sản phẩm...', limit: 10, prepareReaultItem: (item) => {
  //                   // item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
  //                   return item;
  //                 }
  //               }),
  //               multiple: true,
  //               logic: 'OR',
  //               allowClear: true,
  //             },
  //           },
  //         },
  //       },
  //       Object: {
  //         title: 'Đối tượng',
  //         type: 'string',
  //         width: '20%',
  //         valuePrepareFunction: (cell, row: ProductObjectReferenceModel) => {
  //           return row.ObjectName;
  //         },
  //         filter: {
  //           type: 'custom',
  //           component: SmartTableSelect2FilterComponent,
  //           config: {
  //             delay: 0,
  //             condition: 'eq',
  //             select2Option: {
  //               ...this.cms.makeSelect2AjaxOption('/contact/contacts', { includeIdText: true, includeGroups: true }, {
  //                 placeholder: 'Chọn liên hệ...', limit: 10, prepareReaultItem: (item) => {
  //                   item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
  //                   return item;
  //                 }
  //               }),
  //               multiple: true,
  //               logic: 'OR',
  //               allowClear: true,
  //             },
  //           },
  //         },
  //       },
  //       Type: {
  //         title: 'Loại',
  //         type: 'string',
  //         width: '10%',
  //         valuePrepareFunction: (cell, row: ProductObjectReferenceModel) => {
  //           return this.typeMap[cell] && this.cms.getObjectText(this.typeMap[cell]) || cell;
  //         },
  //         filter: {
  //           type: 'custom',
  //           component: SmartTableSelect2FilterComponent,
  //           config: {
  //             delay: 0,
  //             condition: 'eq',
  //             select2Option: {
  //               placeholder: 'Chọn...',
  //               allowClear: true,
  //               width: '100%',
  //               dropdownAutoWidth: true,
  //               minimumInputLength: 0,
  //               withThumbnail: false,
  //               multiple: true,
  //               keyMap: {
  //                 id: 'id',
  //                 text: 'text',
  //               },
  //               logic: 'OR',
  //               data: this.typeList
  //             },
  //           },
  //         },
  //       },
  //       ReferenceValue: {
  //         title: 'Giá trị theo đối tượng',
  //         type: 'string',
  //         width: '20%',
  //       },
  //       LastUpdate: {
  //         title: 'Cập nhật cuối',
  //         type: 'custom',
  //         width: '15%',
  //         filter: {
  //           type: 'custom',
  //           component: SmartTableDateRangeFilterComponent,
  //         },
  //         renderComponent: SmartTableDateTimeComponent,
  //         onComponentInitFunction: (instance: SmartTableDateTimeComponent) => {
  //           // instance.format$.next('medium');
  //         },
  //       },
  //       ReferenceCode: {
  //         title: 'Chứng từ liên quan',
  //         type: 'custom',
  //         renderComponent: SmartTableTagsComponent,
  //         valuePrepareFunction: (cell: string, row: ProductObjectReferenceModel) => {
  //           return [{ id: cell, text: cell, type: row.ReferenceCode.replace(/^(\d{3})(.*)/, '$1') }] as any;
  //         },
  //         onComponentInitFunction: (instance: SmartTableTagsComponent) => {
  //           instance.click.subscribe((tag: { id: string, text: string, type: string }) => {
  //             tag.type && this.cms.previewVoucher(tag.type, tag.id, null, (data, printComponent) => {
  //               // this.refresh();
  //             });
  //           });
  //         },
  //         width: '10%',
  //       },
  //     },
  //   });
  // }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  // initDataSource() {
  //   const source = super.initDataSource();

  //   // Set DataSource: prepareData
  //   source.prepareData = (data: ProductObjectReferenceModel[]) => {
  //     data.map((product: ProductObjectReferenceModel) => {
  //       // if (product.WarehouseUnit && product.WarehouseUnit.Name) {
  //       //   product.WarehouseUnit.text = product.WarehouseUnit.Name;
  //       // }

  //       // if (product.Units && product.Units.length > 0) {
  //       //   product.Containers = product.Units.filter(f => !!f['Container']).map(m => m['Container']);
  //       //   for (const unitConversion of product.Units) {
  //       //     if (unitConversion.IsManageByAccessNumber) {
  //       //       unitConversion['status'] = 'danger';
  //       //       unitConversion['tip'] = unitConversion['text'] + ' (QL theo số truy xuất)';
  //       //     }
  //       //   }
  //       // }

  //       // if (product.Container || product.Container.length > 0) {
  //       //   // product.Container = [product.Container];
  //       // } else {
  //       //   product.Container = { type: 'NEWCONTAINER', id: 'Gán vị trí', text: 'Gán vị trí' };
  //       // }

  //       return product;
  //     });
  //     return data;
  //   };

  //   // Set DataSource: prepareParams
  //   source.prepareParams = (params: any) => {
  //     // params['includeCategories'] = true;
  //     // params['includeGroups'] = true;
  //     // params['includeWarehouseUnit'] = true;
  //     // params['includeUnits'] = true;
  //     // params['includeCreator'] = true;
  //     // params['includeLastUpdateBy'] = true;

  //     params['sort_Id'] = 'desc';
  //     return params;
  //   };

  //   return source;
  // }

  prepareApiParams(params: any, getRowParams?: IGetRowsParams, component?: AgGridDataManagerListComponent<ProductObjectReferenceModel, ProductObjectReferenceFormComponent>) {
    return params;
  }

  /** Api get funciton */
  // executeGet(params: any, success: (resources: ProductObjectReferenceModel[]) => void, error?: (e: HttpErrorResponse) => void, complete?: (resp: ProductObjectReferenceModel[] | HttpErrorResponse) => void) {
  //   params['includeCategories'] = true;
  //   super.executeGet(params, success, error, complete);
  // }

  // getList(callback: (list: ProductObjectReferenceModel[]) => void) {
  //   super.getList((rs) => {
  //     // rs.map((product: any) => {
  //     //   product['Unit'] = product['Unit']['Name'];
  //     //   if (product['Categories']) {
  //     //     product['CategoriesRendered'] = product['Categories'].map(cate => cate['text']).join(', ');
  //     //   }
  //     //   return product;
  //     // });
  //     if (callback) callback(rs);
  //   });
  // }
}

