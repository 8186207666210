import { stateMap } from './../../state.map';
import { ProcessMap } from "../../models/process-map.model";

export class DeploymentStateMap {

    static deploymentStateMap = {
        '': {
            ...stateMap.NOTJUSTAPPROVED,
            nextState: 'APPROVED',
            nextStates: [
                stateMap.APPROVED,
                stateMap.UNRECORDED,
            ],
        } as ProcessMap,
        NOTJUSTAPPROVED: {
            ...stateMap.NOTJUSTAPPROVED,
            nextState: 'APPROVED',
            nextStates: [
                stateMap.APPROVED,
                stateMap.UNRECORDED,
            ],
        } as ProcessMap,
        APPROVED: {
            ...stateMap.APPROVED,
            outline: true,
            nextState: 'COMPLETED',
            nextStates: [
                stateMap.COMPLETED,
                stateMap.UNRECORDED,
            ],
        } as ProcessMap,
        QUEUE: {
            ...stateMap.QUEUE,
            nextState: 'APPROVED',
            nextStates: [
                stateMap.APPROVED,
                stateMap.UNRECORDED,
            ],
        } as ProcessMap,
        COMPLETED: {
            ...stateMap.COMPLETED,
            nextState: 'UNRECORDED',
            nextStates: [
                stateMap.UNRECORDED,
            ],
        } as ProcessMap,
        UNRECORDED: {
            ...stateMap.UNRECORDED,
            nextState: 'APPROVED',
            nextStates: [
                stateMap.APPROVED,
                stateMap.UNRECORDED,
            ],
        } as ProcessMap,
    };
}
