<div class="action-list" #actionList [ngClass]="{'action-list-nowrap': nowrap}">
  <div class="buttons-row">
    <ng-content select="#start"></ng-content>
    <ng-template ngFor let-control [ngForOf]="list" let-i="index">
      <button #controlEle type="button" class="action-list-item"
        *ngIf="(!control.type || control.type == 'button') && (!control.hidden || !control.hidden(option))" nbButton
        [status]="control.status" hero [size]="control.size" [outline]="control?.outline === true && true || false" [disabled]="control.disabled && control.disabled(option)"
        [nbTooltip]="control.title" nbTooltipPlacement="bottom" (click)="control.click($event, option, null, controlEle)">
        <nb-icon *ngIf="!hideIcon" [pack]="control.iconPack || 'eva'" [icon]="control.icon"></nb-icon>
        <span *ngIf="!!control.label && !hideLabel">{{control.label}}</span>
      </button>
      <input class="action-list-item" *ngIf="control.type == 'text' && (!control.hidden || !control.hidden(option))"
        nbInput type="text" [fieldSize]="control.size" [status]="control?.status"
        [nbTooltip]="control.title" nbTooltipPlacement="bottom"
        [disabled]="control.disabled && control.disabled()" [placeholder]="control.title"
        (change)="control.change($event, option)" (keyup)="control.typing($event, option)"
        (key.enter)="control.change($event, option)" [(ngModel)]="control.value">
      <ngx-select2 class="action-list-item select2" *ngIf="control.type === 'select2'" [status]="control?.status"
        [nbTooltip]="control.title" nbTooltipPlacement="bottom"
        [data]="control.select2.data" [value]="control.asyncValue && (control.asyncValue | async) || control.value" [select2Option]="control.select2.option"
        (selectChange)="control?.change($event, option)" [disabled]="control.disabled && control.disabled()">
      </ngx-select2>
    </ng-template>
    <ng-content select="#end"></ng-content>
  </div>
</div>
