<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="onControlEnter($event)" (keydown.enter)="onControlEnter($event)" class="dialog-wrap popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup" [nbSpinner]="loading">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="'Import thông tin sản phẩm từ sàn B2B Center'" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">

            <div class="row">
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Page" label="Đối tác B2B" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [touchedValidate]="false">
                  <ngx-select2 formControlName="Page" [select2Option]="select2OptionForPage"></ngx-select2>
                </ngx-form-group>
              </div>
            </div>

            <div style="margin-top: 1rem; margin-bottom: 1rem;">
              <nb-progress-bar *ngIf="progress > 0" [value]="progress" [status]="progressStatus" size="large">{{progressLabel}}</nb-progress-bar>
            </div>
            <ngx-ag-dynamic-list #details [columnDefs]="columnDefs" [title]="'Danh sách sản phẩm chờ xử lý'" [rowModelType]="'clientSide'" [rowData]="rowData" [idKey]="['RefProduct','RefUnit']" (onReady)="onGridReady($event)" (onGridInit)="onGridInit($event)" style="display: block; height: 500px"></ngx-ag-dynamic-list>

          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button [nbSpinner]="isProcessing" nbButton status="primary" [disabled]="!form.valid || isProcessing || loading" style="float: right;" hero (click)="mappingProcess() && false">Xử lý</button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>
<input #file type="file" accept="csv/text" (change)="onFileChange(file.files)">