<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="onControlEnter($event)" (keydown.enter)="onControlEnter($event)" class="dialog-wrap popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('AdminProduct.Product.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="Name" label="Tên sản phẩm" [array]="array" [index]="i" [allowCopy]="true" [required]="true" [touchedValidate]="false">
                  <input type="text" nbInput fullWidth formControlName="Name" placeholder="Tên sản phẩm">
                </ngx-form-group>
              </div>
              <div class="col-sm-2">
                <ngx-form-group [formGroup]="formItem" name="Sku" label="Sku" [array]="array" [index]="i" [allowCopy]="true" [required]="false" [touchedValidate]="false">
                  <input type="text" nbInput fullWidth formControlName="Sku" placeholder="Sku, auto...">
                </ngx-form-group>
              </div>
              <div class="col-sm-2">
                <ngx-form-group [formGroup]="formItem" name="WarehouseUnit" label="Đơn vị tính cơ bản" [array]="array" [index]="i" [allowCopy]="true" [required]="true" [customIcons]="formItem.__unitControlIcons" [touchedValidate]="false">
                  <ngx-select2 formControlName="WarehouseUnit" [select2Option]="select2OptionForUnit" [data]="unitList" (selectChange)="onWarehouseChange(formItem, $event, i)"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-2">
                <ngx-form-group [formGroup]="formItem" name="Code" label="Id Sản phẩm" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                  <input type="text" nbInput fullWidth formControlName="Code" maxlength="30" placeholder="Tự động tạo mã">
                </ngx-form-group>
              </div>
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="TaxName" label="Tên báo cáo thuế" [array]="array" [index]="i" [allowCopy]="true" [required]="false" [touchedValidate]="false">
                  <input type="text" nbInput fullWidth formControlName="TaxName" placeholder="Tên báo cáo thuế">
                </ngx-form-group>
              </div>
              <div class="col-sm-2">
                <ngx-form-group [formGroup]="formItem" name="Type" label="Loại" [array]="array" [index]="i" [allowCopy]="true" [required]="true" [touchedValidate]="false">
                  <ngx-select2 formControlName="Type" [select2Option]="select2OptionForType"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-4">
                <ngx-form-group [formGroup]="formItem" name="Groups" label="Nhóm" [array]="array" [index]="i" [allowCopy]="true" [required]="false" [touchedValidate]="false" [customIcons]="formItem.__groupsControlIcons">
                  <ngx-select2 formControlName="Groups" [select2Option]="select2OptionForGroups" [data]="groupList">
                  </ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="Categories" label="Danh mục" [array]="array" [index]="i" [allowCopy]="true" [required]="false" [touchedValidate]="false" [customIcons]="formItem.__categoriesControlIcons">
                  <ngx-select2 formControlName="Categories" [select2Option]="select2OptionForCategories" [data]="categoryList"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-2">
                <ngx-form-group [formGroup]="formItem" name="Brand" label="Thương hiệu" [array]="array" [index]="i" [allowCopy]="true" [required]="false" [customIcons]="formItem.__brandControlIcons">
                  <ngx-select2 formControlName="Brand" [select2Option]="select2OptionForBrand" [data]="brandList"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-4">
                <ngx-form-group [formGroup]="formItem" name="Keywords" label="Keywords" [array]="array" [index]="i" [allowCopy]="true" [required]="false">
                  <ngx-select2 formControlName="Keywords" [select2Option]="select2OptionForKeywords" [data]="keywordList"></ngx-select2>
                </ngx-form-group>
              </div>

              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-10">
                    <div class="dashed-radius-border">
                      <!-- Unit Conversions form -->
                      <label class="label">{{'AdminProduct.conversionRatio' | translate | headtitlecase}} <nb-icon class="copy-btn" (click)="copyFormControlValueToOthers(array, i, 'UnitConversions')" title="{{'Common.copyToAllNextForm' | translate}}" pack="eva" icon="copy-outline"></nb-icon></label>
                      <div class="row fit-row form-detail-header">
                        <div class="fit-fist-col label column">{{'Common.noNumber' | translate | headtitlecase}}</div>
                        <div class="row fit-content-column">
                          <div class="col-lg-4">
                            <div class="row">
                              <div class="col-lg-6 label column">{{'Product.unit' | translate | headtitlecase}}</div>
                              <div class="col-lg-6 label column align-right">{{'AdminProduct.conversionRatio' | translate | headtitlecase}}</div>
                            </div>
                          </div>
                          <div class="col-lg-8">
                            <div class="row">
                              <div class="col-sm-2 label column">{{'AdminProduct.defaultSales' | translate | headtitlecase}}</div>
                              <div class="col-sm-2 label column">{{'AdminProduct.defaultPurchase' | translate | headtitlecase}}</div>
                              <div class="col-sm-2 label column">{{'Số truy xuất' | translate | headtitlecase}}</div>
                              <div class="col-sm-2 label column">{{'Số serial' | translate | headtitlecase}}</div>
                              <div class="col-sm-2 label column">{{'Tự động trừ kho' | translate | headtitlecase}}</div>
                              <div class="col-sm-2 label column">{{'Có hạn sử dụng' | translate | headtitlecase}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="fit-last-col column">
                          <button class="fit-control-button" nbButton [outline]="true" status="success" (click)="addUnitConversionFormGroup(formItem)" size="medium" hero>
                            <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                          </button>
                        </div>
                      </div>

                      <hr>
                      <div formArrayName="UnitConversions" class="form-details" [sortablejs]="getUnitConversions(formItem)" [sortablejsOptions]="{ handle: '.sorting-handle' }">
                        <div class="form-detail-item" *ngFor="let unitConversion of getUnitConversions(formItem).controls; let ic=index" [formGroup]="unitConversion">
                          <div class="row fit-row">
                            <div class="fit-fist-col label">
                              <div class="form-group">
                                <!-- <label class="label">{{'Common.noNumber' | translate | headtitlecase}}</label> -->
                                <input class="fit-sequence sorting-handle" nbInput fullWidth disabled="true" value="{{ ic + 1 }}">
                                <!-- <input type="hidden" formControlName="Id"> -->
                              </div>
                            </div>
                            <div class="row fit-content-column">
                              <div class="col-sm-12">
                                <div class="row">
                                  <div class="col-lg-4">
                                    <div class="row">
                                      <div class="col-sm-6">
                                        <ngx-form-group [hideLabel]="true" [formGroup]="unitConversion" name="Unit" label="AdminProduct.unit" [array]="getUnitConversions(formItem)" [index]="ic" [allowCopy]="false" [required]="true" [customIcons]="unitConversion.__unitControlIcons">
                                          <ngx-select2 formControlName="Unit" [data]="unitList" [select2Option]="select2OptionForUnit"></ngx-select2>
                                        </ngx-form-group>
                                      </div>
                                      <div class="col-sm-6">
                                        <ngx-form-group [hideLabel]="true" [formGroup]="unitConversion" name="ConversionRatio" label="AdminProduct.conversionRatio" [array]="getUnitConversions(formItem)" [index]="ic" [allowCopy]="false" [required]="true">
                                          <input type="text" nbInput fullWidth formControlName="ConversionRatio" [inputMask]="towDigitsInputMask" placeholder="{{'Product.conversionRatio' | translate | headtitlecase}}">
                                        </ngx-form-group>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-8">
                                    <div class="row">
                                      <div class="col-sm-2">
                                        <ngx-form-group [hideLabel]="true" [formGroup]="unitConversion" name="IsDefaultSales" label="AdminProduct.defaultSales" [array]="getUnitConversions(formItem)" [index]="ic" [allowCopy]="false" [required]="false">
                                          <nb-checkbox formControlName="IsDefaultSales" (change)="onIsDefaultSalesChange(formItem, unitConversion, $event, ic)">
                                            <!-- <label class="label"><span class="valid">{{'AdminProduct.defaultSales' | translate | headtitlecase}}</span></label> -->
                                          </nb-checkbox>
                                        </ngx-form-group>
                                      </div>
                                      <div class="col-sm-2">
                                        <ngx-form-group [hideLabel]="true" [formGroup]="unitConversion" name="IsDefaultPurchase" label="AdminProduct.defaultPurchase" [array]="getUnitConversions(formItem)" [index]="ic" [allowCopy]="false" [required]="false">
                                          <nb-checkbox formControlName="IsDefaultPurchase" (change)="onIsDefaultPurchaseChange(formItem, unitConversion, $event, ic)">
                                            <!-- <label class="label"><span class="valid">{{'AdminProduct.defaultPurchase' | translate | headtitlecase}}</span></label> -->
                                          </nb-checkbox>
                                        </ngx-form-group>
                                      </div>
                                      <div class="col-sm-2">
                                        <ngx-form-group [hideLabel]="true" [formGroup]="unitConversion" name="IsManageByAccessNumber" label="Dùng số truy xuất" [array]="getUnitConversions(formItem)" [index]="ic" [allowCopy]="false" [required]="false">
                                          <nb-checkbox formControlName="IsManageByAccessNumber">
                                            <!-- <label class="label"><span class="valid">{{'Dùng số truy xuất' | translate | headtitlecase}}</span></label> -->
                                          </nb-checkbox>
                                        </ngx-form-group>
                                      </div>
                                      <div class="col-sm-2">
                                        <ngx-form-group [hideLabel]="true" [formGroup]="unitConversion" name="IsManageBySerialNumber" label="QL Theo số seri" [array]="getUnitConversions(formItem)" [index]="ic" [allowCopy]="false" [required]="false">
                                          <nb-checkbox formControlName="IsManageBySerialNumber">
                                          </nb-checkbox>
                                        </ngx-form-group>
                                      </div>
                                      <div class="col-sm-2">
                                        <ngx-form-group [hideLabel]="true" [formGroup]="unitConversion" name="IsAutoAdjustInventory" label="Tự động trừ kho" [array]="getUnitConversions(formItem)" [index]="ic" [allowCopy]="false" [required]="false">
                                          <nb-checkbox formControlName="IsAutoAdjustInventory">
                                            <!-- <label class="label"><span class="valid">{{'Tự động trừ kho' | translate | headtitlecase}}</span></label> -->
                                          </nb-checkbox>
                                        </ngx-form-group>
                                      </div>
                                      <div class="col-sm-2">
                                        <ngx-form-group [hideLabel]="true" [formGroup]="unitConversion" name="IsExpirationGoods" label="Có hạng sử dụng" [array]="getUnitConversions(formItem)" [index]="ic" [allowCopy]="false" [required]="false">
                                          <nb-checkbox formControlName="IsExpirationGoods">
                                            <!-- <label class="label"><span class="valid">{{'Có hạng sử dụng' | translate | headtitlecase}}</span></label> -->
                                          </nb-checkbox>
                                        </ngx-form-group>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="fit-last-col">
                              <div class="form-group" style="text-align: right;">
                                <!-- <label class="label">CM</label> -->
                                <button class="fit-control-button" nbButton status="danger" [outline]="true" hero size="medium" (click)="removeUnitConversionGroup(formItem, unitConversion, ic)">
                                  <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                                </button>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <!-- End Unit Conversions form -->
                    </div>
                    <div class="dashed-radius-border">
                      <!-- Begin properties form -->
                      <label class="label">{{'Thuộc tính' | translate | headtitlecase}} <nb-icon class="copy-btn" (click)="copyFormControlValueToOthers(array, i, 'Properties')" title="{{'Common.copyToAllNextForm' | translate}}" pack="eva" icon="copy-outline"></nb-icon></label>
                      <div class="row fit-row form-detail-header">
                        <div class="fit-fist-col label column">{{'Common.noNumber' | translate | headtitlecase}}</div>
                        <div class="row fit-content-column">
                          <div class="col-lg-12">
                            <div class="row">
                              <div class="col-lg-4 label column">{{'Thuộc tính' | translate | headtitlecase}}</div>
                              <div class="col-lg-8 label column align-rightX">{{'Giá trị' | translate | headtitlecase}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="fit-last-col column">
                          <button class="fit-control-button" nbButton [outline]="true" status="success" (click)="addPropertyFormGroup(formItem)" size="medium" hero>
                            <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                          </button>
                        </div>
                      </div>

                      <hr>
                      <div formArrayName="Properties" class="form-details" [sortablejs]="getProperties(formItem)" [sortablejsOptions]="{ handle: '.sorting-handle' }">
                        <div class="form-detail-item" *ngFor="let property of getProperties(formItem).controls; let ie=index" [formGroup]="property">
                          <div class="row fit-row">
                            <div class="fit-fist-col label">
                              <div class="form-group">
                                <!-- <label class="label">{{'Common.noNumber' | translate | headtitlecase}}</label> -->
                                <input class="fit-sequence sorting-handle" nbInput fullWidth disabled="true" value="{{ ie + 1 }}">
                                <!-- <input type="hidden" formControlName="Id"> -->
                              </div>
                            </div>
                            <div class="row fit-content-column">
                              <div class="col-sm-12">
                                <div class="row">
                                  <div class="col-lg-12">
                                    <div class="row">
                                      <div class="col-sm-4">
                                        <ngx-form-group [hideLabel]="true" [formGroup]="property" name="Property" label="Thuộc tính" [array]="getProperties(formItem)" [index]="ie" [allowCopy]="false" [required]="true">
                                          <ngx-select2 formControlName="Property" [data]="propertyList" [select2Option]="select2OptionForProperty"></ngx-select2>
                                        </ngx-form-group>
                                      </div>
                                      <div class="col-sm-8">
                                        <ngx-form-group [hideLabel]="true" [formGroup]="property" name="PropertyValues" label="Giá trị" [array]="getProperties(formItem)" [index]="ie" [allowCopy]="false" [required]="true">
                                          <ngx-select2 formControlName="PropertyValues" [select2Option]="select2OptionForPropertyValue" [data]="property.dataList"></ngx-select2>
                                        </ngx-form-group>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="fit-last-col">
                              <div class="form-group" style="text-align: right;">
                                <!-- <label class="label">CM</label> -->
                                <button class="fit-control-button" nbButton status="danger" [outline]="true" hero size="medium" (click)="removePropertyGroup(formItem, unitConversion, ie)">
                                  <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                                </button>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <!-- End Begin properties form -->
                    </div>
                    <div class="dashed-radius-border">
                      <!-- Begin product part form -->
                      <label class="label">{{'Sản phẩm cấu thành theo ĐVT cơ bản' | translate | headtitlecase}}</label>
                      <div class="row fit-row form-detail-header">
                        <div class="fit-fist-col label column">{{'Common.noNumber' | translate | headtitlecase}}</div>
                        <div class="row fit-content-column">
                          <div class="col-lg-12">
                            <div class="row">
                              <div class="col-lg-3 label column">{{'SP Thành phần' | translate | headtitlecase}}</div>
                              <div class="col-lg-3 label column align-rightX">{{'ĐVT' | translate | headtitlecase}}</div>
                              <div class="col-lg-3 label column align-rightX">{{'Số lượng' | translate | headtitlecase}}</div>
                              <div class="col-lg-3 label column align-rightX">{{'Khoản mục CP' | translate | headtitlecase}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="fit-last-col column">
                          <button class="fit-control-button" nbButton [outline]="true" status="success" (click)="addProductPartFormGroup(formItem)" size="medium" hero>
                            <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                          </button>
                        </div>
                      </div>

                      <hr>
                      <div formArrayName="ProductParts" class="form-details" [sortablejs]="getProductParts(formItem)" [sortablejsOptions]="{ handle: '.sorting-handle' }">
                        <div class="form-detail-item" *ngFor="let productPart of getProductParts(formItem).controls; let ie=index" [formGroup]="productPart">
                          <div class="row fit-row">
                            <div class="fit-fist-col label">
                              <div class="form-group">
                                <!-- <label class="label">{{'Common.noNumber' | translate | headtitlecase}}</label> -->
                                <input class="fit-sequence sorting-handle" nbInput fullWidth disabled="true" value="{{ ie + 1 }}">
                                <!-- <input type="hidden" formControlName="Id"> -->
                              </div>
                            </div>
                            <div class="row fit-content-column">
                              <div class="col-sm-12">
                                <div class="row">
                                  <div class="col-lg-12">
                                    <div class="row">
                                      <div class="col-sm-3">
                                        <ngx-form-group [hideLabel]="true" [formGroup]="productPart" name="PartProduct" label="SP Thành phần" [array]="getProductParts(formItem)" [index]="ie" [allowCopy]="false" [required]="true">
                                          <ngx-select2 formControlName="PartProduct" [select2Option]="select2OptionForProduct"></ngx-select2>
                                        </ngx-form-group>
                                      </div>
                                      <div class="col-sm-3">
                                        <ngx-form-group [hideLabel]="true" [formGroup]="productPart" name="PartUnit" label="ĐVT" [array]="getProductParts(formItem)" [index]="ie" [allowCopy]="false" [required]="true">
                                          <ngx-select2 formControlName="PartUnit" [data]="productPart['__unitList']" [select2Option]="select2OptionForUnit"></ngx-select2>
                                        </ngx-form-group>
                                      </div>
                                      <div class="col-sm-3">
                                        <ngx-form-group [hideLabel]="true" [formGroup]="productPart" name="Quantity" label="Số lượng" [array]="getProductParts(formItem)" [index]="ie" [allowCopy]="false" [required]="true">
                                          <input type="text" nbInput fullWidth formControlName="Quantity" [inputMask]="towDigitsInputMask" placeholder="{{'Số lượng' | translate | headtitlecase}}">
                                        </ngx-form-group>
                                      </div>
                                      <div class="col-sm-3">
                                        <ngx-form-group [hideLabel]="true" [formGroup]="productPart" name="CostClassification" label="Khoản mục CP" [array]="getProductParts(formItem)" [index]="ie" [allowCopy]="false" [required]="true">
                                          <ngx-select2 formControlName="CostClassification" [select2Option]="select2OptionForCostClassification"></ngx-select2>
                                        </ngx-form-group>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="fit-last-col">
                              <div class="form-group" style="text-align: right;">
                                <!-- <label class="label">CM</label> -->
                                <button class="fit-control-button" nbButton status="danger" [outline]="true" hero size="medium" (click)="removeProductPartGroup(formItem, unitConversion, ie)">
                                  <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                                </button>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <!-- End Product Part form -->
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="dashed-radius-border">
                      <!--Begin parammetes-->
                      <label class="label">{{'Cài đặt tham số' | translate | headtitlecase}}</label>
                      <div class="row">
                        <div class="col-sm-12">
                          <ngx-form-group [formGroup]="formItem" name="Barcode" label="Barcode" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                            <input type="text" nbInput fullWidth formControlName="Barcode" placeholder="Barcode">
                          </ngx-form-group>
                        </div>
                        <div class="col-sm-12">
                          <ngx-form-group [formGroup]="formItem" name="RequireVatTax" label="Thuế VAT (%)" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                            <nb-checkbox class="form-group-icon-control" formControlName="RequireVatTax"></nb-checkbox>
                            <input class="form-group-input-control" type="text" nbInput fullWidth formControlName="VatTax" placeholder="{{'Thuế VAT' | translate | headtitlecase}}" [inputMask]="towDigitsInputMask">
                          </ngx-form-group>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12" style="padding-top: 5px;">
                          <ngx-form-group [formGroup]="formItem" name="IsStopBusiness" [hideLabel]="true" label="Ngừng kinh doanh" [array]="array" [index]="i" [allowCopy]="true" [required]="false">
                            <nb-checkbox formControlName="IsStopBusiness" style="margin-left: 10px;">Ngừng kinh doanh</nb-checkbox>
                          </ngx-form-group>
                        </div>
                      </div>
                      <!--End Begin parammetes-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <label class="label">Hình sản phẩm (kéo & thả hình vào bên dưới để upload)</label>
            <div class="row">
              <div class="col-is-12 col-lg-3">
                <ngx-form-group [formGroup]="formItem" name="FeaturePicture" label="Common.featurePicture" [array]="array" [index]="i" [allowCopy]="true" [required]="false">
                  <ngx-file-input formControlName="FeaturePicture"></ngx-file-input>
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Pictures" label="Common.pictureList" [array]="array" [index]="i" [allowCopy]="true" [required]="false">
                  <ngx-files-input formControlName="Pictures" [config]="{style: {height: '10rem'}, thumbnailStype: {height: '7rem', width: '8rem'}, colSize: 3, overrideOnThumbnailClick: true}" (onThumbnailClick)="onThumbnailPcitureClick($event, formItem)"></ngx-files-input>
                </ngx-form-group>
              </div>
              <ng-container *ngIf="array.controls.length < 6">
                <div class="col-sm-12">
                  <div class="form-group" [attr.state]="formControlValidate(formItem.get('Description'), 'warning')">
                    <label class="label"><span class="valid">Mô tả (*)</span><span class="invalid">Mô tả là bắt buộc (*)</span></label>
                    <ckeditor [editor]="Editor" formControlName="Description" [config]="ckEditorConfig" (ready)="onCkeditorReady($event)"></ckeditor>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group" [attr.state]="formControlValidate(formItem.get('Technical'), 'warning')">
                    <label class="label"><span class="valid">Thông tin kỹ thuật (*)</span><span class="invalid">Thông tin kỹ thuật là bắt buộc (*)</span></label>
                    <ckeditor [editor]="Editor" formControlName="Technical" [config]="ckEditorConfig"></ckeditor>
                  </div>
                </div>
              </ng-container>
            </div>

          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button [disabled]="isProcessing" nbButton status="warning" (click)="addFormGroup($event)" hero>Thêm cái nữa</button>
            <button [disabled]="isProcessing" nbButton status="primary" [disabled]="!form.valid" style="float: right;" hero (click)="saveAndClose()">Lưu & Đóng</button>
            <button [disabled]="isProcessing" nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>Lưu</button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>