import { ColDef } from "@ag-grid-community/core";
import { CommonService } from "../../../../services/common.service";
import { AgTextCellRenderer } from "../cell/text.component";
import { AgIdCellRenderer } from "../cell/id.component";

export const agMakeIdColDef = (
  cms?: CommonService,
): ColDef => {
  return {
    cellRenderer: AgIdCellRenderer,
    filter: 'agTextColumnFilter',
    // cellClass: ['ag-cell-2line-wrap'],
    autoHeight: true,
  }
}