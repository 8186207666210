import { DialogFormComponent } from './../../../dialog/dialog-form/dialog-form.component';
import { Component, OnInit } from '@angular/core';
import { WpSiteModel } from '../../../../models/wordpress.model';
import { ApiService } from '../../../../services/api.service';
import { RootServices } from '../../../../services/root.services';
import { Router } from '@angular/router';
import { CommonService } from '../../../../services/common.service';
import { NbDialogRef, NbDialogService, NbThemeService, NbToastrService } from '@nebular/theme';
import { WpSiteFormComponent } from '../wp-site-form/wp-site-form.component';
import { HttpClient } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { AgGridDataManagerListComponent } from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { agMakeCommandColDef } from '../../../../lib/custom-element/ag-list/column-define/command.define';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import { agMakeTextColDef } from '../../../../lib/custom-element/ag-list/column-define/text.define';
import { agMakeButtonsColDef } from '../../../../lib/custom-element/ag-list/column-define/buttons.define';
import { Model } from '../../../../models/model';

@Component({
  selector: 'ngx-wp-site-list',
  templateUrl: './wp-site-list.component.html',
  styleUrls: ['./wp-site-list.component.scss'],
})
export class WpSiteListComponent extends AgGridDataManagerListComponent<WpSiteModel, WpSiteFormComponent> implements OnInit {

  componentName: string = 'WpSiteListComponent';
  formPath = '/wordpress/site/form';
  apiPath = '/wordpress/wp-sites';
  idKey = ['Code'];
  formDialog = WpSiteFormComponent;

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public themeService: NbThemeService,
    public toastService: NbToastrService,
    public _http: HttpClient,
    public ref: NbDialogRef<WpSiteListComponent>,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);
  }

  editing = {};
  rows = [];

  init(): Promise<boolean> {
    return super.init().then(async rs => {
      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Code',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tên',
          field: 'Name',
          width: 350,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tên miền',
          field: 'Domain',
          width: 350,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Api Url',
          field: 'ApiUrl',
          width: 250,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Api Username',
          field: 'ApiUsername',
          width: 150,
          filter: 'agTextColumnFilter',
          autoHeight: true,
          // pinned: 'right',
        },
        {
          ...agMakeButtonsColDef(this.cms, [
            {
              name: 'createWebHookUri',
              label: 'Tạo webhook',
              status: 'success',
              outline: true,
              icon: 'settings-outline',
              action: async (params: any, data: Model) => {
                this.loading = true;
                try {
                  const results = await this.apiService.putPromise<WpSiteModel>('/wordpress/sites/' + params.data.Code, { generateWebhookToken: true }, [
                    {
                      Code: params.data.Code,
                    }
                  ]);
                  this.loading = false;
                  const result = results[0];
                  // this.cms.showDialog('Thông tin webhook', `Webhook: ${row.BaseUrl}\nToken: ${result.WebhookToken}`, []);
                  const domain = this.cms.systemConfigs$?.value?.LICENSE_INFO?.register?.domain[0];
                  const webhook = `https://${domain}/v3/wordpress/webhooks?token=${result.WebhookToken}`;
                  this.cms.openDialog(DialogFormComponent, {
                    context: {
                      cardStyle: { width: '500px' },
                      title: 'Thông tin webhook',
                      onInit: async (form, dialog) => {
                        return true;
                      },
                      onClose: async (form, dialog) => {
                        // ev.target.
                        return true;
                      },
                      controls: [
                        {
                          name: 'Webhook',
                          label: 'Webhook',
                          placeholder: 'Webhook',
                          type: 'text',
                          initValue: webhook,
                          // focus: true,
                        },
                      ],
                      actions: [
                        {
                          label: 'Esc - Trở về',
                          icon: 'back',
                          status: 'basic',
                          keyShortcut: 'Escape',
                          action: async () => { return true; },
                        },
                        {
                          label: 'Copy',
                          icon: 'generate',
                          status: 'primary',
                          // keyShortcut: 'Enter',
                          action: async (form: FormGroup, formDialogConpoent: DialogFormComponent) => {
                            this.cms.copyTextToClipboard(webhook);
                            return false;
                          },
                        },
                      ],
                    },
                    closeOnEsc: false,
                    closeOnBackdropClick: false,
                  });
                } catch (err) {
                  this.loading = false;
                }
                return true;
              }
            },
          ]),
          headerName: 'Web hook',
          width: 200,
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, true, [
            {
              name: 'copy',
              label: '',
              title: 'Copy',
              status: 'danger',
              outline: false,
              disabled: () => true,
              icon: 'copy',
              action: async (params) => {
                this.cms.showToast('Lệnh này chưa được cài đặt', 'Copy nội dung sang site khác', { status: 'danger' });
                return true;
                this.cms.openDialog(WpSiteFormComponent, {
                  context: {
                    inputMode: 'dialog',
                    inputId: [params.data.Code],
                    onDialogSave: (newData: WpSiteModel[]) => {
                      // if (onDialogSave) onDialogSave(row);
                    },
                    onDialogClose: () => {
                      // if (onDialogClose) onDialogClose();
                      this.refresh();
                    },
                  },
                });
                return true;
              }
            },
          ]),
          headerName: 'Lệnh',
          resizable: true,
          // maxWidth: 200,
          // width: 200,
        },
      ] as ColDef[]);
      return rs;
    })
  }

  // loadListSetting(): any {
  //   return this.configSetting({
  //     mode: 'external',
  //     selectMode: 'multi',
  //     actions: {
  //       position: 'right',
  //     },
  //     add: {
  //       addButtonContent: '<i class="nb-edit"></i> <i class="nb-trash"></i> <i class="nb-plus"></i>',
  //       createButtonContent: '<i class="nb-checkmark"></i>',
  //       cancelButtonContent: '<i class="nb-close"></i>',
  //     },
  //     edit: {
  //       editButtonContent: '<i class="nb-edit"></i>',
  //       saveButtonContent: '<i class="nb-checkmark"></i>',
  //       cancelButtonContent: '<i class="nb-close"></i>',
  //     },
  //     delete: {
  //       deleteButtonContent: '<i class="nb-trash"></i>',
  //       confirmDelete: true,
  //     },
  //     pager: {
  //       display: true,
  //       perPage: 99999,
  //     },
  //     columns: {
  //       Code: {
  //         title: 'Code',
  //         type: 'string',
  //         width: '10%',
  //       },
  //       Name: {
  //         title: 'Name',
  //         type: 'string',
  //         width: '30%',
  //       },
  //       Domain: {
  //         title: 'Domain',
  //         type: 'string',
  //         width: '20%',
  //       },
  //       ApiUrl: {
  //         title: 'API',
  //         type: 'string',
  //         width: '20%',
  //       },
  //       ApiUsername: {
  //         title: 'Username',
  //         type: 'string',
  //         width: '20%',
  //       },
  //       Copy: {
  //         title: 'Copy',
  //         type: 'custom',
  //         width: '10%',
  //         renderComponent: SmartTableButtonComponent,
  //         onComponentInitFunction: (instance: SmartTableButtonComponent) => {
  //           instance.iconPack = 'eva';
  //           instance.icon = 'copy';
  //           instance.label = 'Copy nội dung sang site khác';
  //           instance.display = true;
  //           instance.status = 'success';
  //           instance.valueChange.subscribe(value => {
  //             // if (value) {
  //             //   instance.disabled = false;
  //             // } else {
  //             //   instance.disabled = true;
  //             // }
  //           });
  //           instance.click.subscribe(async (row: WpSiteModel) => {

  //             this.cms.openDialog(SyncFormComponent, {
  //               context: {
  //                 inputMode: 'dialog',
  //                 inputId: [row.Code],
  //                 onDialogSave: (newData: WpSiteModel[]) => {
  //                   // if (onDialogSave) onDialogSave(row);
  //                 },
  //                 onDialogClose: () => {
  //                   // if (onDialogClose) onDialogClose();
  //                   this.refresh();
  //                 },
  //               },
  //             });

  //           });
  //         },
  //       },
  //       Webhook: {
  //         title: 'Webhook',
  //         type: 'custom',
  //         width: '10%',
  //         renderComponent: SmartTableButtonComponent,
  //         onComponentInitFunction: (instance: SmartTableButtonComponent) => {
  //           instance.iconPack = 'eva';
  //           instance.icon = 'settings-outline';
  //           instance.label = 'Tạo webhook';
  //           instance.display = true;
  //           instance.status = 'danger';
  //           instance.valueChange.subscribe(value => {
  //             // if (value) {
  //             //   instance.disabled = false;
  //             // } else {
  //             //   instance.disabled = true;
  //             // }
  //           });
  //           instance.click.subscribe(async (row: WpSiteModel) => {
  //             this.loading = true;
  //             try {
  //               const results = await this.apiService.putPromise<WpSiteModel>('/wordpress/sites/' + row.Code, { generateWebhookToken: true }, [
  //                 {
  //                   Code: row.Code,
  //                 }
  //               ]);
  //               this.loading = false;
  //               const result = results[0];
  //               // this.cms.showDialog('Thông tin webhook', `Webhook: ${row.BaseUrl}\nToken: ${result.WebhookToken}`, []);
  //               const domain = this.cms.systemConfigs$?.value?.LICENSE_INFO?.register?.domain[0];
  //               const webhook = `https://${domain}/v3/wordpress/webhooks?token=${result.WebhookToken}`;
  //               this.cms.openDialog(DialogFormComponent, {
  //                 context: {
  //                   cardStyle: { width: '500px' },
  //                   title: 'Thông tin webhook',
  //                   onInit: async (form, dialog) => {
  //                     return true;
  //                   },
  //                   onClose: async (form, dialog) => {
  //                     // ev.target.
  //                     return true;
  //                   },
  //                   controls: [
  //                     {
  //                       name: 'Webhook',
  //                       label: 'Webhook',
  //                       placeholder: 'Webhook',
  //                       type: 'text',
  //                       initValue: webhook,
  //                       // focus: true,
  //                     },
  //                   ],
  //                   actions: [
  //                     {
  //                       label: 'Esc - Trở về',
  //                       icon: 'back',
  //                       status: 'basic',
  //                       keyShortcut: 'Escape',
  //                       action: async () => { return true; },
  //                     },
  //                     {
  //                       label: 'Copy',
  //                       icon: 'generate',
  //                       status: 'primary',
  //                       // keyShortcut: 'Enter',
  //                       action: async (form: FormGroup, formDialogConpoent: DialogFormComponent) => {
  //                         this.cms.copyTextToClipboard(webhook);
  //                         return false;
  //                       },
  //                     },
  //                   ],
  //                 },
  //                 closeOnEsc: false,
  //                 closeOnBackdropClick: false,
  //               });
  //             } catch (err) {
  //               this.loading = false;
  //             }
  //           });
  //         },
  //       },
  //     },
  //   });
  // }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  getList(callback: (list: WpSiteModel[]) => void) {
    super.getList((rs) => {
      if (callback) callback(rs);
    });
  }

  /** Implement required */
  // openFormDialplog(ids?: string[], onDialogSave?: (newData: WpSiteModel[]) => void, onDialogClose?: () => void) {
  //   this.cms.openDialog(WpSiteFormComponent, {
  //     context: {
  //       inputMode: 'dialog',
  //       inputId: ids,
  //       onDialogSave: (newData: WpSiteModel[]) => {
  //         if (onDialogSave) onDialogSave(newData);
  //       },
  //       onDialogClose: () => {
  //         if (onDialogClose) onDialogClose();
  //         this.refresh();
  //       },
  //     },
  //   });
  // }

  // /** Go to form */
  // gotoForm(id?: string): false {
  //   this.openFormDialplog(id ? decodeURIComponent(id).split('&') : null);
  //   return false;
  // }

  prepareApiParams(params: any, getRowParams?: IGetRowsParams, component?: AgGridDataManagerListComponent<WpSiteModel, WpSiteFormComponent>) {
    return params;
  }

  /** Implement required */
  openFormDialplog(ids?: string[], onDialogSave?: (newData: WpSiteModel[]) => void, onDialogClose?: () => void) {
    this.cms.openDialog(WpSiteFormComponent, {
      context: {
        inputMode: 'dialog',
        inputId: ids,
        onDialogSave: (newData: WpSiteModel[]) => {
          if (onDialogSave) onDialogSave(newData);
        },
        onDialogClose: () => {
          if (onDialogClose) onDialogClose();
        },
      },
    });
    return false;
  }

}
